
import Downloads from './../Downloads'
import './Ogloszenia.css'
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function Ogloszenia() {
    const [value, onChange] = useState(new Date());
  
  return (
    <div className='ogloszenia-container'>
        <div className='ogloszenia'>
            <div className='ogloszenia-items'>
                <h1>Rok szkolny 2023/2024</h1>
                <h4>1-09-2023 - Przedszkole Słoneczka w Białymstoku</h4>
                <hr></hr>
                <br></br>
                <h3>Drodzy Rodzice</h3>
                <h3>W roku szkolnym 2023/2024 pracujemy z programem wychowania przedszkolnego przygotowanym przez</h3>
                <h3>Wydawnictwo MAC Edukacja</h3>
                <h3>"Kolorowe karty"</h3>
            </div>
            <div className='ogloszenia-items'>
                <h1>Rok szkolny 2022/2023</h1>
                <h4>1-09-2022 - Przedszkole Słoneczka w Białymstoku</h4>
                <hr></hr>
                <br></br>
                <h3>Kochani!</h3>
                <h3>W bieżącym roku szkolnym 2022/2023 rozpoczynamy naukę i zabawę z programem wychowania przedszkolnego</h3>
                <h3>"SAMODZIELNE - WSZECHSTRONNE - SZCZĘŚLIWE"</h3>
                <h3>Wydawnictwo NOWA ERA</h3>
            </div>
        </div>

        <div className='pasek'>
            <div className='pliki'>
                <Downloads/>
            </div>

            <div className='kalendarz'>
                <h3>Kalendarz</h3>
                <Calendar onChange={onChange} value={value} />
            </div>
        </div>
    </div>
    )
  }
  
  export default Ogloszenia