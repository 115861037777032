import React, { useEffect, useState } from 'react'
import "./ImagesViewer.css"

const VideosViewer = (props) => {
    const [videoSet, setVideoSet] = useState([]);
    const [videoSetLenght, setVideoSetLenght] = useState(0);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

    useEffect(() => {
        setVideoSet(props.videos.videos);
        setVideoSetLenght(props.videos.videosLenght);
        setCurrentVideoIndex(0);
    }, [props])

    let incrementVideoIndex = () => {
        let nextIndex;
        if(currentVideoIndex === videoSetLenght){
            nextIndex = 0;
        } else {
            nextIndex = currentVideoIndex + 1;
        } 
        setCurrentVideoIndex(nextIndex);
    }

    let decrementVideoIndex = () => {
        let nextIndex;
        if(currentVideoIndex === 0){
            nextIndex = videoSetLenght;
        } else {
            nextIndex = currentVideoIndex - 1;
        }
        setCurrentVideoIndex(nextIndex);
    }
  return (
    <div className='images-viewer-container'>
        <i className="fa-solid fa-chevron-left" onClick={() => decrementVideoIndex()}/>
        {videoSetLenght >= 0 ?
        <video className='my-video' src={videoSet[currentVideoIndex]} type='video/mp4' controls />
        : <></>}        
        <i className="fa-solid fa-chevron-right" onClick={() => incrementVideoIndex()}/>
        <p className='current-page'>{currentVideoIndex + 1}/{videoSetLenght + 1}</p>
    </div>
  )
}

export default VideosViewer