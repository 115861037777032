import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className='footer-container'>
        <div className='copy'>
               <h5>Realizacja <a target="_blank" rel='noopener noreferrer' href="https://cloverit.pl">CloverIT</a>&copy; 2022 </h5>
        </div>
    </div>
  )
}

export default Footer