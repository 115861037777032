import React, { useEffect, useState } from 'react'
import "./ImagesViewer.css"

const ImagesViewer = (props) => {
    const [imgSet, setImgSet] = useState([]);
    const [imgSetLenght, setImgSetLenght] = useState(0);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);
    const [currentImg, setCurrentImg] = useState('');

    useEffect(() => {
        setImgSet(props.images.images);
        setImgSetLenght(props.images.imagesLenght);
        setCurrentImgIndex(0);
    }, [props])

    useEffect(() => {
        if(imgSet) setCurrentImg(imgSet[currentImgIndex]);
    }, [imgSet, currentImgIndex])

    let incrementImgIndex = () => {
        let nextIndex;
        if(currentImgIndex === imgSetLenght){
            nextIndex = 0;
        } else {
            nextIndex = currentImgIndex + 1;
        } 
        setCurrentImgIndex(nextIndex);
    }

    let decrementImgIndex = () => {
        let nextIndex;
        if(currentImgIndex === 0){
            nextIndex = imgSetLenght;
        } else {
            nextIndex = currentImgIndex - 1;
        }
        setCurrentImgIndex(nextIndex);
    }
  return (
    <div className='images-viewer-container'>
        <i className="fa-solid fa-chevron-left" onClick={() => decrementImgIndex()}/>
        {imgSetLenght >= 0 ? <img src={currentImg} alt="model" /> : <></>}        
        <i className="fa-solid fa-chevron-right" onClick={() => incrementImgIndex()}/>
        <p className='current-page'>{currentImgIndex + 1}/{imgSetLenght + 1}</p>
    </div>
  )
}

export default ImagesViewer