import React from 'react'
import './Folders.css';
import FoldersItem from './FoldersItem';

function Folders() {
  const images0824 = [
    {
      id: 0,
      folderName: 'Słoneczka - wakacyjnie',
      images: [
        'photos/0824/thumb/1.webp',
        'photos/0824/thumb/2.webp',
        'photos/0824/thumb/3.webp',
        'photos/0824/thumb/4.webp',
        'photos/0824/thumb/5.webp',
        'photos/0824/thumb/6.webp',
        'photos/0824/thumb/7.webp',
        'photos/0824/thumb/8.webp',
        'photos/0824/thumb/9.webp',
        'photos/0824/thumb/31c0e0cb-87be-4b1e-8561-97a5e340343c.webp',
        'photos/0824/thumb/38bdb010-9c6b-4f3a-a104-2c98a2466dcf.webp',
        'photos/0824/thumb/604469d6-b41b-43b6-9432-aaa1905fe2d9.webp',
        'photos/0824/thumb/76f0f1c5-297a-4e14-b48e-5cb8bab29404.webp',
        'photos/0824/thumb/965c2e85-ee43-41ef-a493-d3faf0900980.webp',
        'photos/0824/thumb/b514d3c4-d951-4d25-be2b-719f8505cda8.webp',
        'photos/0824/thumb/dd137ada-3dcf-412c-869e-e399c650fd6d.webp',
        'photos/0824/thumb/de3e86c6-8a1b-442f-963c-b9314746d6bf.webp',
        'photos/0824/thumb/e09f4849-0ec7-4c72-a56e-4a33f9f7468c.webp',
        'photos/0824/thumb/e2801dac-fd45-430a-ae9b-76a8c49ede8e.webp'
      ],
      imagesLenght: 18
    }
  ]
  const images0624 = [
    {
      id: 0,
      folderName: 'Urodziny naszej Idusi',
      images: [
        'photos/0624/thumb/06d19cba-1398-44b5-9476-60fecfbd9998.webp',
        'photos/0624/thumb/100062ee-b75d-4992-81f4-20f4dc04c808.webp',
        'photos/0624/thumb/18c94c05-19e8-47ac-aceb-6d8a2c2de484.webp',
        'photos/0624/thumb/1c219d1d-e898-42b6-936d-39ef5bfd44b3.webp',
        'photos/0624/thumb/203674a2-21a0-480e-ab2a-b0d31303f939.webp',
        'photos/0624/thumb/34c2c789-53ea-4435-9237-d9ca90c3b8ea.webp',
        'photos/0624/thumb/36bdef1c-74a0-4d71-9f4b-340bdeb3bf8f.webp',
        'photos/0624/thumb/37a3319d-fda9-4df4-b3ef-b5722fd3d555.webp',
        'photos/0624/thumb/3cb66cb5-5d7b-44c6-9b14-71a81daa6d62.webp',
        'photos/0624/thumb/4176a33f-0f56-463b-a4db-767733691213.webp',
        'photos/0624/thumb/58ac2b3b-8479-4895-88e2-a8dbfef5a2fb.webp',
        'photos/0624/thumb/5b4518a1-619c-4e11-bd4b-1fd4640430ec.webp',
        'photos/0624/thumb/66a3f4b2-df56-4c44-9d0b-d2bcca094903.webp',
        'photos/0624/thumb/675b7dae-e986-4d74-a79a-cd7a08f720c0.webp',
        'photos/0624/thumb/71c05a55-d0a2-4862-a3f8-b28b7b441ed3.webp',
        'photos/0624/thumb/7e6b536a-3d43-4917-9f00-6d417677c62b.webp',
        'photos/0624/thumb/83175c2e-85d5-4fc9-86bc-ebcd2fd7f6ba.webp',
        'photos/0624/thumb/83f9138b-f756-486c-90fa-7564e74c7178.webp',
        'photos/0624/thumb/8a2acc6b-6940-4603-9528-0724b73e7f43.webp',
        'photos/0624/thumb/8bc27e8c-6a6b-4446-90d1-c7a02e18a707.webp',
        'photos/0624/thumb/8cea81f9-9469-4ff4-9419-3396cdc84cff.webp',
        'photos/0624/thumb/8cec88c5-029d-4fb1-b5d8-21ebf98401ba.webp',
        'photos/0624/thumb/8f681926-e9cd-4759-99a1-188994ccf193.webp',
        'photos/0624/thumb/991e1813-7cb5-40cf-acae-9b0e84433057.webp',
        'photos/0624/thumb/a3b173c7-e119-44be-bf4c-d9f9145057a2.webp',
        'photos/0624/thumb/a72b383f-9bff-4b6c-85d9-b92f862c7159.webp',
        'photos/0624/thumb/ace63ca0-9a72-4a38-9c2a-f9b3b47984f9.webp',
        'photos/0624/thumb/b11346bf-5090-49ee-a032-8e6e68b49a4e.webp',
        'photos/0624/thumb/b610b809-21a9-4d2d-8eaa-bb48b1ee8b0a.webp',
        'photos/0624/thumb/bf9ce3a3-6796-4eca-9888-93a280474517.webp',
        'photos/0624/thumb/d7db8f0d-e4a1-440f-bd4d-85434d5a7376.webp',
        'photos/0624/thumb/f508fc30-08d9-4576-9481-2552fcdec71e.webp',
        'photos/0624/thumb/f5c8f827-2b46-4da7-bbae-9b4adfd0410a.webp'
      ],
      imagesLenght: 32
    }, {
      id: 1,
      folderName: 'Urodziny naszej Alusi',
      images: [
        'photos/0624/thumb/1473a035-bdea-44a4-bc4f-cdf38bc30300.webp',
        'photos/0624/thumb/1e0236ae-465b-4d36-9504-c8d1771180a4.webp',
        'photos/0624/thumb/2594dc85-4b1a-4698-9100-d5d606cdae71.webp',
        'photos/0624/thumb/2d894d56-fdc7-4a65-a8c5-fee505063b1c.webp',
        'photos/0624/thumb/2f40057d-9af3-49d2-80cd-e7d7c7146e0a.webp',
        'photos/0624/thumb/3101b129-521b-41f0-9c01-9c8abda92cfb.webp',
        'photos/0624/thumb/330a0fc1-11af-4699-95cd-e43f41eee949.webp',
        'photos/0624/thumb/36e4a6fe-1f1d-42e8-9552-1923397acaae.webp',
        'photos/0624/thumb/3aa434ae-17cd-4fa9-a3bf-d8bcf4d008e9.webp',
        'photos/0624/thumb/3ed19a06-2352-41e0-a972-3990b0ad2cd6.webp',
        'photos/0624/thumb/50ea0ffd-d753-40dd-af90-ec9969ad6814.webp',
        'photos/0624/thumb/53236b9b-6f2e-40da-98bf-831c3c736db4.webp',
        'photos/0624/thumb/57553262-b627-4b13-9187-389fb8af42ec.webp',
        'photos/0624/thumb/5b40500e-2edd-4267-8153-3eb32731ddd7.webp',
        'photos/0624/thumb/5c5c10d3-e022-4b24-b5f0-e203550f5a7c.webp',
        'photos/0624/thumb/6434ed90-9300-4560-94d2-4e31c38f7cd2.webp',
        'photos/0624/thumb/678d2473-d352-46cb-9933-a985052cf468.webp',
        'photos/0624/thumb/699f713d-e1bb-44ce-b583-3e82fb1f3cf0.webp',
        'photos/0624/thumb/6d774ef7-f228-419d-a6da-e9f143ee0c86.webp',
        'photos/0624/thumb/732ed11a-f0a8-4e17-a5c8-0f40ebd90116.webp',
        'photos/0624/thumb/73a5f8ad-dc9b-462a-8d64-afe83d4e5639.webp',
        'photos/0624/thumb/74eddcfa-05b6-4d49-9714-59706cf1ad63.webp',
        'photos/0624/thumb/77ceb3cb-8ca7-4e7e-847c-bf6d327e5748.webp',
        'photos/0624/thumb/7edc6243-b8df-4f65-88e1-94a3da871c64.webp',
        'photos/0624/thumb/8c302583-6537-4949-8f69-815410c682c7.webp',
        'photos/0624/thumb/9ce9f3ab-0789-4fd5-a2d5-6f37eb848e1f.webp',
        'photos/0624/thumb/a9320768-8d2f-4c43-8471-eb9a0a4ed3bf.webp',
        'photos/0624/thumb/a93abe5d-8e54-495a-8396-4e617c855cab.webp',
        'photos/0624/thumb/b8b74481-2683-4e6d-9549-e0a968759f1d.webp',
        'photos/0624/thumb/b9368668-a81f-4e0d-aa48-402110c9fce2.webp',
        'photos/0624/thumb/bc75471c-56cd-4ec8-be59-d121f6614252.webp',
        'photos/0624/thumb/c23e2560-9f9b-4ea5-bf75-f86874c48ec5.webp',
        'photos/0624/thumb/cc1828d9-4d77-4306-8b47-00982208760a.webp',
        'photos/0624/thumb/dc4219a2-8ef2-4dcc-981b-5e92f47fbaae.webp',
        'photos/0624/thumb/e5a97cc0-91a3-4277-b69a-d540aaa54eaf.webp',
        'photos/0624/thumb/ff9355fd-ae56-4ddf-9eea-12b0c4d28e64.webp'
      ],
      imagesLenght: 35
    }, {
      id: 2,
      folderName: 'Urodziny naszej Alusi - nagranie',
      videos: [
        'videos/0624/19a82b3f-2245-4a46-a0df-1785b5b44213.mp4'
      ],
      videosLenght: 0
    }, {
      id: 3,
      folderName: 'Urodzinki naszej Amelci',
      images: [
        'photos/0624/thumb/002a6d76-18e2-4afd-a12a-a88594edaff8.webp',
        'photos/0624/thumb/018ca233-c216-49a1-b2fc-75ab292f0185.webp',
        'photos/0624/thumb/018cb3df-4f08-4d05-9c48-b61d27bab35d.webp',
        'photos/0624/thumb/06a54c4a-9082-43a0-9106-b2b50ef8ad5c.webp',
        'photos/0624/thumb/15382d39-4a2c-4dc5-b824-4f10211ae0a6.webp',
        'photos/0624/thumb/1785cf78-ec8f-457e-b1ae-2625416b97ef.webp',
        'photos/0624/thumb/1f088b02-b731-48f7-9d00-f0eb73b6a76f.webp',
        'photos/0624/thumb/20eef182-2f5d-4b47-8e88-8758e7f543e9.webp',
        'photos/0624/thumb/2ad919c4-3bfd-4f9d-9812-b34ad9f3558a.webp',
        'photos/0624/thumb/2f9b4432-293a-4110-91ee-7ae0552241b0.webp',
        'photos/0624/thumb/3e752367-8f17-421b-a576-aac19db55bbc.webp',
        'photos/0624/thumb/49275cfd-ab80-4fc0-b41a-be2bf7e606ac.webp',
        'photos/0624/thumb/58778c24-82df-4f1d-b601-9e44ca32248b.webp',
        'photos/0624/thumb/6abb464b-4515-463a-9b0a-fbd80e2fed32.webp',
        'photos/0624/thumb/852d682d-4598-4514-bdbd-09458d6de906.webp',
        'photos/0624/thumb/8e11835a-c3d6-487a-8f69-56e5ccbaca7b.webp',
        'photos/0624/thumb/8f78b032-f3e6-476a-a734-cf29838f8c5f.webp',
        'photos/0624/thumb/9214ed1e-2229-4810-a56b-71a52b108839.webp',
        'photos/0624/thumb/927b2b01-7629-4a97-8a1a-5aa60d34ebde.webp',
        'photos/0624/thumb/929ec894-a3f7-420c-a3ef-d65e471f59ac.webp',
        'photos/0624/thumb/9b9ab833-e0f3-4d05-8a7b-a88c5c6b9453.webp',
        'photos/0624/thumb/aa4c54a6-0987-4681-afec-a9ecf9b3b8f9.webp',
        'photos/0624/thumb/b8dbd3ee-768d-4618-9d4d-a62f42522188.webp',
        'photos/0624/thumb/ba92e35a-1309-4948-be7b-2b352e4634dc.webp',
        'photos/0624/thumb/c13ad8bf-9c19-4d67-ab99-00effc2c4276.webp',
        'photos/0624/thumb/c396b1c7-5b5e-472c-be82-c37befbb5526.webp',
        'photos/0624/thumb/d832fd3d-e829-40a7-bc34-61183f38607f.webp',
        'photos/0624/thumb/dcb34157-c893-4b56-a835-a4f285785922.webp',
        'photos/0624/thumb/e4082a5f-ae53-4cee-a3c9-d55206a56b27.webp',
        'photos/0624/thumb/f7dcb740-2d4c-4df1-92f9-823718b36b3d.webp',
        'photos/0624/thumb/fd0641c8-dd16-4ffa-bcad-9cf1f4ee9055.webp'
      ],
      imagesLenght: 30
    }, {
      id: 4,
      folderName: 'Urodzinki naszej Amelci - nagranie',
      videos: [
        'videos/0624/3636abb6-1fab-4f43-bccd-118b33bc134c.mp4'
      ],
      videosLenght: 0
    }, {
      id: 5,
      folderName: 'Urodziny naszego Antka',
      images: [
        'photos/0624/thumb/024504f1-f8c9-4765-9dfc-950f7cfbd886.webp',
        'photos/0624/thumb/0404332b-74cf-44a7-80b1-0c1b15227356.webp',
        'photos/0624/thumb/05c96154-27d1-40e2-8263-e71b59e4295e.webp',
        'photos/0624/thumb/07066272-3ec5-4904-b4e4-89dabe8f841a.webp',
        'photos/0624/thumb/0d008a9c-bd58-4418-b5ef-678e03123687.webp',
        'photos/0624/thumb/1aacc416-e9b6-4f89-a3e7-250da47a98b0.webp',
        'photos/0624/thumb/1f01a808-4c4e-4828-9d22-f8e45f0bf274.webp',
        'photos/0624/thumb/258f0b0f-e4f4-42a3-b210-f99578a4e011.webp',
        'photos/0624/thumb/31bc16e5-180f-4148-8c9d-c63cd7b8a827.webp',
        'photos/0624/thumb/35395d46-221b-4009-99e8-583722bc7f3c.webp',
        'photos/0624/thumb/3ce34da9-bd79-4af3-b5c1-7bf6e2ae900e.webp',
        'photos/0624/thumb/3f53b460-5dc4-4bb6-9a82-6cb90f73dba6.webp',
        'photos/0624/thumb/4b777955-56a6-47fc-9533-c2d0a6886220.webp',
        'photos/0624/thumb/520b031e-c8b3-4098-a97c-d8ac5bbe8d79.webp',
        'photos/0624/thumb/59f3007d-cad0-460f-8e2f-8b256171d9de.webp',
        'photos/0624/thumb/5c1c02a7-673b-49ea-b758-2bad5b7deab4.webp',
        'photos/0624/thumb/5f6142d8-99c9-4fc0-b1c9-a9cc0ce48fe8.webp',
        'photos/0624/thumb/6487146b-af06-4629-965c-73efc26717d7.webp',
        'photos/0624/thumb/665dbc83-fbd8-41eb-b2ae-07834000308e.webp',
        'photos/0624/thumb/67bbc251-aedc-43e6-b4d1-44a2bb87ca79.webp',
        'photos/0624/thumb/69ab02d3-d96b-4d5d-8860-9c884b0885df.webp',
        'photos/0624/thumb/79359c80-b3b5-41d0-b9f7-6b254b2845f7.webp',
        'photos/0624/thumb/7d2ecf08-e804-49b6-b934-88a07dc70b9c.webp',
        'photos/0624/thumb/7f381b1c-3906-4fbc-aa0d-ed238c7d041a.webp',
        'photos/0624/thumb/8183d055-9801-4bce-8b28-646dcf45b3b3.webp',
        'photos/0624/thumb/84d82ee6-2d83-4da4-ad1a-77ab6f4e949d.webp',
        'photos/0624/thumb/8aecb230-b166-417a-9aab-29ecb93933d8.webp',
        'photos/0624/thumb/8bc03f85-4efd-462c-b3b1-530e0dff78cb.webp',
        'photos/0624/thumb/954f64e8-e20d-4ad6-9d62-9c13a22f8577.webp',
        'photos/0624/thumb/97f17e4b-2c06-4bde-8e9f-4e6b4166ef6c.webp',
        'photos/0624/thumb/9e52a853-b8da-4c23-b58b-4872c7c08e30.webp',
        'photos/0624/thumb/a1262ca1-8f58-4a47-90ff-bdfcda05a17d.webp',
        'photos/0624/thumb/b609d06f-c638-4811-915b-57f9fc9b9f6c.webp',
        'photos/0624/thumb/bdd2b368-b74f-47a6-a25b-cb337ec6cae5.webp',
        'photos/0624/thumb/bf9d5188-8211-4dc8-8342-d36f7faf26c1.webp',
        'photos/0624/thumb/c81c8dbe-482e-4955-86d7-4915f63a4db6.webp',
        'photos/0624/thumb/c9b05c83-9ae0-4363-a3be-4de5be93b27d.webp',
        'photos/0624/thumb/d228bad5-c321-4cc3-9141-46075b811858.webp',
        'photos/0624/thumb/d4b4ce30-8f8f-469a-82f1-0886f773c17d.webp',
        'photos/0624/thumb/d5619e61-722c-4302-ad8a-d8015f63e76e.webp',
        'photos/0624/thumb/d6f4dea5-3103-4407-a358-3ae1095fbb62.webp',
        'photos/0624/thumb/de209ba7-76cb-4e02-82f6-c358f6716db7.webp',
        'photos/0624/thumb/ee85cc29-e0a2-48b0-b042-420212784c64.webp',
        'photos/0624/thumb/f15b8634-d0a5-45ea-8350-71b950bfe840.webp',
        'photos/0624/thumb/f26bc4ec-c40a-4234-b7af-0844b69aeefb.webp',
        'photos/0624/thumb/f2e76106-e797-4e9c-868a-7c5027578e57.webp',
        'photos/0624/thumb/f5295621-878b-4add-8dd8-402d4bd184f4.webp',
        'photos/0624/thumb/f6e2d7c0-5a34-42b9-a2f5-dbd7a4d2f15e.webp'
      ],
      imagesLenght: 47
    }, {
      id: 6,
      folderName: 'Urodziny naszego Antka - nagranie',
      videos: [
        'videos/0624/69f7010c-c3e9-41f8-8b8b-3a5963d149c8.mp4'
      ],
      videosLenght: 0
    }, {
      id: 7,
      folderName: 'Dzisiaj urodziny naszej Laury',
      images: [
        'photos/0624/thumb/00775f71-d79d-4f29-8040-e9bc9de072c4.webp',
        'photos/0624/thumb/0355a536-6ba7-4f72-9fb4-6be344b27156.webp',
        'photos/0624/thumb/05581d24-6697-4abf-be46-8606e46241d4.webp',
        'photos/0624/thumb/05bd4439-cd0c-4e81-8182-a07001318c54.webp',
        'photos/0624/thumb/0c161258-e85c-4e5d-a273-4fd59fca8c04.webp',
        'photos/0624/thumb/11c6c70b-df57-4fb0-9014-795d2fd70cd7.webp',
        'photos/0624/thumb/17c84f28-aa75-4048-a97e-4e9bc9123c27.webp',
        'photos/0624/thumb/1ae49004-4b02-4ccd-a8cb-299c6421c589.webp',
        'photos/0624/thumb/1cf31cfe-62a2-4bd0-a077-abf1ce1eec33.webp',
        'photos/0624/thumb/2517c670-2bf8-4f56-9b33-4a50a7e653c3.webp',
        'photos/0624/thumb/342a9455-8a08-455a-8a41-c100b1bacb84.webp',
        'photos/0624/thumb/35531b44-3ab3-47b4-ab0e-fd19c87ee3a6.webp',
        'photos/0624/thumb/3690fd50-3229-44de-8343-dc670709e91f.webp',
        'photos/0624/thumb/39ba3031-ab41-4f0b-b6fb-26b19e19edb6.webp',
        'photos/0624/thumb/3d8e62a1-1fad-4082-91e5-4a83d4395e33.webp',
        'photos/0624/thumb/4e306d91-608e-4882-9dab-15c2d195a2b2.webp',
        'photos/0624/thumb/50af4d27-f95a-4637-af84-23b7334984e1.webp',
        'photos/0624/thumb/610bf8ef-4de3-4a7c-be46-98d13f9d8fbf.webp',
        'photos/0624/thumb/7135bed7-4b79-48f9-8cea-de5bfd312d46.webp',
        'photos/0624/thumb/7f8eb079-3401-421e-8d53-b1b6ef04b382.webp',
        'photos/0624/thumb/80f1f97e-a07b-44ac-9ff8-8020bfffd74f.webp',
        'photos/0624/thumb/8436c304-7a0e-498a-b0a3-0dd452ad0343.webp',
        'photos/0624/thumb/89401d95-d5c0-4aae-bb51-59d9a7240040.webp',
        'photos/0624/thumb/8e340a0f-6774-4509-aa21-478caaa8228a.webp',
        'photos/0624/thumb/acd1f2c1-f923-45de-926b-4c6e840cd006.webp',
        'photos/0624/thumb/af1f4de5-a942-42c6-b45b-ca1de3b287ee.webp',
        'photos/0624/thumb/b162855d-0190-4598-9f75-0de1e0561ffb.webp',
        'photos/0624/thumb/bdd7f532-0234-4efd-bfc9-8ee479f87bed.webp',
        'photos/0624/thumb/bf246806-afee-463c-a738-68b5582a33f8.webp',
        'photos/0624/thumb/c19f9f68-0924-45ee-a7fb-f738d6709038.webp',
        'photos/0624/thumb/c54e9bf7-40e6-43c5-8b12-93150c21fde4.webp',
        'photos/0624/thumb/ca9e4589-7bd9-42f2-ba55-01e9f5dbc496.webp',
        'photos/0624/thumb/d1f04729-0821-479e-b9ee-a5cca2482845.webp',
        'photos/0624/thumb/d4099f9c-8722-4db2-8359-c0961f36b062.webp',
        'photos/0624/thumb/e11a5c26-4f92-43f1-8f41-b3d0034843a8.webp',
        'photos/0624/thumb/e2d040ab-9adc-4f21-9302-af58fb436895.webp',
        'photos/0624/thumb/e5369d32-46cd-4a83-905e-29448ce5182f.webp',
        'photos/0624/thumb/ebec77b8-5c2b-46a4-ae54-0db3096aecf4.webp'
      ],
      imagesLenght: 37
    }, {
      id: 8,
      folderName: 'Dzisiaj urodziny naszej Laury - nagranie',
      videos: [
        'videos/0624/2a0e7957-7c4a-4a73-9edf-2f836724f3f3.mp4'
      ],
      videosLenght: 0
    }, {
      id: 9,
      folderName: 'Urodzinki Natalki',
      images: [
        'photos/0624/thumb/01685245-5012-4187-ae9c-ca103db596d7.webp',
        'photos/0624/thumb/065ec26d-737f-459a-bcd4-b6f5b26bfa5f.webp',
        'photos/0624/thumb/0b598c46-f914-46f4-bc40-1d238bbbe76f.webp',
        'photos/0624/thumb/109fa58f-3309-40bc-b57a-aef1199106f3.webp',
        'photos/0624/thumb/1285c32c-1fe7-4b6e-b233-cb4ceb567976.webp',
        'photos/0624/thumb/1bb3f961-a4e6-45dd-92eb-8be1ecc1f626.webp',
        'photos/0624/thumb/228f8847-688f-4c4a-986d-d0681dfd4bb2.webp',
        'photos/0624/thumb/2c5d4dcd-b01d-47e3-bb16-64c5ff7bee29.webp',
        'photos/0624/thumb/2cc059ec-f974-4e4d-b6cd-fa786b19b86a.webp',
        'photos/0624/thumb/31d72f26-2a09-4f33-89e5-f32f6a9bf726.webp',
        'photos/0624/thumb/34712535-30e8-4cf4-b6b9-26e8a2d28e38.webp',
        'photos/0624/thumb/3d927d56-08e3-4b76-81c2-18610b25d016.webp',
        'photos/0624/thumb/520e1128-b584-4a31-8121-0e0af0c07ed2.webp',
        'photos/0624/thumb/5ad122e0-945c-4eef-af99-fe8fe3606892.webp',
        'photos/0624/thumb/673ae144-4c4b-435f-a941-3f84ed39c0e0.webp',
        'photos/0624/thumb/68bdc4c9-6025-453b-8d7f-1aa1a42116c6.webp',
        'photos/0624/thumb/758f9a88-f9ed-46fc-9530-909e685422d0.webp',
        'photos/0624/thumb/7695e6d0-ccb7-4f55-b315-0b158212149d.webp',
        'photos/0624/thumb/7e9c5bc9-7321-45d6-abf4-ba9f8bfe92bf.webp',
        'photos/0624/thumb/81c4e62c-dd5c-43f6-8cdb-284016ec3299.webp',
        'photos/0624/thumb/8d4306da-86ae-4b29-b908-89d2b3b97742.webp',
        'photos/0624/thumb/8e7bd6ac-256f-47f7-bcdc-f389d7c4582e.webp',
        'photos/0624/thumb/94f91e2a-1a35-46b0-be84-5f25c6c6cda3.webp',
        'photos/0624/thumb/96f1276b-8402-45c7-bcbb-bb9d4b30258a.webp',
        'photos/0624/thumb/98499f41-81d3-451d-9b04-22549ae873fb.webp',
        'photos/0624/thumb/9c34a674-83cc-453d-aba6-a9f20f654a45.webp',
        'photos/0624/thumb/ab0f77f6-aaef-4106-94bc-e2f6c28c407e.webp',
        'photos/0624/thumb/b42e0c78-2309-4f50-8654-f6adc6771d9c.webp',
        'photos/0624/thumb/bdaf32c9-a84a-488f-b87f-d121283278c6.webp',
        'photos/0624/thumb/ca8d6cd0-c75c-4241-a1dd-23996e39b074.webp',
        'photos/0624/thumb/cffa4e3a-927f-460d-b4da-f8c7fc691784.webp',
        'photos/0624/thumb/d1ca0697-3f2e-4902-bff1-0b2edffa2cd9.webp',
        'photos/0624/thumb/dcc4232e-f963-45db-b865-b6fdc6a025ee.webp',
        'photos/0624/thumb/e0672591-eda1-41ce-b1eb-26dd1115ccfd.webp',
        'photos/0624/thumb/e8c67a13-9aa4-4259-8f8d-cfbc6aa764f5.webp',
        'photos/0624/thumb/ef161d85-b0a1-4020-bf00-669388979b12.webp',
        'photos/0624/thumb/f0a020e5-c61c-4d41-be8c-eabde07641d4.webp',
        'photos/0624/thumb/f635317c-93a0-42b3-b026-038bf78b3d6d.webp'
      ],
      imagesLenght: 37
    }, {
      id: 10,
      folderName: 'Urodzinki Natalki - nagranie',
      videos: [
        'videos/0624/48e9ae4a-162e-4431-98b7-2300b4979e93.mp4'
      ],
      videosLenght: 0
    }, {
      id: 11,
      folderName: 'Dyplomy za sukcesy naukowe, językowe, taneczne i sportowe naszych słoneczek',
      images: [
        'photos/0624/thumb/043f6688-b206-4515-ae67-4041caebd063.webp',
        'photos/0624/thumb/099ff4e8-036b-44d6-8ed6-8e0baadd2217.webp',
        'photos/0624/thumb/09c99e1b-8ca9-47de-996a-baa9e1b56bf8.webp',
        'photos/0624/thumb/10425236-51c7-439e-83ff-e3e698e0d716.webp',
        'photos/0624/thumb/18391d18-fda7-4a85-a1a5-cca949e8f72e.webp',
        'photos/0624/thumb/23ea1bdf-e461-42b3-a760-9133ce5740ac.webp',
        'photos/0624/thumb/26f10811-79dc-4dbf-a8eb-419193f16429.webp',
        'photos/0624/thumb/31cb6b80-f0ae-49ea-9ab8-aad991db7044.webp',
        'photos/0624/thumb/33d1565d-e6fd-46ce-a8a9-ed28472a25c3.webp',
        'photos/0624/thumb/39a9feae-a947-4f4d-bec5-82a6cf2dcb4e.webp',
        'photos/0624/thumb/3e69c578-52f4-4973-afd2-d2d3a2704ca7.webp',
        'photos/0624/thumb/40ea9f6b-c39d-4f31-b01b-b833b6eab404.webp',
        'photos/0624/thumb/45fb8719-9de6-4390-9387-78c4d8be0b91.webp',
        'photos/0624/thumb/478ffaa2-a904-4b11-8fc7-ec92e833c5b6.webp',
        'photos/0624/thumb/487b0644-9d9d-46f5-ae3a-fd3c71e3a712.webp',
        'photos/0624/thumb/50c80639-e6cb-416a-9e65-f5cc75567903.webp',
        'photos/0624/thumb/5e133495-ba28-4943-acc2-468a49efd032.webp',
        'photos/0624/thumb/65bbfef3-fd8d-48ad-9e4f-d86f608b1b6c.webp',
        'photos/0624/thumb/6c543bd7-d959-4a12-a911-2147bcbaad33.webp',
        'photos/0624/thumb/7952547b-ccd7-4d1f-be8b-8dfaa593c697.webp',
        'photos/0624/thumb/809e55b4-3fc3-4891-baa4-d2afd463f6de.webp',
        'photos/0624/thumb/834c6d93-ce13-49d1-8fc5-338f170111fa.webp',
        'photos/0624/thumb/85923ea9-40c4-46f7-a999-3b70cec04129.webp',
        'photos/0624/thumb/8aca98af-721d-4d9d-8c83-f85109f0eaa7.webp',
        'photos/0624/thumb/8c91627e-ec85-4146-bc3f-30b8a3113459.webp',
        'photos/0624/thumb/8dca8cdf-86b2-4525-9e00-56b366b4199e.webp',
        'photos/0624/thumb/989d84a2-a69f-48c7-9502-ee931f50e277.webp',
        'photos/0624/thumb/aab0279a-062e-4a8d-9e8e-e9535a41df43.webp',
        'photos/0624/thumb/b89754be-3838-445e-b5ba-e89379c7b6f7.webp',
        'photos/0624/thumb/c1d366c8-06d9-46e6-98fa-2a2106e89518.webp',
        'photos/0624/thumb/c306da40-e00e-4783-8376-08b02048f836.webp',
        'photos/0624/thumb/c73dfd47-ec76-4f6b-a374-ce76d7b64f43.webp',
        'photos/0624/thumb/d4cf6f79-f994-4222-9c39-15af1eebf0fc.webp',
        'photos/0624/thumb/d6a0d002-f61b-418e-8bcc-f1221058dd66.webp',
        'photos/0624/thumb/d82a1cd5-17ce-460b-bd8a-0af03dd1f8ee.webp',
        'photos/0624/thumb/dd48b20e-307f-4ad5-a289-0ec3ce8bb4f5.webp',
        'photos/0624/thumb/e2185280-569a-4432-8efc-0cd11003a7fb.webp',
        'photos/0624/thumb/e2e07e21-14ed-4ed6-a6d2-263a1f2d0c91.webp',
        'photos/0624/thumb/e4cbf289-3ef8-4411-8b53-564cddd0a810.webp',
        'photos/0624/thumb/e7c99877-75e3-46d8-a576-c175b4364792.webp'
      ],
      imagesLenght: 39
    }, {
      id: 12,
      folderName: 'Kończymy rok szkolny 20232024 Witamy wakacje - nagrania',
      videos: [
        'videos/0624/bc23c20c-697e-4816-956a-9421eb20db44.mp4',
        'videos/0624/33f66fa9-7084-410a-b807-a4a3fccdb74f.mp4',
        'videos/0624/6013a7ff-f698-4d40-9284-b031ba4280e4.mp4'
      ],
      videosLenght: 2
    }, {
      id: 13,
      folderName: 'Dalej będzie pięknie Do zobaczenia',
      images: [
        'photos/0624/thumb/506d54a2-18eb-4ef4-8148-2320627ea3d9.webp'
      ],
      imagesLenght: 0
    }, {
      id: 14,
      folderName: 'Dalej będzie pięknie Do zobaczenia - nagranie',
      videos: [
        'videos/0624/71814bcf-c8ab-4fdd-bacd-156c0b5e3aeb.mp4'
      ],
      videosLenght: 0
    }
  ]
  const images0524 = [
    {
      id: 0,
      folderName: 'Obejrzeliśmy „Przygody małej Calineczki”',
      images: [
        'photos/0524/thumb/437258571_964025554922301_4168236196158358068_n.webp',
        'photos/0524/thumb/437343859_410315478534033_4296249690482708253_n.webp',
        'photos/0524/thumb/437369637_2320677661469905_1615746355231382465_n.webp',
        'photos/0524/thumb/437375266_1281708729452319_9179543470878952665_n.webp',
        'photos/0524/thumb/437491958_1115660502980886_1208006541942588230_n.webp',
        'photos/0524/thumb/437527840_1624788534941397_3953783729961659299_n.webp',
        'photos/0524/thumb/437527842_420513753934132_7372420842446460771_n.webp',
        'photos/0524/thumb/437564623_835748895241890_6424685421452854455_n.webp',
        'photos/0524/thumb/437573695_775238684574523_2310483175167301906_n.webp',
        'photos/0524/thumb/437573695_933392825240328_2776038050600754701_n.webp',
        'photos/0524/thumb/437689139_7398887506885391_2241764009480511163_n.webp',
        'photos/0524/thumb/437694647_1812685295890929_411784099368945952_n.webp',
        'photos/0524/thumb/437712084_7241560095941128_9025760782342867195_n.webp',
        'photos/0524/thumb/437733558_741580698138485_2145408909110158843_n.webp',
        'photos/0524/thumb/437754676_438465415500985_9068860711206698595_n.webp',
        'photos/0524/thumb/437763055_1147342636607004_3806673684264153212_n.webp',
        'photos/0524/thumb/440341116_999786658529455_6659483218104270811_n.webp',
        'photos/0524/thumb/440366894_356456750776735_973923433086060583_n.webp',
        'photos/0524/thumb/440372315_449266714345815_8645164280779087903_n.webp',
        'photos/0524/thumb/440475270_873155321239393_5328279097755904713_n.webp',
        'photos/0524/thumb/440539426_1236558367319614_8294345961863163130_n.webp'
      ],
      imagesLenght: 20
    }, {
      id: 1,
      folderName: 'Majowe Święta Narodowe-dni o których pamiętają nasze Słoneczka - nagrania',
      videos: [
        'videos/0524/440995893_7604068333044537_5430914555907890800_n.mp4',
        'videos/0524/441015216_6927467447377935_3804908323206104858_n.mp4',
        'videos/0524/10000000_7432827540132201_6471356564139665465_n.mp4'
      ],
      videosLenght: 2
    }, {
      id: 2,
      folderName: 'Majowe Święta Narodowe-dni o których pamiętają nasze Słoneczka',
      images: [
        'photos/0524/thumb/437129282_403944055803394_672556526261541466_n.webp',
        'photos/0524/thumb/437339345_408430348789073_8765953620567672281_n.webp',
        'photos/0524/thumb/437348108_984010390120196_1425035285131618304_n.webp',
        'photos/0524/thumb/437491965_413695658105255_1256567065283623696_n.webp',
        'photos/0524/thumb/437522162_1658116924927219_6716179364475955680_n.webp',
        'photos/0524/thumb/437541549_2373860496138525_5266902603529232905_n.webp',
        'photos/0524/thumb/440336113_460362936337532_6373585092375748560_n.webp',
        'photos/0524/thumb/440475297_1499620420618132_35502657158194065_n.webp'
      ],
      imagesLenght: 7
    }, {
      id: 3,
      folderName: 'Nasi mali konstruktorzy',
      images: [
        'photos/0524/thumb/437309144_969060684743921_6143650465105640150_n.webp',
        'photos/0524/thumb/437316589_240461849126263_6682245485347924426_n.webp',
        'photos/0524/thumb/437334004_648383097449633_3444086891223880109_n.webp',
        'photos/0524/thumb/437360546_1164347127900786_5273250298428406729_n.webp',
        'photos/0524/thumb/437375260_2457086481119638_4582482803361899106_n.webp',
        'photos/0524/thumb/437554999_399694606266145_9213598454039433419_n.webp',
        'photos/0524/thumb/437601269_433023079338555_3683175395207817536_n.webp',
        'photos/0524/thumb/437602118_835160518430419_4319442860115216049_n.webp',
        'photos/0524/thumb/437665934_409164565300972_5044869600506717832_n.webp',
        'photos/0524/thumb/437712139_974884497593008_6252712988309817323_n.webp',
        'photos/0524/thumb/437724915_751282817137530_7175097190363072831_n.webp',
        'photos/0524/thumb/437740252_8217928138237161_608388578360121936_n.webp',
        'photos/0524/thumb/437744585_1162253288139937_3242043971573468461_n.webp',
        'photos/0524/thumb/437749831_1628437541239800_6541402413617457916_n.webp',
        'photos/0524/thumb/437763053_815197253291084_3057780508607702909_n.webp',
        'photos/0524/thumb/437764216_1484411995837339_3770311508404838782_n.webp',
        'photos/0524/thumb/440272405_1152822215738822_7686320411145908733_n.webp',
        'photos/0524/thumb/440281943_914928847046761_920758942346146716_n.webp',
        'photos/0524/thumb/440484095_2934277950046266_7139762333343494707_n.webp',
        'photos/0524/thumb/440587299_3230179190451077_4402748687325552870_n.webp',
        'photos/0524/thumb/441169936_1499693640949966_3882691938729832439_n.webp',
        'photos/0524/thumb/441173037_403955642549017_6564266189896201385_n.webp'
      ],
      imagesLenght: 21
    }, {
      id: 4,
      folderName: 'Urodziny naszego Mateuszka',
      images: [
        'photos/0524/thumb/01241e6a-5300-4e55-98f9-eca305b70941.webp',
        'photos/0524/thumb/029e49e2-ef3d-4a25-a2a8-054c57e9bcc8.webp',
        'photos/0524/thumb/088f3655-0e62-4797-9071-77b06352f53d.webp',
        'photos/0524/thumb/09b43272-d715-4a84-9df5-fde1a003a0bb.webp',
        'photos/0524/thumb/0cb9e83a-e951-492e-8607-e092aa7615f5.webp',
        'photos/0524/thumb/0ffee328-79de-447c-adb1-7a93a7610210.webp',
        'photos/0524/thumb/11373e1a-6abc-408d-a9bc-671b01fa2785.webp',
        'photos/0524/thumb/14c0fc83-0892-42d8-b4d6-4a24df1799fe.webp',
        'photos/0524/thumb/16fa08d6-3f8b-4ace-9e52-a288c19970e0.webp',
        'photos/0524/thumb/1dd7a74f-61dd-4c55-8240-ef6d95936550.webp',
        'photos/0524/thumb/249cf8df-2d26-444f-a32f-b44992abbdfe.webp',
        'photos/0524/thumb/2f75559a-e71a-475f-84ad-a7b55430d067.webp',
        'photos/0524/thumb/34b49908-95d6-4c0b-846a-4635830675d8.webp',
        'photos/0524/thumb/36ae56ee-d29c-41ba-947f-6e65b73d55a4.webp',
        'photos/0524/thumb/3a1d71aa-1847-4981-b2c6-a74bd99af141.webp',
        'photos/0524/thumb/41bc4f6d-ba76-4bd8-aabb-42a8d5071492.webp',
        'photos/0524/thumb/44d91373-35f7-42f7-b078-2821ae383582.webp',
        'photos/0524/thumb/4d14dd5b-ce09-4dca-93cc-12f38ef5962c.webp',
        'photos/0524/thumb/52feef03-7867-443c-8f6d-0fe3f60ffb97.webp',
        'photos/0524/thumb/530b5b5e-7455-4ce3-b1ab-cc17b0c6b740.webp',
        'photos/0524/thumb/53f59d16-2ca3-41d3-aea2-9a6a84f403e1.webp',
        'photos/0524/thumb/57914fea-5017-4d54-be17-d4febf500238.webp',
        'photos/0524/thumb/57fbd47c-76ab-4128-a64d-9b269fc884b8.webp',
        'photos/0524/thumb/5d5781e8-e96c-4ded-8f76-bf13a0e84603.webp',
        'photos/0524/thumb/653f0621-2f67-4d8d-b4fa-defd6922eead.webp',
        'photos/0524/thumb/65fe31fb-0cdf-4f89-8fd0-fd5615d45b19.webp',
        'photos/0524/thumb/6b531097-b3f5-48b8-ae93-b8722dd5eeea.webp',
        'photos/0524/thumb/6b64390d-6937-4dd1-83e7-bd5cd08bcf93.webp',
        'photos/0524/thumb/6d0a77e7-833a-4212-96fd-f7191fc960d1.webp',
        'photos/0524/thumb/7140a875-61dc-4ae6-8f0d-ccf3d0f8e59b.webp',
        'photos/0524/thumb/72763906-0204-464b-aa4d-02cae4d1591b.webp',
        'photos/0524/thumb/7d72ffea-6f39-4507-81cc-b8bcd0de6d14.webp',
        'photos/0524/thumb/86381ec0-7b70-4249-90a7-765e3678d8e7.webp',
        'photos/0524/thumb/877e7b7c-a7ec-4370-96f4-06e2390956ea.webp',
        'photos/0524/thumb/89edbd8e-f627-4067-848c-5b18538a167c.webp',
        'photos/0524/thumb/8c202b9b-8103-48fc-b570-6d1f1b2df24d.webp',
        'photos/0524/thumb/8c7e640d-9f4e-43b8-a2f1-aec13c9fc6b3.webp',
        'photos/0524/thumb/8d030dc9-bd53-4466-a498-778cc2dd1c38.webp',
        'photos/0524/thumb/8d84a985-dddd-4ddf-a96e-fdcc0dd44094.webp',
        'photos/0524/thumb/9520afd8-b115-4157-a7e9-8139ab0df249.webp',
        'photos/0524/thumb/957ae669-69de-4510-a885-607181fd14c2.webp',
        'photos/0524/thumb/97032702-6a77-485f-a870-1904c373f6d9.webp',
        'photos/0524/thumb/9bc54924-f0af-4de9-a5e3-3c403d9aac01.webp',
        'photos/0524/thumb/ac732b66-47e1-447b-a71a-71e60e28bad6.webp',
        'photos/0524/thumb/ae47a584-35aa-43ed-ab79-cbce6b377882.webp',
        'photos/0524/thumb/aee078ae-7c09-467d-a68a-0e26382af35e.webp',
        'photos/0524/thumb/b7746ace-f7e1-4d63-9869-ce7d3621a7f2.webp',
        'photos/0524/thumb/bc95fdb8-f488-4944-b7cc-534883c759e9.webp',
        'photos/0524/thumb/bd131e87-90e1-4eae-8124-1a9b20635e66.webp',
        'photos/0524/thumb/c222d78f-85a0-42ed-a535-d47f21b54654.webp',
        'photos/0524/thumb/c75e6b7c-ba78-4067-9ce4-1e2512d90fc4.webp',
        'photos/0524/thumb/ca86a18d-a7a0-4ad8-92c9-8fc868353ab7.webp',
        'photos/0524/thumb/cc35adfa-01f1-41ce-a018-6a16b3bc3f01.webp',
        'photos/0524/thumb/ce9a7558-c5d8-4aec-a771-3471a2408ed6.webp',
        'photos/0524/thumb/cf56f341-2159-4b08-9d56-74f26bbdb981.webp',
        'photos/0524/thumb/cfd4a667-ef5a-4e2b-9a7c-e42a3b563ee4.webp',
        'photos/0524/thumb/d61b26d0-7ec3-4558-9626-a4b42b27def1.webp',
        'photos/0524/thumb/dcac0742-7ae1-4043-be2f-b1305cdedcbd.webp',
        'photos/0524/thumb/dce12323-ea44-42bd-9f00-7454104b6191.webp',
        'photos/0524/thumb/e6e0742a-5785-4848-b027-0420173e6770.webp',
        'photos/0524/thumb/e7530cc6-dc4d-4e56-81b0-316710bfeb66.webp',
        'photos/0524/thumb/e871e787-5ab2-47fd-8334-fb42de951371.webp',
        'photos/0524/thumb/eb603fb2-f2c6-4e6c-8e87-8f6c744875df.webp'
      ],
      imagesLenght: 62
    }, {
      id: 5,
      folderName: 'Urodziny naszego Mateuszka - nagranie',
      videos: [
        'videos/0524/c70b15b8-128b-4b0f-8e59-909fd6c91146.mp4'
      ],
      videosLenght: 0
    }, {
      id: 6,
      folderName: 'Dzień Mamy i Taty - nagrania',
      videos: [
        'videos/0524/d0fdd5e8-5d86-46c0-8ee8-f099181b14e3.mp4',
        'videos/0524/67e98bd6-4e6d-4c18-a7ff-19e7c4f7eacf.mp4',
        'videos/0524/d512f7c9-e193-4178-ba0f-0dd720dd621f.mp4',
        'videos/0524/eb8984a0-c2b7-4a85-a762-509f91b6b890.mp4',
        'videos/0524/2d2c12aa-189a-4845-a04b-4caa74c8ca47.mp4',
        'videos/0524/14cfdba8-3c72-4ebb-b5bc-9c1792f43a4b.mp4',
        'videos/0524/16ec841d-5f65-4e3c-b25f-2f8e1a7d87cb.mp4',
        'videos/0524/62a75f69-fc2f-42b5-8912-aaf53ce85656.mp4'
      ],
      videosLenght: 7
    }, {
      id: 7,
      folderName: 'Dzień Mamy i Taty',
      images: [
        'photos/0524/thumb/0030babc-b607-4be9-b5e6-d0f748743356.webp',
        'photos/0524/thumb/0103287e-fa7c-41b0-92b5-f23b6c869acc.webp',
        'photos/0524/thumb/0380efa3-e7e9-4b87-b038-8b686648d787.webp',
        'photos/0524/thumb/0bf62567-6e61-4829-935f-1cd21acdd4b8.webp',
        'photos/0524/thumb/1758d9ff-a738-4997-961c-6fac40995108.webp',
        'photos/0524/thumb/17f214f0-c9cf-4d6c-a45d-f7b1b50bcf70.webp',
        'photos/0524/thumb/18742e87-fd35-4ff9-bad2-01af049f1af4.webp',
        'photos/0524/thumb/1abad908-9a86-402f-a6db-e3b1ad8efaaf.webp',
        'photos/0524/thumb/26310119-8a7f-48d1-b690-766a6ae46c6e.webp',
        'photos/0524/thumb/276197c8-3e61-49ce-823a-845337720a83.webp',
        'photos/0524/thumb/2e11926a-a167-4365-959c-fcf2101e552c.webp',
        'photos/0524/thumb/3aa1dd13-1a28-4668-8d5f-7a9957221ec6.webp',
        'photos/0524/thumb/3af7665d-3fac-45a0-b4be-718a72f9c514.webp',
        'photos/0524/thumb/65a9c940-daae-40d2-86f5-3360821f486e.webp',
        'photos/0524/thumb/71ad64f0-c2eb-4b1b-8060-896d5c052cf2.webp',
        'photos/0524/thumb/7c1b9402-c62d-4f01-8a3c-da205077413a.webp',
        'photos/0524/thumb/836bd45a-267d-486b-9d48-9f4e2878edcb.webp',
        'photos/0524/thumb/9a9290eb-0ed0-4758-b0f9-12caff420487.webp',
        'photos/0524/thumb/a6a8afe9-8f3d-4540-8c12-0e39caacf3d3.webp',
        'photos/0524/thumb/aadbdfd8-518d-4daa-8a4b-19bd40e7cb5c.webp',
        'photos/0524/thumb/bd139cdf-0f1e-4f56-ae8f-7217f50320a2.webp',
        'photos/0524/thumb/bd2493b9-a0a0-48d2-9ed0-17cfc900707a.webp',
        'photos/0524/thumb/d81b8bb4-c34a-4b65-8054-8517b4928dbe.webp',
        'photos/0524/thumb/da0ac2bc-b96f-4467-911e-11c91d15d95f.webp',
        'photos/0524/thumb/da0c2a7c-1d08-4651-b705-bea419ac6600.webp',
        'photos/0524/thumb/e4bd2157-61a0-47a8-9365-c07fd86931c9.webp',
        'photos/0524/thumb/e8770fc1-29f5-4f13-856e-427a318dce6a.webp',
        'photos/0524/thumb/f998b39d-3728-44b7-ac44-498d110e7c4e.webp',
        'photos/0524/thumb/ff0134e2-bdf1-437a-8ebd-4d6a1106eb21.webp'
      ],
      imagesLenght: 28
    }
  ]
  const images0424 = [
    {
      id: 0,
      folderName: 'W dobrych humorach witamy wiosnę na wsi - nagrania',
      videos: [
        'videos/0424/10000000_7550255665040083_5820008384317457351_n.mp4',
        'videos/0424/10000000_25397880696522220_4909074159187780394_n.mp4'
      ],
      videosLenght: 1
    }, {
      id: 1,
      folderName: 'Dbamy o naszą planetę',
      videos: [
        'videos/0424/10000000_7556652547713711_1360414356670809691_n.mp4'
      ],
      videosLenght: 0
    }, {
      id: 2,
      folderName: 'Nasi mali ekolodzy',
      images: [
        'photos/0424/thumb/432674856_461180333259554_8810520760601031755_n.webp',
        'photos/0424/thumb/434184962_389364597239554_2064701120863466847_n.webp',
        'photos/0424/thumb/434234013_941797000949965_2714793393036050272_n.webp',
        'photos/0424/thumb/434247405_7491428857574737_3747797489335644732_n.webp',
        'photos/0424/thumb/434383720_3493601784283196_1693380015957128417_n.webp',
        'photos/0424/thumb/434632163_833094382008447_4139784754832464613_n.webp',
        'photos/0424/thumb/434915990_1295202085216459_2257499407162839929_n.webp',
        'photos/0424/thumb/435032884_307085219079112_8336274862813963769_n.webp',
        'photos/0424/thumb/435032895_1273840270774661_3554322963264354164_n.webp',
        'photos/0424/thumb/435133160_442944305083802_3584389910540652112_n.webp',
        'photos/0424/thumb/435251098_839148031569833_157108510351789435_n.webp',
        'photos/0424/thumb/435329579_831781338790099_1510578317043069420_n.webp',
        'photos/0424/thumb/437073354_758196619754095_1009828607436832286_n.webp',
        'photos/0424/thumb/437100552_734453732102384_1784652644224786225_n.webp',
        'photos/0424/thumb/437105741_2582874015225042_551900952808630781_n.webp',
        'photos/0424/thumb/437158862_831524722361426_5832202785214644231_n.webp',
        'photos/0424/thumb/437220328_287289794428553_2299399749950045700_n.webp',
        'photos/0424/thumb/437284357_965000358603681_6852623146334263215_n.webp',
        'photos/0424/thumb/437290492_1173421367006751_5437233811602667443_n.webp',
        'photos/0424/thumb/437723895_1456214188355368_3801590447441969461_n.webp',
        'photos/0424/thumb/437744844_955872416075160_4722308735373926026_n.webp'
      ],
      imagesLenght: 20
    }, {
      id: 1,
      folderName: 'Nasi mali ekolodzy - nagranie',
      videos: [
        'videos/0424/10000000_7382457788490498_5162640538624725767_n.mp4'
      ],
      videosLenght: 0
    }
  ]
  const images0324 = [
    {
      id: 0,
      folderName: 'Urodziny naszego Boryska',
      images: [
        'photos/0324/thumb/429622548_1112630629858334_4030584939405774143_n.webp',
        'photos/0324/thumb/429719584_1805564143291468_2181667630499590246_n.webp',
        'photos/0324/thumb/429739177_804355855068765_2732353011212538733_n.webp',
        'photos/0324/thumb/429746067_242983688885902_5471456944336493123_n.webp',
        'photos/0324/thumb/430109267_2161890264203286_502762513257390924_n.webp',
        'photos/0324/thumb/430133082_206680599200774_2642799642993011433_n.webp',
        'photos/0324/thumb/430139323_1135813770924264_9066308562008704635_n.webp',
        'photos/0324/thumb/430140295_902315828102938_710001666047131625_n.webp',
        'photos/0324/thumb/430160007_714167800873393_3024031721035155480_n.webp',
        'photos/0324/thumb/430168894_414594271251251_1248313551566995601_n.webp',
        'photos/0324/thumb/430178224_939725574046110_7960087355302160339_n.webp',
        'photos/0324/thumb/430178887_1155999218913882_3763139320480606756_n.webp',
        'photos/0324/thumb/430183179_253860854360777_7357128316049634274_n.webp',
        'photos/0324/thumb/430196607_266966319686095_4394447805049103070_n.webp',
        'photos/0324/thumb/430216120_2758520360980955_146137611161828413_n.webp',
        'photos/0324/thumb/430217542_710619044592102_6204283450463527063_n.webp',
        'photos/0324/thumb/430218075_420103193826572_2687896911200661350_n.webp',
        'photos/0324/thumb/430223134_949221046539836_8449433585438799179_n.webp',
        'photos/0324/thumb/430228149_758336842983633_1395670969481815422_n.webp',
        'photos/0324/thumb/430239188_303319292461417_6292954605862140443_n.webp',
        'photos/0324/thumb/430239190_1477651366465332_5943510679084680990_n.webp',
        'photos/0324/thumb/430240620_839014791319138_1658681570915209435_n.webp',
        'photos/0324/thumb/430240621_348272221543354_6333405690714933639_n.webp',
        'photos/0324/thumb/430252427_931040795365701_8915465391428768966_n.webp',
        'photos/0324/thumb/430283961_1061376721818127_7052432044349572446_n.webp',
        'photos/0324/thumb/430315740_806978851448760_5100756877323895398_n.webp',
        'photos/0324/thumb/430348606_6903704769741422_1126899340933916815_n.webp',
        'photos/0324/thumb/430349247_7339202439502045_5807144882477579397_n.webp',
        'photos/0324/thumb/430434066_1754276101649553_5896528045474436070_n.webp',
        'photos/0324/thumb/430476701_1479882479234194_6869332650429736143_n.webp',
        'photos/0324/thumb/430489210_777457613854170_1521648678821007780_n.webp',
        'photos/0324/thumb/430493869_1639188333285668_5753724780802144028_n.webp',
        'photos/0324/thumb/430494929_1100597441214300_8348582671373762520_n.webp',
        'photos/0324/thumb/430619891_25027726763509535_4261897660775208471_n.webp',
        'photos/0324/thumb/430639386_969323578145046_5136425690303474673_n.webp',
        'photos/0324/thumb/430716045_931366415024133_2137471023551305312_n.webp',
        'photos/0324/thumb/430731955_451539397200196_1403777660320800848_n.webp'
      ],
      imagesLenght: 36
    }, {
      id: 1,
      folderName: 'Ulubione eksperymenty',
      images: [
        'photos/0324/thumb/429566570_226885063781614_3421392697570492494_n.webp',
        'photos/0324/thumb/429622545_727842499337868_8261449603393538806_n.webp',
        'photos/0324/thumb/429746063_394049663363423_8104272767407130371_n.webp',
        'photos/0324/thumb/430178222_1485929895470459_6079059542518525194_n.webp',
        'photos/0324/thumb/430191326_1466467727549503_7918189854657216251_n.webp',
        'photos/0324/thumb/430239183_1459122841624727_3883381033983707890_n.webp',
        'photos/0324/thumb/430253218_359783537028865_3767891055316929307_n.webp',
        'photos/0324/thumb/430253221_1411895916389372_7962679042102498253_n.webp',
        'photos/0324/thumb/430301937_320680104341135_7659885835309878490_n.webp',
        'photos/0324/thumb/430316674_772708191404350_4220045605239870848_n.webp',
        'photos/0324/thumb/430337005_927433245493328_5612714015840393275_n.webp',
        'photos/0324/thumb/430371376_1105409377274964_2909407270821780811_n.webp',
        'photos/0324/thumb/430409487_391944780138332_219323203474039212_n.webp',
        'photos/0324/thumb/430428489_1884247222009684_4503314496683525038_n.webp',
        'photos/0324/thumb/430449515_449787044249344_5885871987881387975_n.webp',
        'photos/0324/thumb/430471102_7166705446716902_8077529028005595947_n.webp',
        'photos/0324/thumb/430478611_403023999200782_5689359152153312446_n.webp',
        'photos/0324/thumb/430489202_418207640741376_1492923557261564770_n.webp',
        'photos/0324/thumb/430593467_1110939366710443_6406509976826955896_n.webp',
        'photos/0324/thumb/430667674_431415362884839_4454147253572781330_n.webp',
        'photos/0324/thumb/430673076_1393953854558782_1315481393085709594_n.webp',
        'photos/0324/thumb/430674867_390891057006044_6630911327547672961_n.webp'
      ],
      imagesLenght: 21
    }, {
      id: 2,
      folderName: 'Ulubione eksperymenty - nagrania',
      videos: [
        'videos/0324/431351268_7029912167138293_282888141442779968_n.mp4',
        'videos/0324/432124626_24975615818750998_3749529693300200973_n.mp4'
      ],
      videosLenght: 1
    }, {
      id: 3,
      folderName: 'Nasza piosenka',
      videos: [
        'videos/0324/10000000_25067675029514885_6983839271501229873_n.mp4'
      ],
      videosLenght: 0
    }, {
      id: 4,
      folderName: 'Święto naszych dziewczynek',
      images: [
        'photos/0324/thumb/429703842_950714476214740_7237129833972849482_n.webp',
        'photos/0324/thumb/429750173_261739566988276_4455564884586905241_n.webp',
        'photos/0324/thumb/429814352_433282355776810_7544290226249657061_n.webp',
        'photos/0324/thumb/430138321_961756902485079_726937407691559193_n.webp',
        'photos/0324/thumb/430140298_1350861955621043_6344135365580590409_n.webp',
        'photos/0324/thumb/430196607_437435818720068_8359069653731976620_n.webp',
        'photos/0324/thumb/430265317_424699370021170_7575120067241998367_n.webp',
        'photos/0324/thumb/430296883_1114432339703143_3797634530006471347_n.webp',
        'photos/0324/thumb/430316670_834904358653012_969947727340364697_n.webp',
        'photos/0324/thumb/430319433_1351463515557042_7985552720837409991_n.webp',
        'photos/0324/thumb/430365029_733783382201712_6219186948308320813_n.webp',
        'photos/0324/thumb/430371376_1127335681734508_7630281863792792453_n.webp',
        'photos/0324/thumb/430378394_407316368557189_5465199620986589194_n.webp',
        'photos/0324/thumb/430400252_938926274080504_4489617574214699176_n.webp',
        'photos/0324/thumb/430409114_941642763728035_424812216581810970_n.webp',
        'photos/0324/thumb/430433191_1100326284542948_3407125374392448238_n.webp',
        'photos/0324/thumb/430471921_928642675395440_1219252409713609055_n.webp',
        'photos/0324/thumb/430489932_1128013588387280_7122811528667244544_n.webp',
        'photos/0324/thumb/430493527_3153617944775514_7982048132803362375_n.webp',
        'photos/0324/thumb/430689780_428602679731585_3379999840600690246_n.webp',
        'photos/0324/thumb/430700976_945888353851900_6037348689634593675_n.webp'
      ],
      imagesLenght: 20
    }, {
      id: 5,
      folderName: 'Dzień Babci i Dziadka z zasobów mamy naszej Alusi',
      videos: [
        'videos/0324/10000000_7564591193599468_6514687091307763122_n.mp4',
        'videos/0324/431727355_7347717271987363_2014033368534889162_n.mp4',
        'videos/0324/431793558_7563827473669633_7548719835092233364_n.mp4',
        'videos/0324/432144873_6619499004816548_804191108603482107_n.mp4',
        'videos/0324/10000000_7091577324245413_539763355007119410_n.mp4',
        'videos/0324/10000000_7166932220039771_6609762464428585543_n.mp4',
        'videos/0324/10000000_7185633158223981_3809751705571076800_n.mp4'
      ],
      videosLenght: 6
    }, {
      id: 6,
      folderName: 'Powitaliśmy wiosnę',
      images: [
        'photos/0324/thumb/419090604_435491919000057_2068690178605611365_n.webp',
        'photos/0324/thumb/419133282_427861293142297_8725111308727790559_n.webp',
        'photos/0324/thumb/419223309_2031790473883948_440259336156960729_n.webp',
        'photos/0324/thumb/419447263_294977450106489_1880062572019186519_n.webp',
        'photos/0324/thumb/419473435_2060177671014027_107620556583881652_n.webp',
        'photos/0324/thumb/430182225_858090486075832_8144078178793841717_n.webp',
        'photos/0324/thumb/430400608_1505632936833345_7349841769071821990_n.webp',
        'photos/0324/thumb/430816547_334602369592428_8696392268460773790_n.webp',
        'photos/0324/thumb/430831089_695421649204077_2301754177956557316_n.webp',
        'photos/0324/thumb/430886031_445633714567238_36989624415800671_n.webp',
        'photos/0324/thumb/431808371_2932286323585527_2874916430405796078_n.webp',
        'photos/0324/thumb/432070499_463241699359936_7421863802141953175_n.webp',
        'photos/0324/thumb/432103079_449899797391071_4792125272153427356_n.webp',
        'photos/0324/thumb/432236963_1195370341627393_651861000222837698_n.webp',
        'photos/0324/thumb/432240914_7749857568391638_9020957696270964212_n.webp',
        'photos/0324/thumb/432256860_338500178747292_1334662578081943213_n.webp',
        'photos/0324/thumb/432299569_780382537351355_8022460991310788164_n.webp',
        'photos/0324/thumb/432302469_1425887898314980_7739924586292682247_n.webp',
        'photos/0324/thumb/432364119_1764802740677374_4058578495482701173_n.webp',
        'photos/0324/thumb/432365593_3638819829767284_5587952897376054910_n.webp',
        'photos/0324/thumb/432393584_1183607975955606_5239717738516725307_n.webp',
        'photos/0324/thumb/432397988_946180766704851_4223141686408811083_n.webp',
        'photos/0324/thumb/432402965_392388860088174_8619894809300793440_n.webp',
        'photos/0324/thumb/432420001_3827257077504926_2603746489682725672_n.webp',
        'photos/0324/thumb/432420746_834696218718079_5310641736960445027_n.webp',
        'photos/0324/thumb/432430759_719882080336254_6350803415951550292_n.webp',
        'photos/0324/thumb/432459308_1505627706653269_1546901801654150634_n.webp',
        'photos/0324/thumb/432476497_1484807795723680_3903183243599771307_n.webp',
        'photos/0324/thumb/432516418_449988000702542_6121733364024129401_n.webp',
        'photos/0324/thumb/433001396_936803991076587_1842759503892995974_n.webp',
        'photos/0324/thumb/434162213_1094668768415349_410630173231180654_n.webp',
        'photos/0324/thumb/434163397_974580377348781_525686494252566580_n.webp'
      ],
      imagesLenght: 31
    }, {
      id: 7,
      folderName: 'Powitaliśmy wiosnę - nagrania',
      videos: [
        'videos/0324/10000000_7258125374223141_393690573233815304_n.mp4',
        'videos/0324/10000000_24967168392929808_9084193281474415010_n.mp4',
        'videos/0324/10000000_25060047723642291_7361470569645423081_n.mp4',
        'videos/0324/10000000_25165871323027625_6797871676453793281_n.mp4'
      ],
      videosLenght: 3
    }, {
      id: 8,
      folderName: 'Przed Wielkanocą',
      images: [
        'photos/0324/thumb/419348480_743333081201544_1982165580501628068_n.webp',
        'photos/0324/thumb/431441123_966214351747993_5425959582696606038_n.webp',
        'photos/0324/thumb/432425164_1601832600633208_3890508325198745208_n.webp',
        'photos/0324/thumb/432438840_443986434649240_7035235010291341786_n.webp',
        'photos/0324/thumb/432472872_275543418929680_5232754597546951095_n.webp',
        'photos/0324/thumb/432504425_446944214337026_426727908294657467_n.webp',
        'photos/0324/thumb/432510856_1351972672154754_2136458642097994964_n.webp',
        'photos/0324/thumb/432516419_1471212137138889_1292002315186539049_n.webp',
        'photos/0324/thumb/432519425_1180016396296508_1244046162734923743_n.webp',
        'photos/0324/thumb/432527501_429332172943047_1500646740157631807_n.webp',
        'photos/0324/thumb/432536595_1362908241093301_5723678841270602135_n.webp',
        'photos/0324/thumb/432674856_461180333259554_8810520760601031755_n.webp',
        'photos/0324/thumb/432810569_424758830232711_7164635725109471725_n.webp',
        'photos/0324/thumb/434129659_928623862601728_1563796278967271820_n.webp',
        'photos/0324/thumb/434132543_1737407533402363_4006752665433139534_n.webp',
        'photos/0324/thumb/434136710_2798138700360921_4066724328559885190_n.webp',
        'photos/0324/thumb/434142139_1170189181028915_4532718727761776000_n.webp',
        'photos/0324/thumb/434143592_407764868537897_6650151955964656801_n.webp',
        'photos/0324/thumb/434157481_380819694876394_7473498825113998542_n.webp',
        'photos/0324/thumb/434163401_942117627619162_3464712334486860578_n.webp',
        'photos/0324/thumb/434171566_379576284997653_2820024728086551043_n.webp',
        'photos/0324/thumb/434180692_1553287778787173_7648506312463889008_n.webp',
        'photos/0324/thumb/434182454_406736025415154_1463709816633740735_n.webp',
        'photos/0324/thumb/434183185_3337198556587908_3536227771855036942_n.webp',
        'photos/0324/thumb/434184962_389364597239554_2064701120863466847_n.webp',
        'photos/0324/thumb/434184969_375147848684778_2210467714356784123_n.webp',
        'photos/0324/thumb/434191792_1803699523387649_8907508421327441657_n.webp',
        'photos/0324/thumb/434197578_433610395715719_2510841043280032019_n.webp',
        'photos/0324/thumb/434201061_976279400525632_6622937746117214833_n.webp',
        'photos/0324/thumb/434201066_727436662871629_1444931563275795431_n.webp',
        'photos/0324/thumb/434204749_286280354416230_324655808941708615_n.webp',
        'photos/0324/thumb/434210367_958872769216862_5390844715860917875_n.webp',
        'photos/0324/thumb/434214591_3283627958600591_6175859241391604403_n.webp',
        'photos/0324/thumb/434214592_2717776171711770_179476636380778397_n.webp',
        'photos/0324/thumb/434223719_315932751172061_7409537029132837158_n.webp',
        'photos/0324/thumb/434226683_384425617791691_5818287868737715197_n.webp',
        'photos/0324/thumb/434227982_1526728807902815_6985417307414602077_n.webp',
        'photos/0324/thumb/434234013_941797000949965_2714793393036050272_n.webp',
        'photos/0324/thumb/434237097_398396929479048_3267119864322938469_n.webp',
        'photos/0324/thumb/434245815_3724193867852071_8080015513435110527_n.webp',
        'photos/0324/thumb/434247405_7491428857574737_3747797489335644732_n.webp',
        'photos/0324/thumb/434247411_993216882221778_5451301850709695545_n.webp',
        'photos/0324/thumb/434383720_3493601784283196_1693380015957128417_n.webp',
        'photos/0324/thumb/434739858_1731676264240515_5636153568001290786_n.webp'
      ],
      imagesLenght: 43
    }, {
      id: 9,
      folderName: 'Przed Wielkanocą - nagrania',
      videos: [
        'videos/0324/10000000_7054540221324377_6191480564834534851_n.mp4',
        'videos/0324/434429623_7952487464775068_2815929791609560703_n.mp4'
      ],
      videosLenght: 1
    }
  ]
  const images0224 = [
    {
      id: 0,
      folderName: 'Walentynki',
      images: [
        'photos/0224/thumb/426724465_901963071677878_5890013490675978217_n.webp',
        'photos/0224/thumb/426819101_927010922411744_8577092487977570239_n.webp',
        'photos/0224/thumb/426829764_713789877403950_9212429837158883223_n.webp',
        'photos/0224/thumb/426829781_7197780267007018_1073031649992311839_n.webp',
        'photos/0224/thumb/426978208_403708942341610_5811597312851593892_n.webp',
        'photos/0224/thumb/426984853_1168798100769942_7786350952634374241_n.webp',
        'photos/0224/thumb/427721084_950879543070089_651898219700983254_n.webp',
        'photos/0224/thumb/427734086_864225372173025_8603878196539464999_n.webp',
        'photos/0224/thumb/427755538_1569406007194149_8686961972839915201_n.webp',
        'photos/0224/thumb/427771330_413895694502366_2398580702197408759_n.webp',
        'photos/0224/thumb/427777715_932417484646882_2708090493066789891_n.webp',
        'photos/0224/thumb/427786609_929030362145186_7152271197810024724_n.webp',
        'photos/0224/thumb/427795630_1327305731290982_4622567648448133330_n.webp',
        'photos/0224/thumb/427805574_710904434491580_1931797271445344756_n.webp',
        'photos/0224/thumb/427818233_1303744816959150_4190028739996839417_n.webp',
        'photos/0224/thumb/427822172_1345182830211194_861283992663088361_n.webp',
        'photos/0224/thumb/427827981_1477666316298212_6959654611917302565_n.webp',
        'photos/0224/thumb/427850554_385286264095143_916849735308764569_n.webp',
        'photos/0224/thumb/427876766_379880344660529_6858398963753274459_n.webp'
      ],
      imagesLenght: 18
    }, {
      id: 1,
      folderName: 'Walentynki - nagrania',
      videos: [
        'videos/0224/10000000_7479925298713750_2173240346638387953_n.mp4'
      ],
      videosLenght: 0
    }, {
      id: 2,
      folderName: 'Pomagamy dzieciom',
      images: [
        'photos/0224/thumb/427786637_440717361645165_5135705655209209023_n.webp',
        'photos/0224/thumb/427805538_440008818471120_2253146234990559521_n.webp',
        'photos/0224/thumb/427816356_358128813716969_7040249904163156751_n.webp',
        'photos/0224/thumb/427914879_1119437252581549_3269629593595847013_n.webp'
      ],
      imagesLenght: 3
    }, {
      id: 3,
      folderName: 'Na co dzień uczymy się i bawimy',
      images: [
        'photos/0224/thumb/401270125_1837603536694609_7552811709572574995_n.webp',
        'photos/0224/thumb/403403987_1343572640374892_743260643886677302_n.webp',
        'photos/0224/thumb/405932996_371474655463209_1590944919322384504_n.webp',
        'photos/0224/thumb/405948536_1014952266502416_2585581411800398059_n.webp',
        'photos/0224/thumb/406242624_1400669860873856_5960500611933562540_n.webp',
        'photos/0224/thumb/406466063_368577459078101_6753047211081058332_n.webp',
        'photos/0224/thumb/426709944_701311705541106_4721350321632499785_n.webp',
        'photos/0224/thumb/426721442_1071813340714446_37715167999604341_n.webp',
        'photos/0224/thumb/426811510_272406962550185_1728438306606841583_n.webp',
        'photos/0224/thumb/426828302_853566669858062_4647955763971217509_n.webp',
        'photos/0224/thumb/426833045_904380064491692_4792224178517729901_n.webp',
        'photos/0224/thumb/427168455_331216576578698_3157238805622507505_n.webp',
        'photos/0224/thumb/427786607_889389652867474_8840407055748333284_n.webp',
        'photos/0224/thumb/427790873_409843138087194_79000887976477510_n.webp',
        'photos/0224/thumb/427795706_784950657007732_7236833317167014864_n.webp',
        'photos/0224/thumb/427816782_267117009755579_7022893716867832544_n.webp',
        'photos/0224/thumb/427861305_2017362505309416_9073003563536422305_n.webp',
        'photos/0224/thumb/427905597_1427695717829357_1271188360388530306_n.webp',
        'photos/0224/thumb/427920037_763830292331303_2785663967656877061_n.webp',
        'photos/0224/thumb/427949434_1711739362569783_348262456922756693_n.webp',
        'photos/0224/thumb/427957159_1720898201653948_1923244393935287120_n.webp',
        'photos/0224/thumb/428028869_911606643763689_269379202240028808_n.webp',
        'photos/0224/thumb/428125830_1107019440318815_1673518108983137495_n.webp',
        'photos/0224/thumb/428880596_2221113864886923_6381056954754493749_n.webp',
        'photos/0224/thumb/428957540_2637050059830173_7771641448286416244_n.webp'
      ],
      imagesLenght: 24
    }, {
      id: 4,
      folderName: 'Już tylko wspominamy zabawy na śniegu',
      images: [
        'photos/0224/thumb/426720383_2932344750236938_6376041803052525739_n.webp',
        'photos/0224/thumb/426720578_1099537357762194_1937354645996955068_n.webp',
        'photos/0224/thumb/426721442_973353074493299_2320880711740827090_n.webp',
        'photos/0224/thumb/426742332_773773814160688_8184313689471366571_n.webp',
        'photos/0224/thumb/426833045_389836700357942_5135578993510605198_n.webp',
        'photos/0224/thumb/426978226_1409931813289542_1770514470877918679_n.webp',
        'photos/0224/thumb/427117251_1441138959854349_8915963204735352715_n.webp',
        'photos/0224/thumb/427135750_1116127459808795_692761988612915159_n.webp',
        'photos/0224/thumb/427782216_767111191973364_6969591822688433237_n.webp',
        'photos/0224/thumb/427974607_260476483763176_2877107870867128398_n.webp',
        'photos/0224/thumb/427979680_1346319599420431_1136074944152231335_n.webp',
        'photos/0224/thumb/428001133_600051605656039_2511370332255914730_n.webp',
        'photos/0224/thumb/428008816_1075538790442971_2130753845728883835_n.webp',
        'photos/0224/thumb/428077174_1849807075490583_6482277294394523326_n.webp',
        'photos/0224/thumb/428082906_1146786046702352_1114362989142233797_n.webp',
        'photos/0224/thumb/428118602_776830927630791_3134392514631427153_n.webp',
        'photos/0224/thumb/428939254_1443996926187982_7216674469273542639_n.webp',
        'photos/0224/thumb/428969376_435044022192868_2321914137291731682_n.webp'
      ],
      imagesLenght: 17
    }, {
      id: 5,
      folderName: 'Zaprosiliśmy naszych Dziadków',
      images: [
        'photos/0224/thumb/427078277_1754759278380090_2884487380327606776_n.webp',
        'photos/0224/thumb/427188770_383621287620337_5479956677742863574_n.webp',
        'photos/0224/thumb/427861853_359157697083505_3135457851686142348_n.webp',
        'photos/0224/thumb/427953020_370223755903471_251178991773778220_n.webp',
        'photos/0224/thumb/428026953_1080679116344553_4013361057849754936_n.webp',
        'photos/0224/thumb/428833751_2755313637939961_6477808034770444681_n.webp',
        'photos/0224/thumb/428939258_2680285118796342_5121055519300403121_n.webp',
        'photos/0224/thumb/428939260_1311818979691583_3167070191874566443_n.webp'
      ],
      imagesLenght: 7
    }, {
      id: 6,
      folderName: 'Kosmiczne tematy - nagranie',
      videos: [
        'videos/0224/10000000_7814289705249233_953029405242925090_n.mp4'
      ],
      videosLenght: 0
    }, {
      id: 7,
      folderName: 'Poznajemy czasy dinozaurów',
      images: [
        'photos/0224/thumb/428763413_745598860656854_8429776300843503002_n.webp',
        'photos/0224/thumb/428899028_797502239078185_6662713823457657699_n.webp',
        'photos/0224/thumb/428924125_2728313013986662_8589150563471720947_n.webp',
        'photos/0224/thumb/428939255_953286593116102_391860698129205846_n.webp',
        'photos/0224/thumb/428943891_1190671731902186_8211788022770159659_n.webp',
        'photos/0224/thumb/428944853_962883818886624_2928453602949149672_n.webp',
        'photos/0224/thumb/428956129_1139904027443256_7997015058509875997_n.webp',
        'photos/0224/thumb/428957136_435106169082904_3241577380530316819_n.webp',
        'photos/0224/thumb/428958468_1839430149825785_4605541558958089821_n.webp',
        'photos/0224/thumb/428969503_361898800074222_6178339774697776202_n.webp',
        'photos/0224/thumb/428969852_1101728891160263_2134324533997559839_n.webp',
        'photos/0224/thumb/428970146_925464045614899_3378902083678193615_n.webp',
        'photos/0224/thumb/428981365_696070442723344_6584428031226277818_n.webp',
        'photos/0224/thumb/428981792_302735176156303_5512553587936424230_n.webp',
        'photos/0224/thumb/428992437_1137422690611003_3181419340539224738_n.webp',
        'photos/0224/thumb/429032461_365633062953831_1382887580852938694_n.webp',
        'photos/0224/thumb/429562290_1444191459827028_5763705609602828176_n.webp',
        'photos/0224/thumb/429581407_378541898284459_1267015885389792285_n.webp',
        'photos/0224/thumb/429622550_419602860546216_8273890533310310825_n.webp',
        'photos/0224/thumb/429622555_291911507021686_848522623828037467_n.webp',
        'photos/0224/thumb/429635955_1605551386936212_5577602924479583645_n.webp',
        'photos/0224/thumb/429637838_369620732677775_4911453698004222485_n.webp',
        'photos/0224/thumb/429697846_1183535112612565_415792586951448607_n.webp',
        'photos/0224/thumb/429697846_250002828194937_4239927401319965015_n.webp',
        'photos/0224/thumb/429728086_1492857141638161_8802006388791747286_n.webp',
        'photos/0224/thumb/429742797_1085511379437451_2347535694137239662_n.webp',
        'photos/0224/thumb/429769571_1067934814496845_3132592962413603542_n.webp',
        'photos/0224/thumb/429795734_907448580864108_2029060129278329795_n.webp',
        'photos/0224/thumb/429813641_773061961376153_8278931445752838975_n.webp',
        'photos/0224/thumb/429816870_251775528020666_6377669177694603430_n.webp',
        'photos/0224/thumb/429819007_1109581900240487_433787312880606711_n.webp',
        'photos/0224/thumb/429819011_422941560404636_734937324047222779_n.webp',
        'photos/0224/thumb/430145270_1453703715248828_2316799300906951586_n.webp',
        'photos/0224/thumb/430201006_311672591917358_5986641951542849285_n.webp',
        'photos/0224/thumb/430233232_3512634885715577_1317014081526045882_n.webp',
        'photos/0224/thumb/430246588_386347830817722_3075958380246596141_n.webp',
        'photos/0224/thumb/430252152_717472550533958_507979515803926520_n.webp',
        'photos/0224/thumb/430259519_876787747553215_1419460801016412436_n.webp',
        'photos/0224/thumb/430276375_7346908335363794_945707774233420137_n.webp',
        'photos/0224/thumb/430320103_380760694714132_3895221146228332948_n.webp',
        'photos/0224/thumb/430368206_1445020839757247_4206057572971605521_n.webp',
        'photos/0224/thumb/430409393_277379095405935_6132160606674499977_n.webp',
        'photos/0224/thumb/430662308_646360864246215_3012125694674385854_n.webp',
        'photos/0224/thumb/430739178_1115091189844751_6813281043493054340_n.webp'
      ],
      imagesLenght: 43
    }, {
      id: 8,
      folderName: 'Poznajemy czasy dinozaurów - nagranie',
      videos: [
        'videos/0224/10000000_7807590905942463_9188563286189237211_n.mp4'
      ],
      videosLenght: 0
    }
  ]
  const images0124 = [
    {
      id: 0,
      folderName: 'Zimowo i świątecznie - nagrania',
      videos: [
        "videos/0124/10000000_24691137833833041_5945677559152798945_n.mp4",
        "videos/0124/10000000_7308438562545863_7671455079153567350_n.mp4",
        "videos/0124/10000000_24963513473262263_6465721262849318225_n.mp4",
        "videos/0124/415446843_7055830234538399_7412698292300713142_n.mp4",
        "videos/0124/10000000_7028795400490129_7926315889945593439_n.mp4"
      ],
      videosLenght: 4
    }, {
      id: 1,
      folderName: 'Mikołaj u naszych Słoneczek',
      images: [
        'photos/0124/thumb/406560725_1454032512162781_3021078763954819918_n.webp',
        'photos/0124/thumb/406713011_1841142063004157_7901082434083444159_n.webp',
        'photos/0124/thumb/406768354_389891050112120_53988040961672008_n.webp',
        'photos/0124/thumb/406770685_411815407841666_6470978916897819791_n.webp',
        'photos/0124/thumb/406773042_938463014314083_717429712447187063_n.webp',
        'photos/0124/thumb/406793048_3775590802724297_6172338869198230422_n.webp',
        'photos/0124/thumb/407006770_691676469748423_7833163540301775606_n.webp',
        'photos/0124/thumb/407039558_1522676548275469_858853600158775437_n.webp',
        'photos/0124/thumb/407052680_346116028369113_8667513227244954189_n.webp',
        'photos/0124/thumb/407052690_378192391560009_4267699493977148985_n.webp',
        'photos/0124/thumb/407063474_1519046635542791_6461744290692804321_n.webp',
        'photos/0124/thumb/407086234_3613801388908574_5769184043980407825_n.webp',
        'photos/0124/thumb/407086240_1408655750035853_210661362445657406_n.webp',
        'photos/0124/thumb/407105079_1353993472171266_2689021277670547147_n.webp',
        'photos/0124/thumb/407137916_328354370102732_1396931544245058651_n.webp',
        'photos/0124/thumb/407179554_228865526927438_5959590638526616928_n.webp',
        'photos/0124/thumb/407179559_3173175319659069_8557514228531202596_n.webp',
        'photos/0124/thumb/407210302_656413179775697_7531398378167651863_n.webp',
        'photos/0124/thumb/407213917_694784895971749_4764332481133469647_n.webp',
        'photos/0124/thumb/407379484_1840392889715069_241072144368437689_n.webp',
        'photos/0124/thumb/407379488_1481943792382296_8098560622187208159_n.webp',
        'photos/0124/thumb/409373631_894794388603969_3363976259228388941_n.webp',
        'photos/0124/thumb/409392295_1444216279835263_6423680596820870780_n.webp',
        'photos/0124/thumb/409431371_905301150974111_8845520039298477081_n.webp',
        'photos/0124/thumb/409442909_943356497168285_7173790622993588226_n.webp',
        'photos/0124/thumb/409490730_194997827033402_2641446038243781489_n.webp',
        'photos/0124/thumb/409496823_194020260403791_7267545145060889557_n.webp',
        'photos/0124/thumb/409502720_889581385996154_8521284985599532020_n.webp',
        'photos/0124/thumb/409533790_3598808947043745_4347120647861672368_n.webp',
        'photos/0124/thumb/409536310_696958968906506_6149438618662669990_n.webp',
        'photos/0124/thumb/409545143_918220989248674_574747655112628831_n.webp',
        'photos/0124/thumb/409579678_419595617074938_2771514397188258845_n.webp',
        'photos/0124/thumb/409597778_379981148046427_8915529194918018969_n.webp',
        'photos/0124/thumb/409621285_921015748942123_716560092759399330_n.webp',
        'photos/0124/thumb/409622629_669830905302133_7918975278949465332_n.webp',
        'photos/0124/thumb/409646540_395112599693541_7764247935631638389_n.webp',
        'photos/0124/thumb/409669582_1772186479916260_188640820255962093_n.webp',
        'photos/0124/thumb/409677824_1045498236781218_7594173852371222504_n.webp',
        'photos/0124/thumb/409700027_7740452602650663_5734267124353926407_n.webp',
        'photos/0124/thumb/409700036_1031480311466807_4589200887239312578_n.webp',
        'photos/0124/thumb/409756275_6931675836869668_1887722087894706188_n.webp',
        'photos/0124/thumb/410146584_384982757235146_5374520828572695423_n.webp',
        'photos/0124/thumb/410300975_1064350487948081_8490301242705080063_n.webp',
        'photos/0124/thumb/410527958_739880174857215_2757015234132933461_n.webp',
        'photos/0124/thumb/410919051_908057654324823_1183452622145147112_n.webp'
      ],
      imagesLenght: 44
    }, {
      id: 2,
      folderName: 'Świąteczne spotkanie z Teatrem',
      images: [
        'photos/0124/thumb/386453858_909893037157562_3292152169194431448_n.webp',
        'photos/0124/thumb/406973431_391930786732473_1499338412044386893_n.webp',
        'photos/0124/thumb/407019511_1711898029288433_5984985217964275997_n.webp',
        'photos/0124/thumb/407078876_1159988331635560_6308288695614731346_n.webp',
        'photos/0124/thumb/407091441_1401480623821600_94896372994071327_n.webp',
        'photos/0124/thumb/407102351_3761667767397103_2144931563149609274_n.webp',
        'photos/0124/thumb/407146623_1200840570876514_2777109073352649662_n.webp',
        'photos/0124/thumb/407179563_383913220963397_2385041714220460123_n.webp',
        'photos/0124/thumb/407186968_331535419709091_2973385239528437718_n.webp',
        'photos/0124/thumb/407191986_397957295921001_6897195397466748472_n.webp',
        'photos/0124/thumb/407191992_813814863845507_4747762126666477961_n.webp',
        'photos/0124/thumb/407197673_3761780350775904_1929610078162365824_n.webp',
        'photos/0124/thumb/407197678_747358207261661_6683339774566720672_n.webp',
        'photos/0124/thumb/407210310_906204190914332_1796673842508724126_n.webp',
        'photos/0124/thumb/407250662_1797294527383234_4805466077312904077_n.webp',
        'photos/0124/thumb/407268232_752436433574018_2764794349503846387_n.webp',
        'photos/0124/thumb/407271225_385540873873436_5814207899319133984_n.webp',
        'photos/0124/thumb/407277192_251946324581041_7743760892261512749_n.webp',
        'photos/0124/thumb/407298054_1044499250106167_2192325926020676497_n.webp',
        'photos/0124/thumb/407373801_7162031730486368_5497221737849452211_n.webp',
        'photos/0124/thumb/409314381_740847928104038_7359283894290729885_n.webp',
        'photos/0124/thumb/409320804_1644236789440517_6223881551508968431_n.webp',
        'photos/0124/thumb/409358836_1354601968761147_2530101085173813172_n.webp',
        'photos/0124/thumb/409421018_1797039264078666_4988483625562527539_n.webp',
        'photos/0124/thumb/409463976_868839118302146_293555735939228603_n.webp',
        'photos/0124/thumb/409527606_1796715477432121_2603362582893932567_n.webp',
        'photos/0124/thumb/409536301_380091851208510_4599367284573522746_n.webp',
        'photos/0124/thumb/409586411_740557481456197_7547515999095669159_n.webp',
        'photos/0124/thumb/409669576_2967996530001705_9007064040210643364_n.webp',
        'photos/0124/thumb/409700037_1395042084556806_3471694740926127660_n.webp',
        'photos/0124/thumb/409707812_7731527713527483_305751106750510566_n.webp',
        'photos/0124/thumb/409717491_2272336399633515_6027889359679434898_n.webp',
        'photos/0124/thumb/409773567_7113460535404395_6178867723897443729_n.webp',
        'photos/0124/thumb/409773570_255444264087189_504933899907615283_n.webp',
        'photos/0124/thumb/409773573_3690758964533498_2889905874496289160_n.webp',
        'photos/0124/thumb/409799930_1668975500300128_6409088756559082535_n.webp',
        'photos/0124/thumb/409897854_934746394888381_6107213296146753865_n.webp',
        'photos/0124/thumb/409958897_733128728763241_1296425010284657463_n.webp',
        'photos/0124/thumb/410046805_343579321770902_3468976749929783167_n.webp',
        'photos/0124/thumb/410125958_898919065037805_787782623918768548_n.webp',
        'photos/0124/thumb/410151626_1516955365756145_4992998984473461041_n.webp',
        'photos/0124/thumb/410326090_916693286683152_41715832374120146_n.webp',
        'photos/0124/thumb/416766811_1541445220000779_3207175148255594072_n.webp'
      ],
      imagesLenght: 42
    }, {
      id: 3,
      folderName: 'Bal Karnawałowy',
      images: [
        'photos/0124/thumb/416782306_1727852007701114_4671318973263063086_n.webp',
        'photos/0124/thumb/416907707_2205313696479333_197995505512424625_n.webp',
        'photos/0124/thumb/416996063_745088057220568_1250531533726391313_n.webp',
        'photos/0124/thumb/417076310_1069157777539843_2360571802270512369_n.webp',
        'photos/0124/thumb/417150208_356016747372448_8801128225694808965_n.webp',
        'photos/0124/thumb/417200127_705562178043413_784506751178284030_n.webp',
        'photos/0124/thumb/417200128_1491847258421590_6545670641865151386_n.webp',
        'photos/0124/thumb/417234966_1099219157750526_1537942650223002961_n.webp',
        'photos/0124/thumb/417234966_1211030569892594_5971650586553733195_n.webp',
        'photos/0124/thumb/417234979_1766423223837863_1485582055303668786_n.webp',
        'photos/0124/thumb/417236579_789382153227998_3905992923275831239_n.webp',
        'photos/0124/thumb/417239930_767487282081748_5306403458556883264_n.webp',
        'photos/0124/thumb/417241522_326807147015219_6219269798010478876_n.webp',
        'photos/0124/thumb/417248009_749038890443178_5914832780893807874_n.webp',
        'photos/0124/thumb/417248027_1115278829480111_4007850496049482349_n.webp',
        'photos/0124/thumb/417255933_1120018228996958_32972711872519889_n.webp',
        'photos/0124/thumb/417257250_1949670005430670_8943307007682126302_n.webp',
        'photos/0124/thumb/417257251_354916047316298_5982023420809498466_n.webp',
        'photos/0124/thumb/417257256_3637844153149992_800118531175223295_n.webp',
        'photos/0124/thumb/417257263_704591821810979_7974874107367507482_n.webp',
        'photos/0124/thumb/417257269_659043182874768_6472736810349995974_n.webp',
        'photos/0124/thumb/417270423_579927394343971_2447746517683962528_n.webp',
        'photos/0124/thumb/417275071_1032100551200616_3176136562506934620_n.webp',
        'photos/0124/thumb/417275075_753832813469921_5755834782335509062_n.webp',
        'photos/0124/thumb/417289779_1773946549737949_8887599149378342457_n.webp',
        'photos/0124/thumb/417293690_692777899698757_3068152512839778360_n.webp',
        'photos/0124/thumb/417298387_1544573163043115_7091655867381293291_n.webp',
        'photos/0124/thumb/417317469_1665355820539701_5950883493825305791_n.webp',
        'photos/0124/thumb/417438862_1381934579097045_6394875443948728841_n.webp',
        'photos/0124/thumb/417586988_24906731968940099_2849747913674653338_n.webp',
        'photos/0124/thumb/418648036_763694258964281_3361983757842210234_n.webp',
        'photos/0124/thumb/418648363_1445210676426858_1526222330474539784_n.webp',
        'photos/0124/thumb/418651892_322683277431334_6060627542191513878_n.webp',
        'photos/0124/thumb/418669333_364917702845635_7064392848607149998_n.webp',
        'photos/0124/thumb/418696852_1760866711079093_3547210750603928606_n.webp',
        'photos/0124/thumb/418698961_2101651903535302_5653012347712217761_n.webp',
        'photos/0124/thumb/418713433_3464699257173468_610825134890746407_n.webp',
        'photos/0124/thumb/418721809_400451365777697_2812543419804121409_n.webp',
        'photos/0124/thumb/418722602_1573152856853440_3640800789245009332_n.webp',
        'photos/0124/thumb/418722602_3489033594679388_7461440643166979929_n.webp',
        'photos/0124/thumb/418729337_763963015598180_5801099027065645288_n.webp',
        'photos/0124/thumb/418733247_298519283204577_1078457391849886905_n.webp',
        'photos/0124/thumb/418736858_754848986076391_3723515842297672414_n.webp',
        'photos/0124/thumb/418753495_3481159242135927_8876026059396961557_n.webp',
        'photos/0124/thumb/418787673_1783039295538438_1566987175022511961_n.webp',
        'photos/0124/thumb/418811060_768214008659643_6091709455249280193_n.webp',
        'photos/0124/thumb/418826728_368017562604372_1301354733600741598_n.webp',
        'photos/0124/thumb/418838372_1364802157734626_1564579713673316259_n.webp',
        'photos/0124/thumb/418845656_919665599264959_5057765444179813184_n.webp',
        'photos/0124/thumb/418845659_420343947016209_5161488533364689614_n.webp',
        'photos/0124/thumb/418848596_1064770284760757_1331418767119314721_n.webp',
        'photos/0124/thumb/418855445_1385480755440424_3521604368996393100_n.webp',
        'photos/0124/thumb/418864168_347268194783643_2264021112116114467_n.webp',
        'photos/0124/thumb/418867248_2349601888763010_4907551784987242438_n.webp',
        'photos/0124/thumb/418880162_367456879230913_1621908703989587018_n.webp',
        'photos/0124/thumb/418885999_1043140906763838_5502351979815150126_n.webp',
        'photos/0124/thumb/418892688_593466489627847_3412675428484203596_n.webp',
        'photos/0124/thumb/418923145_1064719181447952_6116160322532512173_n.webp',
        'photos/0124/thumb/418951753_756298595922473_8788705262332472623_n.webp',
        'photos/0124/thumb/419303604_306164895771516_6329569101743468017_n.webp',
        'photos/0124/thumb/420553268_724757306289214_597094794613290166_n.webp',
        'photos/0124/thumb/421414199_1372198000099724_2852447735875050407_n.webp',
        'photos/0124/thumb/421432389_392234446676869_9183513395220456726_n.webp',
        'photos/0124/thumb/421581326_1074731913677779_8926186592349497253_n.webp',
        'photos/0124/thumb/421593096_1056225615686128_5562009519105784108_n.webp',
        'photos/0124/thumb/421606180_784441810200924_6329134937556777374_n.webp',
        'photos/0124/thumb/421644896_948105883401846_4095052243610951268_n.webp',
        'photos/0124/thumb/421652731_298757009446312_8735259658571294146_n.webp',
        'photos/0124/thumb/421661103_410230508110326_5480790020562212514_n.webp',
        'photos/0124/thumb/421671628_897603238764459_2266805146204949457_n.webp',
        'photos/0124/thumb/421681122_359044320239258_6168772689170092960_n.webp',
        'photos/0124/thumb/421688765_347563764819341_1617094682563538283_n.webp',
        'photos/0124/thumb/421701853_3552204745044842_407545987901514100_n.webp',
        'photos/0124/thumb/421716047_7333745756690170_5895388409255846732_n.webp',
        'photos/0124/thumb/421742571_1565942297541758_7267390200857657863_n.webp',
        'photos/0124/thumb/421779530_1067715087892741_9210648486509834153_n.webp',
        'photos/0124/thumb/421825650_1066360851256286_4574288228287019915_n.webp',
        'photos/0124/thumb/421860644_917773403041118_8801340505470750438_n.webp',
        'photos/0124/thumb/421872419_1098498831345295_6131469629837495465_n.webp',
        'photos/0124/thumb/421883981_938933434521824_4989425558828320370_n.webp',
        'photos/0124/thumb/422092950_382288261058520_8600821309384133488_n.webp',
        'photos/0124/thumb/422140986_912366650419138_7695040907755063022_n.webp',
        'photos/0124/thumb/422145974_1373372863303158_4461583643817690986_n.webp',
        'photos/0124/thumb/422152190_1449307002672046_957074671985706562_n.webp'
      ],
      imagesLenght: 83
    }, {
      id: 4,
      folderName: 'Bal Karnawałowy - nagrania',
      videos: [
        'videos/0124/421871727_25331469279777293_268670575751174141_n.mp4',
        'videos/0124/421906348_24646054785040345_22041205722124077_n.mp4',
        'videos/0124/422349123_24851223424523879_2560726831177142109_n.mp4'
      ],
      videosLenght: 2
    }
  ]
  const images1223 = [
    {
      id: 0,
      folderName: 'Mikołajki - nagrania',
      videos: [
        'videos/1223/407522427_6847449655371964_3497514401319685855_n.mp4',
        'videos/1223/10000000_7908594222489747_437250857325001839_n.mp4'
      ],
      videosLenght: 1
    }, {
      id: 1,
      folderName: 'Mikołajki',
      images: [
        'photos/1223/thumb/386451233_1295230924473676_7007316179329370933_n.webp',
        'photos/1223/thumb/386451894_256015490819900_1267232269885636847_n.webp',
        'photos/1223/thumb/403341359_884537056500235_8986299242355758369_n.webp',
        'photos/1223/thumb/403394842_1813223682445763_4298308423097512699_n.webp',
        'photos/1223/thumb/403398629_315079328088938_5186868237830199800_n.webp',
        'photos/1223/thumb/403398638_380713807633947_6332745790062904159_n.webp',
        'photos/1223/thumb/403401536_666553755560203_8471019908302989845_n.webp',
        'photos/1223/thumb/403402124_1776777486094345_5547289477715768762_n.webp',
        'photos/1223/thumb/403404013_767611775229724_6823731920592069991_n.webp',
        'photos/1223/thumb/403406779_1015049952885387_4365568223815163843_n.webp',
        'photos/1223/thumb/403406796_874279987681941_7913556431524584774_n.webp',
        'photos/1223/thumb/403406892_709395671165876_2160648572580737167_n.webp',
        'photos/1223/thumb/403408129_1838889263209867_7500985498636597849_n.webp',
        'photos/1223/thumb/403408853_208081962273587_206790801802771788_n.webp',
        'photos/1223/thumb/403411753_321557580685687_852296366137505385_n.webp',
        'photos/1223/thumb/403412984_1434789984096478_2594060661257134454_n.webp',
        'photos/1223/thumb/403416307_314110728162705_5062145713215031832_n.webp',
        'photos/1223/thumb/403419840_688320596761605_4212051249254970358_n.webp',
        'photos/1223/thumb/403478153_705324361555882_8938041262550975866_n.webp',
        'photos/1223/thumb/405926368_1396564164625521_7991186173090878594_n.webp',
        'photos/1223/thumb/405946300_376416008138171_5357998015018160198_n.webp',
        'photos/1223/thumb/406006458_1116613323112356_9168690584761175214_n.webp',
        'photos/1223/thumb/406053745_980421537027617_8369636318807132890_n.webp',
        'photos/1223/thumb/406093612_1056102152093442_7308358150459523981_n.webp',
        'photos/1223/thumb/406189252_1395098871088052_6060996118409681332_n.webp',
        'photos/1223/thumb/406219414_714902530305153_3137935996622802411_n.webp',
        'photos/1223/thumb/406275054_1470549553504291_6138837760088726528_n.webp',
        'photos/1223/thumb/406467907_7063105720470043_3660117763252156270_n.webp',
        'photos/1223/thumb/406484795_895812692005321_7107789133357658877_n.webp',
        'photos/1223/thumb/407049233_853696966400360_3382545672869017583_n.webp'
      ],
      imagesLenght: 29
    }, {
      id: 2,
      folderName: 'Spotkanie z teatrem',
      images: [
        'photos/1223/thumb/403394776_809772480835382_7002664154442129595_n.webp',
        'photos/1223/thumb/403399501_1551376912069869_7859251459867417035_n.webp',
        'photos/1223/thumb/403403479_1049407483061243_4044133971345670537_n.webp',
        'photos/1223/thumb/403406887_1567760460427053_1315501827058027524_n.webp',
        'photos/1223/thumb/403411787_265738026492099_1718072240142647100_n.webp',
        'photos/1223/thumb/403412523_1492734208172503_3139771161326504408_n.webp',
        'photos/1223/thumb/405935265_1029980004922223_2463676831841798806_n.webp',
        'photos/1223/thumb/406001685_1144940766480755_2886291032852066073_n.webp',
        'photos/1223/thumb/406115625_887836173062705_3801370684435712442_n.webp',
        'photos/1223/thumb/406129834_888153996001808_2870798787138705907_n.webp',
        'photos/1223/thumb/406205164_3483187155325122_1330389442871242774_n.webp',
        'photos/1223/thumb/406365137_1019711582594060_3964253806500937165_n.webp',
        'photos/1223/thumb/406369509_744860007521873_4336652737195126730_n.webp',
        'photos/1223/thumb/406459172_1037407877527003_2259557249007911096_n.webp',
        'photos/1223/thumb/406694795_1808483462956090_5669391656440716481_n.webp'
      ],
      imagesLenght: 14
    }, {
      id: 3,
      folderName: 'My się zimy nie boimy',
      images: [
        'photos/1223/thumb/386439656_1043911323588332_234117513294098834_n.webp',
        'photos/1223/thumb/386853506_923362316074196_907377597478759507_n.webp',
        'photos/1223/thumb/386866413_903017141205570_7311051059462514586_n.webp',
        'photos/1223/thumb/386875556_888897139257763_274164780381468276_n.webp',
        'photos/1223/thumb/403394336_1565622160843206_5921226610830186036_n.webp',
        'photos/1223/thumb/403394883_1079363346395910_781387673187243806_n.webp',
        'photos/1223/thumb/403398657_659650666367468_2366170203746711487_n.webp',
        'photos/1223/thumb/403399501_1019978629260922_8614911642396536231_n.webp',
        'photos/1223/thumb/403401976_667796845424233_6900017413475865448_n.webp',
        'photos/1223/thumb/403402083_1150808009638551_328143610355511831_n.webp',
        'photos/1223/thumb/403402148_886581912998937_5481513448409368176_n.webp',
        'photos/1223/thumb/403406205_1482576725644995_2888542470585663959_n.webp',
        'photos/1223/thumb/403408859_892550832306698_3500844304939680639_n.webp',
        'photos/1223/thumb/403411756_927769812241998_7711756148961881528_n.webp',
        'photos/1223/thumb/403412967_164711843370818_7382264250030656357_n.webp',
        'photos/1223/thumb/403414900_7126811800714412_3589007736544023469_n.webp',
        'photos/1223/thumb/405802583_651879630446092_2022192293639869472_n.webp',
        'photos/1223/thumb/405835024_721892329530254_6569442581191825173_n.webp',
        'photos/1223/thumb/405919472_725440759456380_8666539514279603957_n.webp',
        'photos/1223/thumb/406109952_1062150644923135_8467142824398342532_n.webp',
        'photos/1223/thumb/406131898_3500733026816285_5600543496320356605_n.webp',
        'photos/1223/thumb/406181390_1060560975070701_8815538437166340700_n.webp',
        'photos/1223/thumb/406198451_862488942025354_8769008624616344874_n.webp',
        'photos/1223/thumb/406222356_662684352695843_1867944945863064748_n.webp',
        'photos/1223/thumb/406410423_1023099825624327_1614284786348692153_n.webp',
        'photos/1223/thumb/406435581_1032376804641631_4804395411804060078_n.webp',
        'photos/1223/thumb/406498675_1055239195664377_1841248850693835469_n.webp',
        'photos/1223/thumb/406526600_270873199308730_6746852598789566655_n.webp',
        'photos/1223/thumb/406657424_718154406594743_2640352268690670069_n.webp'
      ],
      imagesLenght: 28
    }, {
      id: 4,
      folderName: 'Podsumowanie Konkursu Plastycznego „Świąteczna Kartka”',
      images: [
        'photos/1223/thumb/386882931_703813855058115_1878469588627805504_n.webp',
        'photos/1223/thumb/403408859_6852371278150263_6284645708140699229_n.webp',
        'photos/1223/thumb/405978892_319861214271763_235603332586162282_n.webp',
        'photos/1223/thumb/405998784_697878165773257_7297702066925210391_n.webp',
        'photos/1223/thumb/406055628_1859385861144179_1307999115018778690_n.webp',
        'photos/1223/thumb/406106258_173334475867010_8203523185919746571_n.webp',
        'photos/1223/thumb/406394625_718965253231985_5920959990218510216_n.webp',
        'photos/1223/thumb/406444520_338750528899736_8188339622980902808_n.webp',
        'photos/1223/thumb/406553671_738975031011507_7679890692656918112_n.webp',
        'photos/1223/thumb/406746227_6558902294211159_1595046730029448749_n.webp',
        'photos/1223/thumb/406959512_1059828018584180_8304987459416945281_n.webp',
        'photos/1223/thumb/407194794_903229530890692_2090867715490681020_n.webp'
      ],
      imagesLenght: 11
    }, {
      id: 5,
      folderName: 'Dekorujemy pierniczki',
      images: [
        'photos/1223/thumb/367215070_1040917567128772_1925975402681256207_n.webp',
        'photos/1223/thumb/386837159_2548344655324758_7585723642209461642_n.webp',
        'photos/1223/thumb/386868833_368195735707272_6595342257403375128_n.webp',
        'photos/1223/thumb/386887341_374801724951386_3980435986562351087_n.webp',
        'photos/1223/thumb/403392764_1512737305962020_6316530855811363528_n.webp',
        'photos/1223/thumb/403406450_894683832187896_5841202638289191882_n.webp',
        'photos/1223/thumb/403406963_327085123509846_1775755217404143971_n.webp',
        'photos/1223/thumb/406008948_1382038516035410_9133229958580104227_n.webp',
        'photos/1223/thumb/406013747_909603080732289_2283703833282000764_n.webp',
        'photos/1223/thumb/406240071_778432237445162_6488399948398684828_n.webp',
        'photos/1223/thumb/406378182_681665197130100_1318428675772707755_n.webp',
        'photos/1223/thumb/406382630_1078612190146745_4414218772785301395_n.webp',
        'photos/1223/thumb/406937445_904381451030522_5451295025352914988_n.webp',
        'photos/1223/thumb/406939716_1046654566556830_4142029568419973037_n.webp',
        'photos/1223/thumb/407105070_317735007391961_2346685849278469086_n.webp',
        'photos/1223/thumb/409442898_374037351827656_4900594749813062105_n.webp',
        'photos/1223/thumb/409516985_900345388068422_1960028335602367373_n.webp',
        'photos/1223/thumb/409875818_1641552116649226_6135126674516782138_n.webp'
      ],
      imagesLenght: 17
    }, {
      id: 6,
      folderName: 'Zdrowych i wesołych Świąt Bożego Narodzenia',
      images: [
        'photos/1223/thumb/386879334_329403843313127_6132629399352154285_n.webp',
        'photos/1223/thumb/403399486_326767636979236_6497228987647783905_n.webp',
        'photos/1223/thumb/403401557_919975306199191_3587840397778771327_n.webp',
        'photos/1223/thumb/406046983_363445316228921_8351528364040802212_n.webp',
        'photos/1223/thumb/406212377_1074591920551437_333168592580639409_n.webp',
        'photos/1223/thumb/406530598_5291099687680312_2484526973940472055_n.webp',
        'photos/1223/thumb/407231679_1473851260205368_2521170408297538652_n.webp',
        'photos/1223/thumb/409773571_1053228452547468_35504197886594307_n.webp'
      ],
      imagesLenght: 7
    }, {
      id: 7,
      folderName: 'Urodzinki naszej Sofii',
      images: [
        'photos/1223/thumb/403402056_2141998746167741_688211921540530332_n.webp',
        'photos/1223/thumb/403417605_323758470571381_6896838310884791273_n.webp',
        'photos/1223/thumb/403439686_859773912551142_6327710003743992242_n.webp',
        'photos/1223/thumb/406008947_1272626133418015_2377146254300317047_n.webp',
        'photos/1223/thumb/406151831_743905667177957_1588228525612948445_n.webp',
        'photos/1223/thumb/406225131_1036612564280315_3990621280950937733_n.webp',
        'photos/1223/thumb/406628570_2695692680585381_2193862339449293886_n.webp',
        'photos/1223/thumb/406919573_2087267008304861_2980914747068510237_n.webp',
        'photos/1223/thumb/407045493_1286289259439085_3871632263651213247_n.webp',
        'photos/1223/thumb/407107818_1306730246568017_6768625073262301204_n.webp',
        'photos/1223/thumb/407197674_3641325059445672_4913607852639085025_n.webp',
        'photos/1223/thumb/407298054_2802940736524532_4415812508757932581_n.webp',
        'photos/1223/thumb/407301689_742490151267351_6957874975736988479_n.webp',
        'photos/1223/thumb/407385570_409356948090518_3592308195010234179_n.webp',
        'photos/1223/thumb/409366115_277409778668384_6074014830249278743_n.webp',
        'photos/1223/thumb/409402041_352734630700954_9041791526912093852_n.webp',
        'photos/1223/thumb/409468060_1554687075299354_2634784768412638277_n.webp',
        'photos/1223/thumb/409554768_328512353408909_1554690993172658427_n.webp',
        'photos/1223/thumb/409994489_361962146523827_9212431030484519969_n.webp'
      ],
      imagesLenght: 18
    }
  ]
  const images1123 = [
    {
      id: 0,
      folderName: 'Naci mali patrioci - nagrania',
      videos: [
        'videos/1123/10000000_6821068017969758_2031863764881289099_n.mp4',
        'videos/1123/10000000_7224594580905192_26818919768036363_n.mp4',
        'videos/1123/401148612_24050224961288402_2579174109224746274_n.mp4'
      ],
      videosLenght: 2
    }, {
      id: 1,
      folderName: 'Nasi mali patrioci',
      images: [
        'photos/1123/thumb/368082151_318016257749168_1185390830364199615_n.webp',
        'photos/1123/thumb/377307564_207190112411224_2965995486750719734_n.webp',
        'photos/1123/thumb/397079135_354958177048794_3611022135856455114_n.webp',
        'photos/1123/thumb/397084852_1372297390042239_4474920030817962696_n.webp',
        'photos/1123/thumb/399201095_1451783482050843_6832978801269400917_n.webp',
        'photos/1123/thumb/399218981_1007204273728865_1508141782675321236_n.webp',
        'photos/1123/thumb/399677489_1791211027998040_6291797766460962482_n.webp',
        'photos/1123/thumb/399713954_361142079645430_6834934929087585176_n.webp',
        'photos/1123/thumb/399716918_310914678426579_6871018952154788237_n.webp',
        'photos/1123/thumb/399765174_3602547443359617_2299565097150247858_n.webp',
        'photos/1123/thumb/399768164_638814401788522_451865275332561466_n.webp',
        'photos/1123/thumb/399788022_1018542969264301_8539975358397359925_n.webp',
        'photos/1123/thumb/399799253_1533964100685264_1867211880358922655_n.webp',
        'photos/1123/thumb/399808006_6589120357882563_718236827077899107_n.webp',
        'photos/1123/thumb/399828753_786613496555618_8034547258708201027_n.webp',
        'photos/1123/thumb/399868187_729536172550765_2272213919601769404_n.webp',
        'photos/1123/thumb/399895196_1056012792211566_1339943848470489223_n.webp',
        'photos/1123/thumb/399903343_682427653991038_2075742160832695740_n.webp',
        'photos/1123/thumb/399906362_892531689095815_4502880607946132139_n.webp',
        'photos/1123/thumb/400300190_1316543865893273_2064656178114243392_n.webp'
      ],
      imagesLenght: 19
    }, {
      id: 2,
      folderName: 'Kochamy ptaki i zwierzęta',
      images: [
        'photos/1123/thumb/393946648_1055455609138389_8344635431705585805_n.webp',
        'photos/1123/thumb/396778761_2166467257035467_6546673625651388666_n.webp',
        'photos/1123/thumb/397084844_845372207373603_5699458509949470753_n.webp',
        'photos/1123/thumb/399192631_1794625017635924_7057141928302713160_n.webp',
        'photos/1123/thumb/399710740_281979934825767_1974787462150088163_n.webp',
        'photos/1123/thumb/399713945_795207152289283_6477229211602848701_n.webp',
        'photos/1123/thumb/399730291_1289841581691476_6779290676320695798_n.webp',
        'photos/1123/thumb/399755340_1617074272033354_7416801209526245826_n.webp',
        'photos/1123/thumb/399816565_660102452906094_6941648367176013416_n.webp',
        'photos/1123/thumb/399871395_706144594775737_8064370117575974352_n.webp',
        'photos/1123/thumb/400294682_2094379327563232_3230516912835857003_n.webp',
        'photos/1123/thumb/400344658_1359447417988216_2599311239897842672_n.webp',
        'photos/1123/thumb/400769446_638331361707107_8513824150457356166_n.webp',
        'photos/1123/thumb/400776640_338456168788434_5897461684128458139_n.webp'
      ],
      imagesLenght: 13
    }, {
      id: 3,
      folderName: 'Na sportowo przed zimą',
      images: [
        'photos/1123/thumb/399190223_1421194175405149_2484462976163659162_n.webp',
        'photos/1123/thumb/399697604_356519656744791_2101919389513353810_n.webp',
        'photos/1123/thumb/399725631_3498833280372335_404916598569621476_n.webp',
        'photos/1123/thumb/399761931_2301877456868787_6471866089314598814_n.webp',
        'photos/1123/thumb/399765176_6597200547044907_1068344536398604687_n.webp',
        'photos/1123/thumb/399765179_1287076941982244_4792006122473528507_n.webp',
        'photos/1123/thumb/399776758_889993476073010_4041506519723990171_n.webp',
        'photos/1123/thumb/399799266_1020942759144375_1351562355537080944_n.webp',
        'photos/1123/thumb/400015277_233432256419673_2018523767184874375_n.webp',
        'photos/1123/thumb/400022304_298687856405420_4435485625977102636_n.webp',
        'photos/1123/thumb/401090577_322712363701701_481740181577100389_n.webp'
      ],
      imagesLenght: 10
    }, {
      id: 4,
      folderName: 'Lubimy eksperymentować',
      images: [
        'photos/1123/thumb/399195805_844904144089446_1973065650340458130_n.webp',
        'photos/1123/thumb/399673682_859644985541503_4893856241464036465_n.webp',
        'photos/1123/thumb/399735487_25174326415500245_116090377467504239_n.webp',
        'photos/1123/thumb/399807993_288015386892616_3665171676873520710_n.webp',
        'photos/1123/thumb/399819574_725442028914413_2895430014571264597_n.webp',
        'photos/1123/thumb/399895199_1431802207398114_7494737594091969753_n.webp',
        'photos/1123/thumb/400300186_1545044142702350_7736468428906605282_n.webp',
        'photos/1123/thumb/400451087_225404803761521_6537616297677697570_n.webp'
      ],
      imagesLenght: 7
    }, {
      id: 5,
      folderName: 'Urodziny naszej Ali - nagrania',
      videos: [
        'videos/1123/403133601_24068632306116714_3354276310418005893_n.mp4'
      ],
      videosLenght: 0
    }, {
      id: 6,
      folderName: 'Urodziny naszej Ali',
      images: [
        'photos/1123/thumb/363484575_303638939171685_8058275604628262306_n.webp',
        'photos/1123/thumb/369440720_804486284763194_6727070335968099387_n.webp',
        'photos/1123/thumb/369568443_162078553658894_1806096247638038759_n.webp',
        'photos/1123/thumb/370142325_218675367920270_5883669980895644271_n.webp',
        'photos/1123/thumb/370236026_1616826648723105_7524893924648765315_n.webp',
        'photos/1123/thumb/370236046_1513236556181014_6532207556183994319_n.webp',
        'photos/1123/thumb/370249911_676061651392260_7834294514253329190_n.webp',
        'photos/1123/thumb/370277855_1005160060780815_2007120526975075746_n.webp',
        'photos/1123/thumb/370284325_303386869323098_9124243960935083764_n.webp',
        'photos/1123/thumb/370815314_905325997687154_3587585818655766285_n.webp',
        'photos/1123/thumb/387518354_1494995697981085_1144428210249303643_n.webp',
        'photos/1123/thumb/393832167_1564195111056747_7019741256330266188_n.webp',
        'photos/1123/thumb/393990868_1328619507763294_2271117287581321122_n.webp',
        'photos/1123/thumb/394025328_198121496601284_6228214864219148522_n.webp',
        'photos/1123/thumb/399212031_3521453491441722_7670726989456520565_n.webp',
        'photos/1123/thumb/399719915_748711877297567_8145041488136001616_n.webp',
        'photos/1123/thumb/399735489_625282789576268_4633818154799278969_n.webp',
        'photos/1123/thumb/399751872_2784168361726353_8014899158256939004_n.webp',
        'photos/1123/thumb/399773923_870395988049257_4848105680668254617_n.webp',
        'photos/1123/thumb/399776777_879354863404060_896049788157158656_n.webp',
        'photos/1123/thumb/399779570_211566558558726_469617993758201092_n.webp',
        'photos/1123/thumb/399788011_3615563708663419_2914376550798915176_n.webp',
        'photos/1123/thumb/399799249_3323888104423935_1477552235106015917_n.webp',
        'photos/1123/thumb/399816571_2116769502048916_9114664046205982279_n.webp',
        'photos/1123/thumb/399819581_879042760144667_5398504648043761685_n.webp',
        'photos/1123/thumb/399831933_644174884578973_2840708923474763943_n.webp',
        'photos/1123/thumb/399876911_310252325273610_4430270065604380020_n.webp',
        'photos/1123/thumb/399876928_719404126795029_8554824517301483926_n.webp',
        'photos/1123/thumb/399891490_1535745123828762_6073378366023488794_n.webp',
        'photos/1123/thumb/399906361_283205174119455_7468798986411085478_n.webp',
        'photos/1123/thumb/399979148_352869180735031_7162331649685549470_n.webp',
        'photos/1123/thumb/400025798_774985924388367_6095104008962742647_n.webp',
        'photos/1123/thumb/400035955_1260171468712539_4676622214562798189_n.webp',
        'photos/1123/thumb/400048061_725917892202367_369497200864538281_n.webp',
        'photos/1123/thumb/400048070_350926184013413_3278496567544867900_n.webp',
        'photos/1123/thumb/400069429_1281038685928364_1631212415923808056_n.webp',
        'photos/1123/thumb/400072434_355781550274052_1534805446224869556_n.webp',
        'photos/1123/thumb/400080616_648195104062002_7158177016024212238_n.webp',
        'photos/1123/thumb/400080617_721251072769184_8276249683740683671_n.webp',
        'photos/1123/thumb/400101102_685654500331871_4965635206972848854_n.webp',
        'photos/1123/thumb/400375661_1468901673695120_9155489559864794362_n.webp',
        'photos/1123/thumb/400460710_1474566779930966_331056631087225430_n.webp',
        'photos/1123/thumb/400497803_304430829102519_3809407516260146240_n.webp',
        'photos/1123/thumb/400503985_1397168974564090_8632403135911190752_n.webp',
        'photos/1123/thumb/400503986_721432526572952_9000591373120561633_n.webp',
        'photos/1123/thumb/400541319_1422553828690469_5889040143184140529_n.webp'
      ],
      imagesLenght: 45
    }, {
      id: 7,
      folderName: 'Zdrowe śniadanie',
      images: [
        'photos/1123/thumb/368070868_211231001924899_8863863392087546565_n.webp',
        'photos/1123/thumb/368145647_1064012221616733_4933054497295264380_n.webp',
        'photos/1123/thumb/376393472_1035268164186983_1715544837994688618_n.webp',
        'photos/1123/thumb/394031080_315150037975856_7649269441860997687_n.webp',
        'photos/1123/thumb/399713948_853314899616752_2803966186347089705_n.webp',
        'photos/1123/thumb/399771115_1011741696607474_6248443846207259698_n.webp',
        'photos/1123/thumb/399796468_342106841741244_2645280620415066772_n.webp',
        'photos/1123/thumb/399816569_291439280529738_6368718600557046333_n.webp',
        'photos/1123/thumb/399816584_881493783334968_4023671952360865536_n.webp',
        'photos/1123/thumb/399822563_368163998966127_8636980468344998544_n.webp',
        'photos/1123/thumb/399846256_678981727661104_5362345827628157619_n.webp',
        'photos/1123/thumb/399858251_256451790738684_3010909185182595292_n.webp',
        'photos/1123/thumb/399858271_885649659854297_6732031656116970669_n.webp',
        'photos/1123/thumb/400008716_3691556011117463_7263968043219710695_n.webp',
        'photos/1123/thumb/400025815_335877255756205_8197987148774943201_n.webp',
        'photos/1123/thumb/400080620_366589339274702_2494516718598940317_n.webp',
        'photos/1123/thumb/400093797_369093098881852_2265457770277622295_n.webp',
        'photos/1123/thumb/400277167_1303877443656819_49210108643218883_n.webp',
        'photos/1123/thumb/400326491_1526677531421529_7587004596663834498_n.webp',
        'photos/1123/thumb/400387659_161457113720783_1951218114991781111_n.webp'
      ],
      imagesLenght: 19
    }, {
      id: 8,
      folderName: 'Andrzejkowe zabawy',
      images: [
        'photos/1123/thumb/393815910_1084337039670308_5744627284859243949_n.webp',
        'photos/1123/thumb/400728182_891245262414110_1492259574169316025_n.webp',
        'photos/1123/thumb/401083067_905316230474453_8033095909284841156_n.webp',
        'photos/1123/thumb/401145879_787850829773921_401266252926372062_n.webp',
        'photos/1123/thumb/401233805_1060570474964896_6659891372903249351_n.webp',
        'photos/1123/thumb/401239305_884304109745131_932975762132283875_n.webp',
        'photos/1123/thumb/401270125_1837603536694609_7552811709572574995_n.webp',
        'photos/1123/thumb/403376935_1061436828314465_8014188268818870916_n.webp',
        'photos/1123/thumb/403398505_913546773518565_4901025002464908912_n.webp',
        'photos/1123/thumb/403402133_883198366787433_4321776959906978307_n.webp',
        'photos/1123/thumb/403402136_215470401587684_7185234855520681668_n.webp',
        'photos/1123/thumb/403405907_3509945132651937_8050924721348905183_n.webp',
        'photos/1123/thumb/403406439_1475080023035434_371984643914332258_n.webp',
        'photos/1123/thumb/403412890_919813172912109_5404422556237674700_n.webp',
        'photos/1123/thumb/403415633_882129580280320_1733433317482547061_n.webp',
        'photos/1123/thumb/405564870_3678691419084714_7216381865282160672_n.webp',
        'photos/1123/thumb/405919477_882444796585257_1890783773797308958_n.webp',
        'photos/1123/thumb/405946298_1017725659461261_2316486024662609364_n.webp',
        'photos/1123/thumb/405948536_1014952266502416_2585581411800398059_n.webp',
        'photos/1123/thumb/405958293_1076659540133789_2748858818542843435_n.webp',
        'photos/1123/thumb/406004123_1938910836504324_7176551437942081139_n.webp',
        'photos/1123/thumb/406043254_395339452817201_125048537640103334_n.webp',
        'photos/1123/thumb/406108066_652570620419307_2078961573682738055_n.webp',
        'photos/1123/thumb/406129834_2057464344605688_5405806208751055227_n.webp',
        'photos/1123/thumb/406140481_1410434566218859_3590608588613228153_n.webp',
        'photos/1123/thumb/406171133_1283451449011491_4825132235196989750_n.webp',
        'photos/1123/thumb/406242624_1400669860873856_5960500611933562540_n.webp',
        'photos/1123/thumb/406253947_911227100656754_6151805156110365642_n.webp',
        'photos/1123/thumb/406253950_6935944563165167_987538918243056509_n.webp',
        'photos/1123/thumb/406256154_2055722384804828_3405637785353341120_n.webp',
        'photos/1123/thumb/406276677_822628849665880_7979275698891868243_n.webp',
        'photos/1123/thumb/406289692_781510613738721_5863206877331323865_n.webp',
        'photos/1123/thumb/406380444_365190775963533_2782076815281069584_n.webp',
        'photos/1123/thumb/406450988_1056494935701043_8495836542919755716_n.webp',
        'photos/1123/thumb/406466063_368577459078101_6753047211081058332_n.webp',
        'photos/1123/thumb/406553670_865739541626961_4027644302914915253_n.webp',
        'photos/1123/thumb/406654757_1296170351090698_4892965576922417846_n.webp',
        'photos/1123/thumb/406687013_895452262155158_7061737425342631761_n.webp'
      ],
      imagesLenght: 37
    }, {
      id: 9,
      folderName: 'Sto lat misiom i pluszakom',
      images: [
        'photos/1123/thumb/363886379_884973953232150_4403673404150960805_n.webp',
        'photos/1123/thumb/370325852_824090126136344_9129545640021406884_n.webp',
        'photos/1123/thumb/400762988_874303910914955_6316376118359282305_n.webp',
        'photos/1123/thumb/400766174_227329330382551_5933215654561525198_n.webp',
        'photos/1123/thumb/401223165_861421215628039_6428310816048689345_n.webp',
        'photos/1123/thumb/403394922_7853372604690284_2269988067548782857_n.webp',
        'photos/1123/thumb/403403987_1343572640374892_743260643886677302_n.webp',
        'photos/1123/thumb/405584409_1133003494775140_5243209275995723638_n.webp',
        'photos/1123/thumb/405932996_371474655463209_1590944919322384504_n.webp',
        'photos/1123/thumb/405953326_877788040800478_329342617829328722_n.webp',
        'photos/1123/thumb/406147151_863688042125565_1822145925961415083_n.webp',
        'photos/1123/thumb/406247572_874564067547388_4308459207877581621_n.webp',
        'photos/1123/thumb/406337959_730301692289547_4182122477976536036_n.webp'
      ],
      imagesLenght: 12
    }
  ]
  const images1023 = [
    {
      id: 0,
      folderName: 'Uczymy się zdrowo odżywiać',
      images: [
        'photos/1023/thumb/383146304_803191244886987_4699070987033748783_n.webp',
        'photos/1023/thumb/384541913_698138742219376_4357146451997177994_n.webp',
        'photos/1023/thumb/384546109_299967229461271_3757784644243877032_n.webp',
        'photos/1023/thumb/384546149_710752737566770_4159583468846357540_n.webp',
        'photos/1023/thumb/384546986_1807830332984525_862258776373771944_n.webp',
        'photos/1023/thumb/384546997_1079607406288775_4919537531195573847_n.webp',
        'photos/1023/thumb/384546997_835847574716948_898680783080029476_n.webp',
        'photos/1023/thumb/384547468_211068735170628_2135778714731437256_n.webp',
        'photos/1023/thumb/384547565_305450438859697_3423023695436439036_n.webp',
        'photos/1023/thumb/384547582_350055530739162_8306818196484699032_n.webp',
        'photos/1023/thumb/384548615_683650310490245_2681039519494117973_n.webp',
        'photos/1023/thumb/384549187_693068799057158_5397391142757253170_n.webp',
        'photos/1023/thumb/384550348_970841507539890_7979439931821686915_n.webp',
        'photos/1023/thumb/384551374_130845236783928_8830438580611179102_n.webp',
        'photos/1023/thumb/384552500_188511270943869_2420463274522197697_n.webp',
        'photos/1023/thumb/384846254_2196349203895344_4647200930444950316_n.webp',
        'photos/1023/thumb/385546867_162234120274065_2258264743178381761_n.webp',
        'photos/1023/thumb/386431331_623896986323764_4907885330840580906_n.webp',
        'photos/1023/thumb/386468725_670323681723137_4935338103219502801_n.webp',
        'photos/1023/thumb/386473111_1364079031210554_4961556454449343166_n.webp',
        'photos/1023/thumb/386473834_239616208727926_2605123465776724707_n.webp',
        'photos/1023/thumb/386821702_1046757576467187_2278712367557464168_n.webp',
        'photos/1023/thumb/386837016_1528953134523256_7296283504862327938_n.webp',
        'photos/1023/thumb/386858688_2005558213170671_2138735521053198040_n.webp',
        'photos/1023/thumb/386863920_1473086463476320_489249684405923336_n.webp',
        'photos/1023/thumb/386868997_322227960493497_2696317463481304639_n.webp',
        'photos/1023/thumb/386870924_341743864961942_6243958655733301031_n.webp',
        'photos/1023/thumb/386881536_1757721288074861_701760445295200555_n.webp',
        'photos/1023/thumb/386881721_683567257060019_8937559203007495124_n.webp',
        'photos/1023/thumb/386883470_626860569608923_8192673913197755523_n.webp',
        'photos/1023/thumb/386886174_1467077964146156_4916784018387796439_n.webp',
        'photos/1023/thumb/386886234_1363133844638278_1716370907373646781_n.webp',
        'photos/1023/thumb/386890212_2299037933613823_8218562726936618115_n.webp',
        'photos/1023/thumb/384377059_231304456598715_4215681832298939565_n.webp',
        'photos/1023/thumb/384828812_2041733002847557_4526170649645638298_n.webp',
        'photos/1023/thumb/384829348_6409617029166157_8935093779894666673_n.webp',
        'photos/1023/thumb/384838378_1271048163591045_67383825074008936_n.webp',
        'photos/1023/thumb/384841382_278026438030780_4974767294389911091_n.webp',
        'photos/1023/thumb/384856687_337459245523764_153143543044844736_n.webp',
        'photos/1023/thumb/385362869_542812778003927_9065996438464125344_n.webp',
        'photos/1023/thumb/386468748_1034560011223854_6699026626621190830_n.webp',
        'photos/1023/thumb/386891240_839140714346319_3301053899200519736_n.webp',
        'photos/1023/thumb/387330141_869849974489468_7744459089971429323_n.webp',
        'photos/1023/thumb/387331205_668157985410937_49888011114978078_n.webp',
        'photos/1023/thumb/387334702_1820961818318783_1356442519061776412_n.webp'
      ],
      imagesLenght: 44
    }, {
      id: 1,
      folderName: 'Trochę trudne dla nas ale fajne zajęcia',
      images: [
        'photos/1023/thumb/384407424_324816366865757_6115140833679866074_n.webp',
        'photos/1023/thumb/384541067_156736754175417_4572791196141378412_n.webp',
        'photos/1023/thumb/384546110_847819393384940_6800105814129760336_n.webp',
        'photos/1023/thumb/384546125_136053892898832_2116869971987934523_n.webp',
        'photos/1023/thumb/384547005_287033097568800_6595478495356432342_n.webp',
        'photos/1023/thumb/384547017_350203570762523_2349212082960204926_n.webp',
        'photos/1023/thumb/384547468_3600956926818159_5572885436806678477_n.webp',
        'photos/1023/thumb/384547527_626075689684653_4975449675269289351_n.webp',
        'photos/1023/thumb/384548611_281017921490423_299951620465296034_n.webp',
        'photos/1023/thumb/386429731_849808579987625_2908995766476133309_n.webp',
        'photos/1023/thumb/386430764_2302811536575181_5870035503046695399_n.webp',
        'photos/1023/thumb/386431557_332822359229694_8293674327745017896_n.webp',
        'photos/1023/thumb/386472102_1553927505434279_347853277084466685_n.webp',
        'photos/1023/thumb/386472559_985745052724588_6609714736679296907_n.webp',
        'photos/1023/thumb/386826545_2449997558500776_8829738854059662412_n.webp',
        'photos/1023/thumb/386856545_1285307425508003_1047558249272067289_n.webp',
        'photos/1023/thumb/386863965_2344841045718847_119493982056795761_n.webp',
        'photos/1023/thumb/386877185_268391972830369_3890261041869011285_n.webp',
        'photos/1023/thumb/386879581_1787395185039421_6181007060039896416_n.webp',
        'photos/1023/thumb/386885065_275401962087270_7874732682219265486_n.webp',
        'photos/1023/thumb/386886404_1451135018791540_2248649619241955977_n.webp',
        'photos/1023/thumb/386886595_227463956996637_4216528598094961397_n.webp',
        'photos/1023/thumb/386894033_654426256481548_5688689456088416499_n.webp'
      ],
      imagesLenght: 22
    }, {
      id: 2,
      folderName: 'Letnie igraszki na placu',
      images: [
        'photos/1023/thumb/384546121_3090338641108466_4724612970876334820_n.webp',
        'photos/1023/thumb/384547032_653898773197522_5745594739366950982_n.webp',
        'photos/1023/thumb/384547502_1222440789147955_2394319020101292154_n.webp',
        'photos/1023/thumb/384547513_1514961069244070_7540236049610257551_n.webp',
        'photos/1023/thumb/384547526_135944919576982_8959362394429489679_n.webp',
        'photos/1023/thumb/384547585_831243782069474_3261120772113782505_n.webp',
        'photos/1023/thumb/384552520_701834178067216_4671704400844157346_n.webp',
        'photos/1023/thumb/386452239_249344844774833_6957542924777481564_n.webp',
        'photos/1023/thumb/386809498_863092651960895_2586610496411156963_n.webp',
        'photos/1023/thumb/386833966_7127581960599653_318139848765815929_n.webp',
        'photos/1023/thumb/386875332_237368202649102_3774903523503601077_n.webp'
      ],
      imagesLenght: 10
    }, {
      id: 3,
      folderName: 'Pożegnaliśmy jesień piosenką',
      videos: [
        'videos/1023/10000000_6631899246932903_3429688778303448399_n.mp4',
        'videos/1023/10000000_6918494431547143_1404916340815774721_n.mp4'
      ],
      videosLenght: 1
    }, {
      id: 4,
      folderName: 'Uczymy dzieci pomagać innym. Dziękujęmy Rodzicom Laury i Mateuszka :)',
      images: [
        'photos/1023/thumb/393871056_1044559053526402_6862135640787858261_n.webp',
        'photos/1023/thumb/393939191_999527591351402_5089509005287947904_n.webp',
        'photos/1023/thumb/393946648_1055455609138389_8344635431705585805_n.webp',
        'photos/1023/thumb/393953726_850163126486803_8713013656607554211_n.webp',
        'photos/1023/thumb/393953729_347712104296711_9068642799315657110_n.webp'
      ],
      imagesLenght: 4
    }, {
      id: 5,
      folderName: 'Rodzinne klimaty u naszych Słoneczek',
      videos: [
        'videos/1023/10000000_6553502024777110_1609801307216326199_n.mp4'
      ],
      videosLenght: 0
    }
  ]
  const images0923 = [
    {
      id: 0,
      folderName: 'Jeszcze lato',
      images: [
        'photos/0923/thumb/371763741_1388857928392512_6309662390649073999_n.webp',
        'photos/0923/thumb/372217237_2423416527829111_4621280397402031231_n.webp',
        'photos/0923/thumb/372790006_2058589257811616_1435159328011848713_n.webp',
        'photos/0923/thumb/373269507_328878526220719_8526712478890434490_n.webp',
        'photos/0923/thumb/373461928_3247982718827983_4401312173920117326_n.webp',
        'photos/0923/thumb/373463932_1043625407020588_2790946229137028291_n.webp',
        'photos/0923/thumb/373464040_1209302333794486_883144289780629610_n.webp',
        'photos/0923/thumb/373465889_265958379236712_3093593775326525414_n.webp',
        'photos/0923/thumb/375014339_167484886382852_2156510808962270271_n.webp',
        'photos/0923/thumb/375023524_697689625022991_4259436083705389307_n.webp',
        'photos/0923/thumb/375196585_684994603208220_4960836187204693194_n.webp',
        'photos/0923/thumb/375196912_855039236144139_8180264866528451290_n.webp',
        'photos/0923/thumb/375692612_1017052472651680_9108808782259690822_n.webp',
        'photos/0923/thumb/375694893_634649448648454_3475838530216504738_n.webp',
        'photos/0923/thumb/376376634_1055856012261202_8404463536440961185_n.webp',
        'photos/0923/thumb/376517782_872301177738656_2965831110938651505_n.webp',
        'photos/0923/thumb/376597563_6471428426298031_5423703384778570029_n.webp',
        'photos/0923/thumb/376597563_880862666781564_3590152136602482801_n.webp'
      ],
      imagesLenght: 17
    }, {
      id: 1,
      folderName: 'Urodziny naszego Adrianka - nagrania',
      videos: [
        'videos/0923/376782967_6488328004547959_7989426793083088716_n.mp4'
      ],
      videosLenght: 0
    }, {
      id: 2,
      folderName: 'Urodziny naszego Adrianka',
      images: [
        'photos/0923/thumb/371763741_1305173876789194_5151424132483453646_n.webp',
        'photos/0923/thumb/372151765_2581784591984469_7406039877474492105_n.webp',
        'photos/0923/thumb/372154011_1272216733431246_3567921939551503074_n.webp',
        'photos/0923/thumb/372238153_326286299757369_7958807902101949470_n.webp',
        'photos/0923/thumb/372271572_1514173395988644_5671612418480748881_n.webp',
        'photos/0923/thumb/372371501_971929893886391_9027776270696846798_n.webp',
        'photos/0923/thumb/372391683_150004864828892_7078683004857845729_n.webp',
        'photos/0923/thumb/373458356_2038948829791883_947253159679878463_n.webp',
        'photos/0923/thumb/373464871_274082198903918_6414786142983174793_n.webp',
        'photos/0923/thumb/373468029_820053443099774_3368456972474081593_n.webp',
        'photos/0923/thumb/373482252_977873649994455_5728073879256452916_n.webp',
        'photos/0923/thumb/373488397_213798138146058_9216949144003766499_n.webp',
        'photos/0923/thumb/373491694_2364933850361294_5757421729397205160_n.webp',
        'photos/0923/thumb/374959253_315823017629681_3724066304478610771_n.webp',
        'photos/0923/thumb/375013742_818904866384574_1305972516248853262_n.webp',
        'photos/0923/thumb/375021935_708746471294683_108379150339021552_n.webp',
        'photos/0923/thumb/375197840_1013294233038890_2472580204472665013_n.webp',
        'photos/0923/thumb/375199785_182192451491433_6781439996596801030_n.webp',
        'photos/0923/thumb/375487400_716524656972450_6101718757124580474_n.webp',
        'photos/0923/thumb/375692612_332421069184392_9171263802627793424_n.webp',
        'photos/0923/thumb/375692708_316862260921432_5997414022165012147_n.webp',
        'photos/0923/thumb/375757547_1715882825506784_2920207141920071923_n.webp',
        'photos/0923/thumb/375765052_282809974533842_4448186620558076010_n.webp',
        'photos/0923/thumb/375766010_3653883308175865_967657919531587489_n.webp',
        'photos/0923/thumb/376357299_631101589005258_2144918433683755532_n.webp',
        'photos/0923/thumb/376371042_289604680458605_3160524977334893816_n.webp',
        'photos/0923/thumb/376389017_844371276943639_7351369557019637739_n.webp',
        'photos/0923/thumb/376389362_1965350707157600_1542889739503629673_n.webp',
        'photos/0923/thumb/376431208_1042071343818817_2779440420210116423_n.webp',
        'photos/0923/thumb/376575246_267483536097269_3395367160002540713_n.webp',
        'photos/0923/thumb/377148799_238890695810620_918417105408511857_n.webp'
      ],
      imagesLenght: 30
    }, {
      id: 3,
      folderName: 'Dzień Przedszkolaka - nagrania',
      videos: [
        'videos/0923/378060032_7278905515470911_5625449991610902374_n.mp4'
      ],
      videosLenght: 0
    }, {
      id: 4,
      folderName: 'Dzień Przedszkolaka',
      images: [
        'photos/0923/thumb/379535754_2049389292061361_6089016591602169098_n.webp',
        'photos/0923/thumb/379624183_354173443607006_97680367368300139_n.webp',
        'photos/0923/thumb/380695380_231535436215375_3554160980378212095_n.webp',
        'photos/0923/thumb/380710803_834299491583676_2375117244204362838_n.webp',
        'photos/0923/thumb/380826734_1752040298588761_1623754321456470183_n.webp',
        'photos/0923/thumb/380826980_313858804669244_2657012093699658138_n.webp',
        'photos/0923/thumb/380840753_1084496306246626_5455480743509826595_n.webp',
        'photos/0923/thumb/380840754_3649253025352380_6454635286902660945_n.webp',
        'photos/0923/thumb/380841675_1980175729003986_5916078788331776889_n.webp',
        'photos/0923/thumb/380841680_2043296389358912_2069681033564710080_n.webp',
        'photos/0923/thumb/380844470_847285623370319_2435384381800988111_n.webp',
        'photos/0923/thumb/380847757_1442839929894058_9017371550798141467_n.webp',
        'photos/0923/thumb/380857773_861076938924724_4872843671051748769_n.webp',
        'photos/0923/thumb/380859004_1504328610308403_931673287264035018_n.webp',
        'photos/0923/thumb/380861138_286482964166878_6830407354286887094_n.webp',
        'photos/0923/thumb/380864689_627627592582193_6923436886558135328_n.webp',
        'photos/0923/thumb/380864689_952080845884586_6055896031949254349_n.webp',
        'photos/0923/thumb/380869458_243648461541224_613354830710642314_n.webp',
        'photos/0923/thumb/380871802_2356981441169711_1614784093893448658_n.webp',
        'photos/0923/thumb/380875412_327919843093784_3360923628330716569_n.webp',
        'photos/0923/thumb/380879402_843547130472970_179977822466052438_n.webp',
        'photos/0923/thumb/380883215_992835555319477_5317227509176924333_n.webp',
        'photos/0923/thumb/380883233_268557586080194_8163440497335893006_n.webp',
        'photos/0923/thumb/380893498_1684180802070637_7051294874233577135_n.webp',
        'photos/0923/thumb/380905088_334032839071078_2502126738459981029_n.webp',
        'photos/0923/thumb/380907873_1314041899225097_1569422351046813971_n.webp',
        'photos/0923/thumb/380909859_320235207224118_782250776234043149_n.webp',
        'photos/0923/thumb/380916574_203823502582546_1352415105145504564_n.webp',
        'photos/0923/thumb/381993191_1053565392313113_7828451560332736384_n.webp'
      ],
      imagesLenght: 28
    }, {
      id: 5,
      folderName: 'Lubimy to',
      images: [
        'photos/0923/thumb/380831822_1137299810589177_7249095527158526466_n.webp',
        'photos/0923/thumb/380837697_1003591450969029_2478995673542839108_n.webp',
        'photos/0923/thumb/380851059_156806807480619_1671479264220471530_n.webp',
        'photos/0923/thumb/380851065_967015051030944_4441623504189841538_n.webp',
        'photos/0923/thumb/380854500_306132132021067_6616863916553418939_n.webp',
        'photos/0923/thumb/380864673_696377072336717_5300799728379122258_n.webp',
        'photos/0923/thumb/380869458_220540554072187_6092033624279234100_n.webp',
        'photos/0923/thumb/380903983_270927625766308_2118530353486550640_n.webp',
        'photos/0923/thumb/380916577_216594241417905_6498974581526383432_n.webp',
      ],
      imagesLenght: 8
    }, {
      id: 6,
      folderName: 'Witamy jesień',
      images: [
        'photos/0923/thumb/381768535_337661015458477_332063727452099608_n.webp',
        'photos/0923/thumb/381773146_195026990275248_5657796578328782110_n.webp',
        'photos/0923/thumb/381773150_323731700325765_8919733116475749038_n.webp',
        'photos/0923/thumb/381773155_188127724304839_3814045127445726856_n.webp',
        'photos/0923/thumb/381778286_1705059693345969_1375565427351049818_n.webp',
        'photos/0923/thumb/381781692_1038264327595498_3662280012231528996_n.webp',
        'photos/0923/thumb/381787112_1053556602500508_850004435174499007_n.webp',
        'photos/0923/thumb/381794970_1002464624337772_8648081388194999722_n.webp',
        'photos/0923/thumb/381794984_825635319351443_7587590649447662062_n.webp',
        'photos/0923/thumb/381799828_1666767047137049_8384220988538175976_n.webp',
        'photos/0923/thumb/381800162_687163939964207_4662148304918324136_n.webp',
        'photos/0923/thumb/381808044_1399642113921644_3860893085286914041_n.webp',
        'photos/0923/thumb/381813480_702236021240614_5073694869864552706_n.webp',
        'photos/0923/thumb/381813693_971490583918489_3704435917121638506_n.webp',
        'photos/0923/thumb/381821872_1494141511419137_2791315595989003828_n.webp',
        'photos/0923/thumb/381908260_336128418811048_8288072465035447084_n.webp',
        'photos/0923/thumb/381975009_877552997314617_389560544494567121_n.webp',
        'photos/0923/thumb/382019663_690348702646183_6718450939227511986_n.webp',
        'photos/0923/thumb/382047894_1439798173537696_1910175412505037236_n.webp',
        'photos/0923/thumb/382063959_147321041788774_2632385141108909398_n.webp',
        'photos/0923/thumb/382079212_337199368670865_8868763026731649011_n.webp',
        'photos/0923/thumb/382114001_298622899555258_6679207749951229548_n.webp',
        'photos/0923/thumb/382127881_1353454001918782_7398641064501684148_n.webp',
        'photos/0923/thumb/382189900_279232894966904_4159361130795774100_n.webp',
        'photos/0923/thumb/382244429_640733368170423_3345367978700107511_n.webp',
        'photos/0923/thumb/382259762_851252079801757_6137839561247798349_n.webp',
        'photos/0923/thumb/384309559_690547709303836_5820933358309202809_n.webp',
        'photos/0923/thumb/384351461_6676935079026673_620330211291671125_n.webp',
        'photos/0923/thumb/384469824_820107213180364_211479142003605834_n.webp',
        'photos/0923/thumb/384471971_322624493682412_7227545123949208459_n.webp',
        'photos/0923/thumb/384471971_609709834703162_1067262860134111074_n.webp',
        'photos/0923/thumb/384471974_1005602033823639_7802103413036460095_n.webp',
        'photos/0923/thumb/384471974_707213921264891_4228290334535388548_n.webp',
        'photos/0923/thumb/384473763_750860030137515_201615003258489700_n.webp',
        'photos/0923/thumb/384479404_301929605800944_2720967514873475345_n.webp',
        'photos/0923/thumb/384480209_1706487116518537_1180201216426394426_n.webp',
        'photos/0923/thumb/384481086_1236572871069242_857983422732513778_n.webp',
        'photos/0923/thumb/384481086_264492013221077_7217077417608719623_n.webp',
        'photos/0923/thumb/384501178_160649163763607_9137090896113394213_n.webp',
        'photos/0923/thumb/384525041_3497421780524348_4592717657232247315_n.webp'
      ],
      imagesLenght: 39
    }, {
      id: 7,
      folderName: 'Spotkanie ze sztuką',
      images: [
        'photos/0923/thumb/382620112_289140170538155_498943696142998631_n.webp',
        'photos/0923/thumb/382622816_1034141564446704_1538444997031995356_n.webp',
        'photos/0923/thumb/382626104_1473121336845320_7701710257115204719_n.webp',
        'photos/0923/thumb/382627279_276906988562172_1286435631926531296_n.webp',
        'photos/0923/thumb/382627286_1415376512359527_5652039623380250303_n.webp',
        'photos/0923/thumb/382627294_1365211017705115_725901424275050049_n.webp',
        'photos/0923/thumb/382630521_6741000069317708_5544017611251365269_n.webp',
        'photos/0923/thumb/382637530_853442312730437_6013170074503795124_n.webp',
        'photos/0923/thumb/382645002_1274886399886877_7907795441783733132_n.webp',
        'photos/0923/thumb/382670036_331727075996016_6143782353695502321_n.webp',
        'photos/0923/thumb/382670047_303094209004339_1900092652852551054_n.webp',
        'photos/0923/thumb/382677529_1705451816640369_339843366860676783_n.webp',
        'photos/0923/thumb/382701265_1391912538028556_2947845294051079317_n.webp',
        'photos/0923/thumb/382719010_1349283805994250_4422776474741008472_n.webp',
        'photos/0923/thumb/384474532_1314064796147371_3537285524635923833_n.webp',
        'photos/0923/thumb/385397315_1143034149990760_7101312513933092330_n.webp',
        'photos/0923/thumb/385526632_753389700135523_9110237509864811380_n.webp'
      ],
      imagesLenght: 16
    }
  ]
  const images0623 = [
    {
      id: 0,
      folderName: 'Kończymy rok szkolny 2022/2023',
      images: [
        'photos/0623/thumb/ADHS2773.webp',
        'photos/0623/thumb/AWXK4606.webp',
        'photos/0623/thumb/BKFV1481.webp',
        'photos/0623/thumb/BQUA0404.webp',
        'photos/0623/thumb/CDFC4362.webp',
        'photos/0623/thumb/CJZW5157.webp',
        'photos/0623/thumb/EKOA4372.webp',
        'photos/0623/thumb/EZNK5271.webp',
        'photos/0623/thumb/GBJU2049.webp',
        'photos/0623/thumb/GGJA4882.webp',
        'photos/0623/thumb/IBVO9345.webp',
        'photos/0623/thumb/IEWL2131.webp',
        'photos/0623/thumb/IJZU3448.webp',
        'photos/0623/thumb/ILJP0735.webp',
        'photos/0623/thumb/JUYK9946.webp',
        'photos/0623/thumb/KVJX2949.webp',
        'photos/0623/thumb/LRSN9480.webp',
        'photos/0623/thumb/MHAQ9963.webp',
        'photos/0623/thumb/MHUA3337.webp',
        'photos/0623/thumb/MJHU3437.webp',
        'photos/0623/thumb/MNKI2929.webp',
        'photos/0623/thumb/OCXK9167.webp',
        'photos/0623/thumb/PLKJ7295.webp',
        'photos/0623/thumb/QCCJ2976.webp',
        'photos/0623/thumb/QOJP1312.webp',
        'photos/0623/thumb/QZUQ5800.webp',
        'photos/0623/thumb/RDNT7217.webp',
        'photos/0623/thumb/RNRQ0398.webp',
        'photos/0623/thumb/RPZU4269.webp',
        'photos/0623/thumb/SEWV6272.webp',
        'photos/0623/thumb/TDNH5288.webp',
        'photos/0623/thumb/UOZY4741.webp',
        'photos/0623/thumb/UYYV9808.webp',
        'photos/0623/thumb/VNFC4526.webp',
        'photos/0623/thumb/VNFL1116.webp',
        'photos/0623/thumb/WCNF5825.webp',
        'photos/0623/thumb/WEWG3888.webp',
        'photos/0623/thumb/XMVH6948.webp',
        'photos/0623/thumb/XUPQ4005.webp'
      ],
      imagesLenght: 38
    }, {
      id: 1,
      folderName: 'Kończymy rok szkolny 2022/2023 - nagrania',
      videos: [
        'videos/0623/BOHJ5830.MOV',
        'videos/0623/NZHH1469.MOV',
        'videos/0623/VDDR5331.MOV',
        'videos/0623/TBBL7370.MOV'
      ],
      videosLenght: 3
    }, {
      id: 2,
      folderName: 'Urodziny naszej Idusi',
      images: [
        'photos/0623/thumb/AJLR7118.webp',
        'photos/0623/thumb/ASHR6750.webp',
        'photos/0623/thumb/DBFW7869.webp',
        'photos/0623/thumb/DJFQ4907.webp',
        'photos/0623/thumb/DOQV1274.webp',
        'photos/0623/thumb/DPCQ2402.webp',
        'photos/0623/thumb/DYZB4948.webp',
        'photos/0623/thumb/DZIP6064.webp',
        'photos/0623/thumb/FVLX9373.webp',
        'photos/0623/thumb/FZXD4477.webp',
        'photos/0623/thumb/GSUM9703.webp',
        'photos/0623/thumb/GYQM6467.webp',
        'photos/0623/thumb/HXCU1048.webp',
        'photos/0623/thumb/HXPE7628.webp',
        'photos/0623/thumb/KBAF1293.webp',
        'photos/0623/thumb/KKWT4047.webp',
        'photos/0623/thumb/NDBS6138.webp',
        'photos/0623/thumb/OCPS0107.webp',
        'photos/0623/thumb/OJRK3759.webp',
        'photos/0623/thumb/PDLD0712.webp',
        'photos/0623/thumb/PFYH4285.webp',
        'photos/0623/thumb/PNEX3870.webp',
        'photos/0623/thumb/PXIW6181.webp',
        'photos/0623/thumb/QOVW7793.webp',
        'photos/0623/thumb/SEWR0532.webp',
        'photos/0623/thumb/TSNT2965.webp',
        'photos/0623/thumb/VEQX0107.webp',
        'photos/0623/thumb/VZZA4117.webp',
        'photos/0623/thumb/XTOP4867.webp',
        'photos/0623/thumb/YGJC5243.webp',
        'photos/0623/thumb/YGZU5402.webp',
        'photos/0623/thumb/YIGA4854.webp'
      ],
      imagesLenght: 31
    }, {
      id: 3,
      folderName: 'Urodziny naszej Idusi - nagrania',
      videos: [
        'videos/0623/NKAI9431.MP4'
      ],
      videosLenght: 0
    }, {
      id: 4,
      folderName: 'Dzień Dziecka',
      images: [
        'photos/0623/thumb/BHJE5241.webp',
        'photos/0623/thumb/BHXF4333.webp',
        'photos/0623/thumb/BIZH5831.webp',
        'photos/0623/thumb/BJVW6118.webp',
        'photos/0623/thumb/BUDZ9176.webp',
        'photos/0623/thumb/DFOW6231.webp',
        'photos/0623/thumb/EEPY7980.webp',
        'photos/0623/thumb/FLVK5113.webp',
        'photos/0623/thumb/FUDB2662.webp',
        'photos/0623/thumb/HPWU5746.webp',
        'photos/0623/thumb/HWPH1388.webp',
        'photos/0623/thumb/IONE3960.webp',
        'photos/0623/thumb/JIXA5011.webp',
        'photos/0623/thumb/JJSV1598.webp',
        'photos/0623/thumb/JSCB6516.webp',
        'photos/0623/thumb/KLIZ9277.webp',
        'photos/0623/thumb/LSLP6937.webp',
        'photos/0623/thumb/LSNF8443.webp',
        'photos/0623/thumb/OCZO3788.webp',
        'photos/0623/thumb/OYJC9777.webp',
        'photos/0623/thumb/QCPQ6231.webp',
        'photos/0623/thumb/QUGA1933.webp',
        'photos/0623/thumb/RKQW4411.webp',
        'photos/0623/thumb/TBKA0118.webp',
        'photos/0623/thumb/UNQG6563.webp',
        'photos/0623/thumb/VNKM6044.webp',
        'photos/0623/thumb/YBWW7145.webp',
        'photos/0623/thumb/YFNI7652.webp'
      ],
      imagesLenght: 27
    }, {
      id: 5,
      folderName: 'Dzień Dziecka - nagrania',
      videos: [
        'videos/0623/MNYA3949.MP4'
      ],
      videosLenght: 0
    }, {
      id: 6,
      folderName: 'Spotkanie z teatrem',
      images: [
        'photos/0623/thumb/IMG_4006.webp',
        'photos/0623/thumb/IMG_4007.webp',
        'photos/0623/thumb/IMG_4009.webp',
        'photos/0623/thumb/IMG_4010.webp',
        'photos/0623/thumb/IMG_4011.webp',
        'photos/0623/thumb/IMG_4013.webp',
        'photos/0623/thumb/IMG_4014.webp',
        'photos/0623/thumb/IMG_4015.webp',
        'photos/0623/thumb/IMG_4016.webp',
        'photos/0623/thumb/IMG_4017.webp',
        'photos/0623/thumb/IMG_4018.webp',
        'photos/0623/thumb/IMG_4019.webp',
        'photos/0623/thumb/IMG_4020.webp',
        'photos/0623/thumb/IMG_4022.webp',
        'photos/0623/thumb/IMG_4023.webp',
        'photos/0623/thumb/IMG_4024.webp',
        'photos/0623/thumb/IMG_4026.webp',
        'photos/0623/thumb/IMG_4027.webp',
        'photos/0623/thumb/IMG_4028.webp'
      ],
      imagesLenght: 18
    }, {
      id: 7,
      folderName: 'Urodziny naszego Antka',
      images: [
        'photos/0623/thumb/AINY6995.webp',
        'photos/0623/thumb/CCCR1300.webp',
        'photos/0623/thumb/CCUZ3201.webp',
        'photos/0623/thumb/CFVX3405.webp',
        'photos/0623/thumb/CPIF2504.webp',
        'photos/0623/thumb/CUGL9102.webp',
        'photos/0623/thumb/EGCH0261.webp',
        'photos/0623/thumb/EJFY0878.webp',
        'photos/0623/thumb/EYZZ2199.webp',
        'photos/0623/thumb/FFNP6851.webp',
        'photos/0623/thumb/FSRM6838.webp',
        'photos/0623/thumb/GLGR0322.webp',
        'photos/0623/thumb/GSZY3331.webp',
        'photos/0623/thumb/GWJJ8242.webp',
        'photos/0623/thumb/GWRU3148.webp',
        'photos/0623/thumb/GZHA2228.webp',
        'photos/0623/thumb/HDBK9288.webp',
        'photos/0623/thumb/HYUZ5255.webp',
        'photos/0623/thumb/IHSI2375.webp',
        'photos/0623/thumb/IRVN6111.webp',
        'photos/0623/thumb/KXLO3891.webp',
        'photos/0623/thumb/LKMZ3490.webp',
        'photos/0623/thumb/MDXV9647.webp',
        'photos/0623/thumb/MGOM0845.webp',
        'photos/0623/thumb/NNIR1635.webp',
        'photos/0623/thumb/OEYG5929.webp',
        'photos/0623/thumb/ONVO4839.webp',
        'photos/0623/thumb/PAMV3413.webp',
        'photos/0623/thumb/PRUB5287.webp',
        'photos/0623/thumb/SJWN8907.webp',
        'photos/0623/thumb/TQCF0708.webp',
        'photos/0623/thumb/TRTX4528.webp',
        'photos/0623/thumb/UESH2029.webp',
        'photos/0623/thumb/USIH2711.webp',
        'photos/0623/thumb/UXZJ3353.webp',
        'photos/0623/thumb/VGBA2399.webp',
        'photos/0623/thumb/VWJL9872.webp',
        'photos/0623/thumb/XXNN4453.webp',
        'photos/0623/thumb/YDHJ3648.webp',
        'photos/0623/thumb/YHAQ4269.webp'
      ],
      imagesLenght: 39
    }
  ]
  const images0523 = [
    {
      id: 0,
      folderName: 'Dzień Mamy i Taty',
      images: [
        'photos/0523/thumb/ADWF0617.webp',
        'photos/0523/thumb/IEUM2787.webp',
        'photos/0523/thumb/IUHD5812.webp',
        'photos/0523/thumb/IWOL6159.webp',
        'photos/0523/thumb/JBXF0285.webp',
        'photos/0523/thumb/JRUA1892.webp',
        'photos/0523/thumb/JUNW2425.webp',
        'photos/0523/thumb/KJUO5733.webp',
        'photos/0523/thumb/LZUJ2869.webp',
        'photos/0523/thumb/MPZA0389.webp',
        'photos/0523/thumb/MWZB5708.webp',
        'photos/0523/thumb/MYQN9928.webp',
        'photos/0523/thumb/NWHQ2658.webp',
        'photos/0523/thumb/NWMT0983.webp',
        'photos/0523/thumb/OEUG7889.webp',
        'photos/0523/thumb/QCBW9200.webp',
        'photos/0523/thumb/QPQU6717.webp',
        'photos/0523/thumb/TIHX9897.webp',
        'photos/0523/thumb/VAFB8650.webp',
        'photos/0523/thumb/WAJH6471.webp',
        'photos/0523/thumb/XWUM4253.webp'
      ],
      imagesLenght: 20
    }, {
      id: 1,
      folderName: 'Dzień Mamy i Taty - nagrania',
      videos: [
        'videos/0523/GETT0906.MOV',
        'videos/0523/PYGY9846.MOV'
      ],
      videosLenght: 1
    }, {
      id: 2,
      folderName: 'Urodziny Szymonka',
      images: [
        'photos/0523/thumb/BBPD3704.webp',
        'photos/0523/thumb/BSUF8233.webp',
        'photos/0523/thumb/ELFO7342.webp',
        'photos/0523/thumb/EMAE2625.webp',
        'photos/0523/thumb/ERQB3768.webp',
        'photos/0523/thumb/EVNB8333.webp',
        'photos/0523/thumb/FAPV0737.webp',
        'photos/0523/thumb/FOGQ2583.webp',
        'photos/0523/thumb/FOXM1366.webp',
        'photos/0523/thumb/FUCR6594.webp',
        'photos/0523/thumb/KGHA4409.webp',
        'photos/0523/thumb/KMZM7720.webp',
        'photos/0523/thumb/KRNY6354.webp',
        'photos/0523/thumb/LOVO7513.webp',
        'photos/0523/thumb/MRVZ5179.webp',
        'photos/0523/thumb/OEAE1649.webp',
        'photos/0523/thumb/OELR0622.webp',
        'photos/0523/thumb/OQDM6389.webp',
        'photos/0523/thumb/PKYT7689.webp',
        'photos/0523/thumb/RCHC4472.webp',
        'photos/0523/thumb/SNOQ1710.webp',
        'photos/0523/thumb/SWCG6180.webp',
        'photos/0523/thumb/UYXX7327.webp',
        'photos/0523/thumb/VGCD9476.webp',
        'photos/0523/thumb/VOTA1853.webp',
        'photos/0523/thumb/XAWC0920.webp',
        'photos/0523/thumb/YBYI9380.webp'
      ],
      imagesLenght: 26
    }, {
      id: 3,
      folderName: 'Urodziny Mateuszka',
      images: [
        'photos/0523/thumb/ATRV8230.webp',
        'photos/0523/thumb/COUT5070.webp',
        'photos/0523/thumb/DPLO3449.webp',
        'photos/0523/thumb/DSTA2860.webp',
        'photos/0523/thumb/DYQZ8146.webp',
        'photos/0523/thumb/EVKM3847.webp',
        'photos/0523/thumb/GJNO2615.webp',
        'photos/0523/thumb/GPGH6033.webp',
        'photos/0523/thumb/HQUL9694.webp',
        'photos/0523/thumb/IJAK1025.webp',
        'photos/0523/thumb/IMG_3578.webp',
        'photos/0523/thumb/IMG_3579.webp',
        'photos/0523/thumb/IMG_3580.webp',
        'photos/0523/thumb/IMG_3581.webp',
        'photos/0523/thumb/IYLZ8128.webp',
        'photos/0523/thumb/JKAH7122.webp',
        'photos/0523/thumb/JZTA4398.webp',
        'photos/0523/thumb/KADQ1719.webp',
        'photos/0523/thumb/KFDU1442.webp',
        'photos/0523/thumb/KTVC4982.webp',
        'photos/0523/thumb/LOSF9032.webp',
        'photos/0523/thumb/LQFO2464.webp',
        'photos/0523/thumb/MOAT2301.webp',
        'photos/0523/thumb/MWSL0559.webp',
        'photos/0523/thumb/NHUP1568.webp',
        'photos/0523/thumb/NKST1724.webp',
        'photos/0523/thumb/NLMN3288.webp',
        'photos/0523/thumb/NYEK6050.webp',
        'photos/0523/thumb/NYZD0089.webp',
        'photos/0523/thumb/OLAA4650.webp',
        'photos/0523/thumb/OWKW4821.webp',
        'photos/0523/thumb/QGYC4656.webp',
        'photos/0523/thumb/QLWJ7589.webp',
        'photos/0523/thumb/RLQV6347.webp',
        'photos/0523/thumb/RLYB2137.webp',
        'photos/0523/thumb/SCHG8969.webp',
        'photos/0523/thumb/TLWI9514.webp',
        'photos/0523/thumb/UJHI9251.webp',
        'photos/0523/thumb/WZYD5557.webp',
        'photos/0523/thumb/XSUC0006.webp'
      ],
      imagesLenght: 39
    }, {
      id: 4,
      folderName: 'Urodziny Mateuszka - nagrania',
      videos: [
        'videos/0523/CXUM0974.MOV'
      ],
      videosLenght: 0
    }, {
      id: 5,
      folderName: 'Ciekawe spotkanie z mamą naszej Nikusi',
      images: [
        'photos/0523/thumb/FRQK5596.webp',
        'photos/0523/thumb/GLSV0735.webp',
        'photos/0523/thumb/HPCH1712.webp',
        'photos/0523/thumb/HXDU8864.webp',
        'photos/0523/thumb/HYMH5957.webp',
        'photos/0523/thumb/KAOD1318.webp',
        'photos/0523/thumb/KZES1443.webp',
        'photos/0523/thumb/LPJT2009.webp',
        'photos/0523/thumb/LPXF7088.webp',
        'photos/0523/thumb/LXTK7664.webp',
        'photos/0523/thumb/NJIW3158.webp',
        'photos/0523/thumb/QBQL8033.webp',
        'photos/0523/thumb/QQGG8668.webp',
        'photos/0523/thumb/SFJP5461.webp',
        'photos/0523/thumb/SKJN7011.webp',
        'photos/0523/thumb/TZWA0902.webp',
        'photos/0523/thumb/VEZF8315.webp',
        'photos/0523/thumb/VPEP7980.webp',
        'photos/0523/thumb/VRXR2123.webp',
        'photos/0523/thumb/VVXW5950.webp',
        'photos/0523/thumb/WQWL9922.webp'
      ],
      imagesLenght: 20
    }, {
      id: 6,
      folderName: 'Lubimy Przyrodę',
      images: [
        'photos/0523/thumb/DLUM3954.webp',
        'photos/0523/thumb/DUWN9401.webp',
        'photos/0523/thumb/JEWC2496.webp',
        'photos/0523/thumb/JNAA2012.webp',
        'photos/0523/thumb/JWFR1804.webp',
        'photos/0523/thumb/KVFV7426.webp',
        'photos/0523/thumb/LCOU8018.webp',
        'photos/0523/thumb/LIQN9509.webp',
        'photos/0523/thumb/MJMH4582.webp',
        'photos/0523/thumb/OPLA0911.webp',
        'photos/0523/thumb/OVOQ6504.webp',
        'photos/0523/thumb/QRFK3713.webp',
        'photos/0523/thumb/SNNW4301.webp',
        'photos/0523/thumb/UXYI7631.webp',
        'photos/0523/thumb/XLUJ1991.webp',
        'photos/0523/thumb/XPSH4103.webp',
      ],
      imagesLenght: 15
    }, {
      id: 7,
      folderName: 'W Bibliotece',
      images: [
        'photos/0523/thumb/ADPX6886.webp',
        'photos/0523/thumb/AKRJ6732.webp',
        'photos/0523/thumb/AXDM9560.webp',
        'photos/0523/thumb/AYPE7365.webp',
        'photos/0523/thumb/BZCS4684.webp',
        'photos/0523/thumb/DIYX6545.webp',
        'photos/0523/thumb/EAPZ1543.webp',
        'photos/0523/thumb/EMEZ5292.webp',
        'photos/0523/thumb/FDON9339.webp',
        'photos/0523/thumb/FHVG1140.webp',
        'photos/0523/thumb/GVHK1534.webp',
        'photos/0523/thumb/HBGH3777.webp',
        'photos/0523/thumb/IMG_3691.webp',
        'photos/0523/thumb/IMG_3692.webp',
        'photos/0523/thumb/IMG_3693.webp',
        'photos/0523/thumb/IMG_3694.webp',
        'photos/0523/thumb/IMG_3695.webp',
        'photos/0523/thumb/IQMI7399.webp',
        'photos/0523/thumb/ISHL4402.webp',
        'photos/0523/thumb/JBGF6849.webp',
        'photos/0523/thumb/KBVF1572.webp',
        'photos/0523/thumb/KDOV1525.webp',
        'photos/0523/thumb/KRVO0612.webp',
        'photos/0523/thumb/KXHD1455.webp',
        'photos/0523/thumb/LNSC5961.webp',
        'photos/0523/thumb/PLBP6378.webp',
        'photos/0523/thumb/PWDD8861.webp',
        'photos/0523/thumb/QAWO4016.webp',
        'photos/0523/thumb/QCCX3725.webp',
        'photos/0523/thumb/QSPI5372.webp',
        'photos/0523/thumb/RCRP3639.webp',
        'photos/0523/thumb/RIKA6951.webp',
        'photos/0523/thumb/RWME3739.webp',
        'photos/0523/thumb/SKAE5158.webp',
        'photos/0523/thumb/SLFT3321.webp',
        'photos/0523/thumb/SQJJ9153.webp',
        'photos/0523/thumb/SXMS2393.webp',
        'photos/0523/thumb/TOYE8845.webp',
        'photos/0523/thumb/UPNP4925.webp',
        'photos/0523/thumb/UYHC1764.webp',
        'photos/0523/thumb/VJYV2649.webp',
        'photos/0523/thumb/VOOU9696.webp',
        'photos/0523/thumb/WIGF5576.webp',
        'photos/0523/thumb/WWKU2575.webp',
        'photos/0523/thumb/XBBI3993.webp',
        'photos/0523/thumb/XGFP1223.webp',
        'photos/0523/thumb/XLVU2422.webp',
        'photos/0523/thumb/YHAC9851.webp'
      ],
      imagesLenght: 47
    }
  ]
  const images0423 = [
    {
      id: 0,
      folderName: 'Święta Majowe',
      images: [
        'photos/0423/thumb/GPYU8848.webp',
        'photos/0423/thumb/IMG_3553.webp',
        'photos/0423/thumb/IMG_3554.webp',
        'photos/0423/thumb/NBQM7127.webp',
        'photos/0423/thumb/YEJO0118.webp'
      ],
      imagesLenght: 4
    }, {
      id: 1,
      folderName: 'Święta Majowe - nagrania',
      videos: [
        'videos/0423/BNZJ9185.MOV'
      ],
      videosLenght: 0
    }, {
      id: 2,
      folderName: 'Wiosna',
      images: [
        'photos/0423/thumb/BTYU0030.webp',
        'photos/0423/thumb/CFEC1294.webp',
        'photos/0423/thumb/EILU5783.webp',
        'photos/0423/thumb/ESTA6250.webp',
        'photos/0423/thumb/IMG_3516.webp',
        'photos/0423/thumb/IMG_3517.webp',
        'photos/0423/thumb/IMG_3519.webp',
        'photos/0423/thumb/IMG_3520.webp',
        'photos/0423/thumb/IMG_3521.webp',
        'photos/0423/thumb/IMG_3522.webp',
        'photos/0423/thumb/IMG_3524.webp',
        'photos/0423/thumb/NYGH2240.webp'
      ],
      imagesLenght: 11
    }, {
      id: 3,
      folderName: 'Wiosna - nagrania',
      videos: [
        'videos/0423/FWXN0747.MOV',
        'videos/0423/MFNK3248.MOV'
      ],
      videosLenght: 1
    }, {
      id: 4,
      folderName: 'Uczymy się',
      images: [
        'photos/0423/thumb/CREG3991.webp',
        'photos/0423/thumb/DPJF9848.webp',
        'photos/0423/thumb/DPRK2784.webp',
        'photos/0423/thumb/DTAD9200.webp',
        'photos/0423/thumb/FSOR9614.webp',
        'photos/0423/thumb/HAPN9360.webp',
        'photos/0423/thumb/LNOR5803.webp',
        'photos/0423/thumb/NIEQ7747.webp',
        'photos/0423/thumb/PQUH2061.webp',
        'photos/0423/thumb/QGVX2049.webp'
      ],
      imagesLenght: 9
    }, {
      id: 5,
      folderName: 'Lubimy tańczyć i bawić się',
      images: [
        'photos/0423/thumb/IMG_3510.webp',
        'photos/0423/thumb/IMG_3511.webp',
        'photos/0423/thumb/IMG_3512.webp',
        'photos/0423/thumb/IMG_3513.webp',
        'photos/0423/thumb/NXXM4119.webp',
        'photos/0423/thumb/TXPJ8750.webp',
        'photos/0423/thumb/VWHM9299.webp',
        'photos/0423/thumb/WQOS1462.webp'
      ],
      imagesLenght: 7
    }, {
      id: 6,
      folderName: 'Lubimy tańczyć i bawić się - nagrania',
      videos: [
        'videos/0423/EKFJ2448.MOV'
      ],
      videosLenght: 0
    }, {
      id: 7,
      folderName: 'Szukamy wiosny',
      images: [
        'photos/0423/thumb/IMG_3450.webp',
        'photos/0423/thumb/IMG_3453.webp',
        'photos/0423/thumb/IMG_3454.webp',
        'photos/0423/thumb/IMG_3455.webp'
      ],
      imagesLenght: 3
    }, {
      id: 8,
      folderName: 'Uczymy się',
      images: [
        'photos/0423/thumb/IMG_3458.webp',
        'photos/0423/thumb/IMG_3460.webp',
        'photos/0423/thumb/IMG_3461.webp',
        'photos/0423/thumb/IMG_3463.webp',
        'photos/0423/thumb/IMG_3464.webp',
        'photos/0423/thumb/IMG_3477.webp',
        'photos/0423/thumb/IMG_3486.webp',
        'photos/0423/thumb/IMG_3487.webp',
        'photos/0423/thumb/IMG_3488.webp',
        'photos/0423/thumb/IMG_3489.webp',
        'photos/0423/thumb/IMG_3490.webp',
        'photos/0423/thumb/IMG_3491.webp',
        'photos/0423/thumb/IMG_3492.webp'
      ],
      imagesLenght: 12
    }, {
      id: 9,
      folderName: 'Uczymy się - nagrania',
      videos: [
        'videos/0423/DAPI2595.MP4'
      ],
      videosLenght: 0
    }, {
      id: 10,
      folderName: 'Bajka wielkanocna',
      images: [
        'photos/0423/thumb/IMG_3465.webp',
        'photos/0423/thumb/IMG_3466.webp',
        'photos/0423/thumb/IMG_3467.webp',
        'photos/0423/thumb/IMG_3468.webp',
        'photos/0423/thumb/IMG_3469.webp',
        'photos/0423/thumb/IMG_3470.webp',
        'photos/0423/thumb/IMG_3472.webp',
        'photos/0423/thumb/IMG_3473.webp',
        'photos/0423/thumb/IMG_3474.webp',
        'photos/0423/thumb/IMG_3475.webp',
        'photos/0423/thumb/IMG_3476.webp',
        'photos/0423/thumb/IMG_3477.webp',
        'photos/0423/thumb/IMG_3478.webp',
        'photos/0423/thumb/IMG_3479.webp',
        'photos/0423/thumb/IMG_3480.webp',
        'photos/0423/thumb/IMG_3481.webp',
        'photos/0423/thumb/IMG_3482.webp'
      ],
      imagesLenght: 16
    }, {
      id: 11,
      folderName: 'Wielkanoc',
      images: [
        'photos/0423/thumb/IMG_3497.webp',
        'photos/0423/thumb/IMG_3498.webp',
        'photos/0423/thumb/IMG_3499.webp',
        'photos/0423/thumb/IMG_3500.webp',
        'photos/0423/thumb/IMG_3501.webp',
        'photos/0423/thumb/IMG_3502.webp',
        'photos/0423/thumb/IMG_3503.webp',
        'photos/0423/thumb/IMG_3504.webp'
      ],
      imagesLenght: 7
    }
  ]
  const images0323 = [
    {
      id: 0,
      folderName: 'Teatrzyk - nagrania',
      videos: [
        'videos/0323/LQDJ2018.MOV',
        'videos/0323/NCOV2480.MOV',
        'videos/0323/NWHU2001.MOV',
        'videos/0323/UMUS1072.MOV',
        'videos/0323/VAKI1240.MOV',
        'videos/0323/VHCM1850.MOV'
      ],
      videosLenght: 5
    }, {
      id: 1,
      folderName: 'Urodziny Boryska',
      images: [
        'photos/0323/thumb/APSU8069.JPG',
        'photos/0323/thumb/BXVD7869.JPG',
        'photos/0323/thumb/EKNR7145.JPG',
        'photos/0323/thumb/ELKW1785.JPG',
        'photos/0323/thumb/ESDI8373.JPG',
        'photos/0323/thumb/FALU1301.JPG',
        'photos/0323/thumb/GJCO3746.JPG',
        'photos/0323/thumb/GONL0137.JPG',
        'photos/0323/thumb/KWZK0065.JPG',
        'photos/0323/thumb/LRFA8202.JPG',
        'photos/0323/thumb/LWUN0286.JPG',
        'photos/0323/thumb/MDOH2658.JPG',
        'photos/0323/thumb/MRHD0879.JPG',
        'photos/0323/thumb/NOBJ1278.JPG',
        'photos/0323/thumb/OCWT1867.JPG',
        'photos/0323/thumb/ONKT1787.JPG',
        'photos/0323/thumb/PQEB1292.JPG',
        'photos/0323/thumb/QSKA5999.JPG',
        'photos/0323/thumb/QUAZ5439.JPG',
        'photos/0323/thumb/RCDR5167.JPG',
        'photos/0323/thumb/SDOU6778.JPG',
        'photos/0323/thumb/SXKX9554.JPG',
        'photos/0323/thumb/TSUG8327.JPG',
        'photos/0323/thumb/TVDP8431.JPG',
        'photos/0323/thumb/UCNH9824.JPG',
        'photos/0323/thumb/UXNU3123.JPG',
        'photos/0323/thumb/UXXI5189.JPG',
        'photos/0323/thumb/WXMN4436.JPG',
        'photos/0323/thumb/XFJK7853.JPG',
        'photos/0323/thumb/YIVM6056.JPG'
      ],
      imagesLenght: 28
    }, {
      id: 2,
      folderName: 'Urodziny Boryska - nagrania',
      videos: [
        'videos/0323/KCPV2743.MOV'
      ],
      videosLenght: 0
    }, {
      id: 3,
      folderName: 'Dzień Kobiet',
      images: [
        'photos/0323/thumb/XHZI8539.JPG',
        'photos/0323/thumb/KZBL4979.JPG',
        'photos/0323/thumb/EGQC6064.JPG',
        'photos/0323/thumb/KUGM2641.JPG',
        'photos/0323/thumb/BVJT8157.JPG',
        'photos/0323/thumb/HUQE9721.JPG',
        'photos/0323/thumb/NLOH6608.JPG',
        'photos/0323/thumb/TFYT4580.JPG',
        'photos/0323/thumb/VQGC3985.JPG',
        'photos/0323/thumb/XFPJ9303.JPG'
      ],
      imagesLenght: 9
    }, {
      id: 4,
      folderName: 'Dzień Kobiet - nagrania',
      videos: [
        'videos/0323/AAFA1552.MOV'
      ],
      videosLenght: 0
    }, {
      id: 5,
      folderName: 'Powitanie wiosny - nagrania',
      videos: [
        'videos/0323/HKOG8171.MOV',
        'videos/0323/XOKE5755.MOV'
      ],
      videosLenght: 1
    }, {
      id: 6,
      folderName: 'Powitanie wiosny',
      images: [
        'photos/0323/thumb/BGPR3438.webp',
        'photos/0323/thumb/BMCJ3681.webp',
        'photos/0323/thumb/FDEY8749.webp',
        'photos/0323/thumb/GVQF0269.webp',
        'photos/0323/thumb/ILKN2273.webp',
        'photos/0323/thumb/IMG_3369.webp',
        'photos/0323/thumb/IMG_3371.webp',
        'photos/0323/thumb/IMG_3373.webp',
        'photos/0323/thumb/IMG_3375.webp',
        'photos/0323/thumb/IMG_3377.webp',
        'photos/0323/thumb/IMG_3378.webp',
        'photos/0323/thumb/IMG_3379.webp',
        'photos/0323/thumb/IMG_3380.webp',
        'photos/0323/thumb/IMG_3382.webp',
        'photos/0323/thumb/IMG_3384.webp',
        'photos/0323/thumb/IMG_3385.webp',
        'photos/0323/thumb/IMG_3415.webp',
        'photos/0323/thumb/OELX2828.webp',
        'photos/0323/thumb/PELS8237.webp',
        'photos/0323/thumb/PFNX6622.webp',
        'photos/0323/thumb/QNQQ0592.webp',
        'photos/0323/thumb/RAQS4864.webp',
        'photos/0323/thumb/TZDX6985.webp',
        'photos/0323/thumb/URJS8494.webp',
        'photos/0323/thumb/VFMK5251.webp',
        'photos/0323/thumb/WDZB3953.webp',
        'photos/0323/thumb/XGKM9819.webp'
      ],
      imagesLenght: 26
    }
  ]
  const images0223 = [
    {
      id: 0,
      folderName: 'Walentynki',
      images: [
        'photos/0223/thumb/IMG_3111.webp',
        'photos/0223/thumb/IMG_3113.webp',
        'photos/0223/thumb/IMG_3118.webp',
        'photos/0223/thumb/IMG_3119.webp',
        'photos/0223/thumb/IMG_3121.webp',
        'photos/0223/thumb/IMG_3123.webp',
        'photos/0223/thumb/IMG_3127.webp',
        'photos/0223/thumb/IMG_3128.webp'
      ],
      imagesLenght: 7
    }, {
      id: 1,
      folderName: 'Walentynki - nagrania',
      videos: [
        'videos/0223/KDSU4015.MOV',
        'videos/0223/SWMM7611.MOV'
      ],
      videosLenght: 1
    }, {
      id: 2,
      folderName: 'Urodziny Marcysi',
      images: [
        'photos/0223/thumb/Resized_20230207_100433.webp',
        'photos/0223/thumb/Resized_20230207_100456.webp',
        'photos/0223/thumb/Resized_20230207_100506.webp',
        'photos/0223/thumb/Resized_20230207_101038.webp',
        'photos/0223/thumb/Resized_20230207_101053.webp',
        'photos/0223/thumb/Resized_20230207_101100.webp',
        'photos/0223/thumb/Resized_20230207_101107.webp',
        'photos/0223/thumb/Resized_20230207_101226.webp',
        'photos/0223/thumb/Resized_20230207_101341.webp',
        'photos/0223/thumb/Resized_20230207_101404.webp',
        'photos/0223/thumb/Resized_20230207_101413.webp'
      ],
      imagesLenght: 10
    }, {
      id: 3,
      folderName: 'Zabawa karnawałowa',
      images: [
        'photos/0223/thumb/AZHN6981.webp',
        'photos/0223/thumb/BAQR8878.webp',
        'photos/0223/thumb/BMOI7269.webp',
        'photos/0223/thumb/BSHU6023.webp',
        'photos/0223/thumb/CUGI0771.webp',
        'photos/0223/thumb/DGEW5957.webp',
        'photos/0223/thumb/EHGP5401.webp',
        'photos/0223/thumb/ETNH8479.webp',
        'photos/0223/thumb/FPQP2910.webp',
        'photos/0223/thumb/GULV5223.webp',
        'photos/0223/thumb/IQIC4654.webp',
        'photos/0223/thumb/ISZQ7869.webp',
        'photos/0223/thumb/IUAO9628.webp',
        'photos/0223/thumb/IVSR3798.webp',
        'photos/0223/thumb/JAFO0462.webp',
        'photos/0223/thumb/JCHX6985.webp',
        'photos/0223/thumb/JOUD9428.webp',
        'photos/0223/thumb/JWJQ9364.webp',
        'photos/0223/thumb/KVME7605.webp',
        'photos/0223/thumb/LTPO3117.webp',
        'photos/0223/thumb/LWNK1975.webp',
        'photos/0223/thumb/MWHK2365.webp',
        'photos/0223/thumb/MYCU9165.webp',
        'photos/0223/thumb/NOQQ6548.webp',
        'photos/0223/thumb/NSZV2190.webp',
        'photos/0223/thumb/OXGI3327.webp',
        'photos/0223/thumb/PDSG2491.webp',
        'photos/0223/thumb/PPLV7148.webp',
        'photos/0223/thumb/QOGH4722.webp',
        'photos/0223/thumb/QTPV1798.webp',
        'photos/0223/thumb/RNMK8112.webp',
        'photos/0223/thumb/RUFF7005.webp',
        'photos/0223/thumb/TCYY0949.webp',
        'photos/0223/thumb/UTGT5402.webp',
        'photos/0223/thumb/VHBC1194.webp',
        'photos/0223/thumb/WGIZ3365.webp',
        'photos/0223/thumb/XKJV7410.webp',
        'photos/0223/thumb/XOYV7479.webp'
      ],
      imagesLenght: 37
    }, {
      id: 4,
      folderName: 'Zabawa karnawałowa - nagrania',
      videos: [
        'videos/0223/RTZE8348.MOV'
      ],
      videosLenght: 0
    }
  ]
  const images0123 = [
    {
      id: 0,
      folderName: 'Konkurs pt. Kartka Świąteczna',
      images: [
        'photos/0123/thumb/IMG_2930.JPG',
        'photos/0123/thumb/IMG_2931.JPG',
        'photos/0123/thumb/IMG_2932.JPG',
        'photos/0123/thumb/IMG_2933.JPG',
        'photos/0123/thumb/IMG_2934.JPG',
        'photos/0123/thumb/IMG_2935.JPG',
        'photos/0123/thumb/IMG_2936.JPG',
        'photos/0123/thumb/IMG_2937.JPG',
        'photos/0123/thumb/IMG_2939.JPG',
        'photos/0123/thumb/IMG_2940.JPG',
        'photos/0123/thumb/IMG_2942.JPG',
        'photos/0123/thumb/IMG_2951.JPG',
        'photos/0123/thumb/IMG_2959.JPG',
        'photos/0123/thumb/IMG_2961.JPG'
      ],
      imagesLenght: 13
    }, {
      id: 1,
      folderName: 'Angielskie rytmy',
      videos: [
        'videos/0123/IXUO6424.MOV',
        'videos/0123/NHOU1403.MOV'
      ],
      videosLenght: 1
    }, {
      id: 2,
      folderName: 'Jeszcze trochę zimy',
      images: [
        'photos/0123/thumb/IMG_2837.JPG',
        'photos/0123/thumb/IMG_2849.JPG',
        'photos/0123/thumb/IMG_2850.JPG',
        'photos/0123/thumb/IMG_2851.JPG',
        'photos/0123/thumb/IMG_2852.JPG',
        'photos/0123/thumb/IMG_2853.JPG',
        'photos/0123/thumb/IMG_2855.JPG',
        'photos/0123/thumb/IMG_2857.JPG',
        'photos/0123/thumb/IMG_2858.JPG',
        'photos/0123/thumb/IMG_2859.JPG'
      ],
      imagesLenght: 9
    }, {
      id: 3,
      folderName: 'Na sportowo',
      images: [
        'photos/0123/thumb/IMG_2905.JPG',
        'photos/0123/thumb/IMG_2906.JPG',
        'photos/0123/thumb/IMG_2907.JPG',
        'photos/0123/thumb/IMG_2908.JPG'
      ],
      imagesLenght: 3
    }, {
      id: 4,
      folderName: 'Lubimy to',
      images: [
        'photos/0123/thumb/IMG_2838.JPG',
        'photos/0123/thumb/IMG_2839.JPG',
        'photos/0123/thumb/IMG_2840.JPG',
        'photos/0123/thumb/IMG_2841.JPG',
        'photos/0123/thumb/IMG_2842.JPG',
        'photos/0123/thumb/IMG_2843.JPG',
        'photos/0123/thumb/IMG_2860.JPG',
        'photos/0123/thumb/IMG_2861.JPG',
        'photos/0123/thumb/IMG_2862.JPG',
        'photos/0123/thumb/IMG_2911.JPG',
        'photos/0123/thumb/IMG_2947.JPG'
      ],
      imagesLenght: 10
    }, {
      id: 5,
      folderName: 'Święta',
      videos: [
        'videos/0123/HDCX4552.MOV'
      ],
      videosLenght: 0
    }, {
      id: 6,
      folderName: 'Dzień Babci i Dziadka',
      videos: [
        'videos/0123/10000000_9343243255701652_6221447427781192684_n.mp4',
        'videos/0123/10000000_6481545285206648_3279558198732314838_n.mp4'
      ],
      videosLenght: 1
    }, {
      id: 7,
      folderName: 'Urodziny Nikoli i Oliwii',
      images: [
        'photos/0123/thumb/1.jpg',
        'photos/0123/thumb/2.jpg',
        'photos/0123/thumb/3.jpg',
        'photos/0123/thumb/4.jpg',
        'photos/0123/thumb/5.jpg',
        'photos/0123/thumb/6.jpg',
        'photos/0123/thumb/7.jpg',
        'photos/0123/thumb/8.jpg',
        'photos/0123/thumb/9.jpg',
        'photos/0123/thumb/10.jpg'
      ],
      imagesLenght: 9
    }, {
      id: 8,
      folderName: 'Ciekawe spotkanie z Tatą naszej Idy',
      images: [
        'photos/0123/thumb/IMG_3037.webp',
        'photos/0123/thumb/IMG_3038.webp',
        'photos/0123/thumb/IMG_3040.webp',
        'photos/0123/thumb/IMG_3041.webp',
        'photos/0123/thumb/IMG_3042.webp',
        'photos/0123/thumb/IMG_3043.webp',
        'photos/0123/thumb/IMG_3044.webp',
        'photos/0123/thumb/IMG_3045.webp',
        'photos/0123/thumb/IMG_3046.webp',
        'photos/0123/thumb/IMG_3047.webp',
        'photos/0123/thumb/IMG_3048.webp',
        'photos/0123/thumb/IMG_3049.webp',
        'photos/0123/thumb/IMG_3051.webp',
        'photos/0123/thumb/IMG_3052.webp',
        'photos/0123/thumb/IMG_3053.webp',
        'photos/0123/thumb/IMG_3059.webp',
        'photos/0123/thumb/IMG_3060.webp',
        'photos/0123/thumb/IMG_3061.webp',
        'photos/0123/thumb/IMG_3062.webp',
        'photos/0123/thumb/IMG_3063.webp',
        'photos/0123/thumb/IMG_3064.webp',
        'photos/0123/thumb/IMG_3065.webp',
        'photos/0123/thumb/IMG_3066.webp'
      ],
      imagesLenght: 22
    }
  ]
  const images1222 = [
    {
      id: 0,
      folderName: 'Mikołajki',
      images: [
        'photos/1222/thumb/IMG_2790.JPG',
        'photos/1222/thumb/IMG_2792.JPG',
        'photos/1222/thumb/IMG_2793.JPG',
        'photos/1222/thumb/IMG_2794.JPG',
        'photos/1222/thumb/IMG_2795.JPG',
        'photos/1222/thumb/IMG_2796.JPG',
        'photos/1222/thumb/IMG_2798.JPG',
        'photos/1222/thumb/IMG_2799.JPG',
        'photos/1222/thumb/IMG_2800.JPG',
        'photos/1222/thumb/IMG_2801.JPG',
        'photos/1222/thumb/IMG_2806.JPG',
        'photos/1222/thumb/IMG_2807.JPG',
        'photos/1222/thumb/IMG_2808.JPG',
        'photos/1222/thumb/IMG_2809.JPG',
        'photos/1222/thumb/IMG_2810.JPG',
        'photos/1222/thumb/IMG_2812.JPG',
        'photos/1222/thumb/IMG_2814.JPG',
        'photos/1222/thumb/IMG_2816.JPG',
        'photos/1222/thumb/IMG_2818.JPG',
        'photos/1222/thumb/IMG_2822.JPG',
        'photos/1222/thumb/IMG_2823.JPG',
        'photos/1222/thumb/IMG_2824.JPG',
        'photos/1222/thumb/IMG_2825.JPG',
        'photos/1222/thumb/IMG_2828.JPG',
        'photos/1222/thumb/IMG_2831.JPG',
        'photos/1222/thumb/IMG_2832.JPG'
      ],
      imagesLenght: 25
    },
    {
      id: 1,
      folderName: 'Zimowe zabawy',
      images: [
        'photos/1222/thumb/IMG_2837.JPG',
        'photos/1222/thumb/IMG_2849.JPG',
        'photos/1222/thumb/IMG_2850.JPG',
        'photos/1222/thumb/IMG_2851.JPG',
        'photos/1222/thumb/IMG_2852.JPG',
        'photos/1222/thumb/IMG_2853.JPG',
        'photos/1222/thumb/IMG_2855.JPG',
        'photos/1222/thumb/IMG_2856.JPG',
        'photos/1222/thumb/IMG_2857.JPG',
        'photos/1222/thumb/IMG_2859.JPG'
      ],
      imagesLenght: 9
    },
    {
      id: 2,
      folderName: 'Teatrzyk',
      images: [
        'photos/1222/thumb/IMG_2876.JPG',
        'photos/1222/thumb/IMG_2878.JPG'
      ],
      imagesLenght: 1
    },
    {
      id: 3,
      folderName: 'Wizyta Mikołaja',
      images: [
        'photos/1222/thumb/IMG_2877.JPG',
        'photos/1222/thumb/IMG_2879.JPG',
        'photos/1222/thumb/IMG_2880.JPG',
        'photos/1222/thumb/IMG_2881.JPG',
        'photos/1222/thumb/IMG_2882.JPG',
        'photos/1222/thumb/IMG_2886.JPG',
        'photos/1222/thumb/IMG_2888.JPG',
        'photos/1222/thumb/IMG_2889.JPG',
        'photos/1222/thumb/IMG_2891.JPG',
        'photos/1222/thumb/IMG_2893.JPG',
        'photos/1222/thumb/IMG_2895.JPG',
        'photos/1222/thumb/IMG_2897.JPG',
        'photos/1222/thumb/IMG_2899.JPG',
        'photos/1222/thumb/IMG_2900.JPG',
        'photos/1222/thumb/IMG_2901.JPG',
        'photos/1222/thumb/IMG_2902.JPG'
      ],
      imagesLenght: 15
    },
    {
      id: 4,
      folderName: 'Wizyta Mikołaja - nagrania',
      videos: [
        'videos/1222/KDFP6879.MOV',
        'videos/1222/TQMZ4079.MOV'
      ],
      videosLenght: 1,
    }
  ]
  const images1122 = [
    {
      id: 0,
      folderName: '11 listopada - nagrania',
      videos: [
        'videos/1122/DENW2799.MOV',
        'videos/1122/CCYH4948.MOV'
      ],
      videosLenght: 1,
    }, {
      id: 1,
      folderName: '11 listopada',
      images: [
        'photos/1122/thumb/IMG_2702.JPG',
        'photos/1122/thumb/IMG_2703.JPG',
        'photos/1122/thumb/IMG_2707.JPG',
        'photos/1122/thumb/IMG_2708.JPG',
        'photos/1122/thumb/IMG_2709.JPG',
        'photos/1122/thumb/IMG_2714.JPG',
        'photos/1122/thumb/IMG_2717.JPG'
      ],
      imagesLenght: 6,
    }, {
      id: 2,
      folderName: 'Uczymy się',
      images: [
        'photos/1122/thumb/IMG_2685.JPG',
        'photos/1122/thumb/IMG_2686.JPG',
        'photos/1122/thumb/IMG_2688.JPG',
        'photos/1122/thumb/IMG_2689.JPG',
        'photos/1122/thumb/IMG_2760.JPG',
        'photos/1122/thumb/IMG_2761.JPG',
        'photos/1122/thumb/IMG_2763.JPG',
        'photos/1122/thumb/IMG_2765.JPG',
        'photos/1122/thumb/IMG_2766.JPG',
        'photos/1122/thumb/IMG_2767.JPG',
        'photos/1122/thumb/IMG_2768.JPG',
        'photos/1122/thumb/IMG_2769.JPG'
      ],
      imagesLenght: 11
    }, {
      id: 3,
      folderName: 'Bawimy się',
      images: [
        'photos/1122/thumb/IMG_2720.JPG',
        'photos/1122/thumb/IMG_2727.JPG',
        'photos/1122/thumb/IMG_2728.JPG',
        'photos/1122/thumb/IMG_2729.JPG',
        'photos/1122/thumb/IMG_2730.JPG',
        'photos/1122/thumb/IMG_2732.JPG',
        'photos/1122/thumb/IMG_2733.JPG',
        'photos/1122/thumb/IMG_2734.JPG',
        'photos/1122/thumb/IMG_2735.JPG',
        'photos/1122/thumb/IMG_2737.JPG',
        'photos/1122/thumb/IMG_2738.JPG',
        'photos/1122/thumb/IMG_2739.JPG',
        'photos/1122/thumb/IMG_2740.JPG',
        'photos/1122/thumb/IMG_2742.JPG',
        'photos/1122/thumb/IMG_2745.JPG',
        'photos/1122/thumb/IMG_2747.JPG',
        'photos/1122/thumb/IMG_2748.JPG',
        'photos/1122/thumb/IMG_2749.JPG',
        'photos/1122/thumb/IMG_2751.JPG',
        'photos/1122/thumb/IMG_2753.JPG',
        'photos/1122/thumb/IMG_2754.JPG',
        'photos/1122/thumb/IMG_2770.JPG',
        'photos/1122/thumb/IMG_2771.JPG',
        'photos/1122/thumb/IMG_2772.JPG',
        'photos/1122/thumb/IMG_2774.JPG',
        'photos/1122/thumb/IMG_2775.JPG',
        'photos/1122/thumb/IMG_2777.JPG',
        'photos/1122/thumb/IMG_2694.JPG',
        'photos/1122/thumb/IMG_2695.JPG',
        'photos/1122/thumb/IMG_2696.JPG',
        'photos/1122/thumb/IMG_2697.JPG',
        'photos/1122/thumb/IMG_2698.JPG',
        'photos/1122/thumb/IMG_2699.JPG',
        'photos/1122/thumb/IMG_2700.JPG',
        'photos/1122/thumb/IMG_2701.JPG'
      ],
      imagesLenght: 34
    }, {
      id: 4,
      folderName: 'Dzień pluszowego misia',
      images: [
        'photos/1122/thumb/IMG_2755.JPG',
        'photos/1122/thumb/IMG_2778.JPG',
        'photos/1122/thumb/IMG_2781.JPG'
      ],
      imagesLenght: 2,
    }, {
      id: 5,
      folderName: 'Urodziny Darka',
      images: [
        'photos/1122/thumb/IMG_2783.JPG',
        'photos/1122/thumb/IMG_2784.JPG',
        'photos/1122/thumb/IMG_2786.JPG',
        'photos/1122/thumb/IMG_2789.JPG'
      ],
      imagesLenght: 3,
    }
  ]
  const images1022 = [
    {
      id: 0,
      folderName: 'Zumba',
      images: [
        'photos/0922/thumb/IMG_2357.JPG'
      ],
      imagesLenght: 0
    }, {
      id: 1,
      folderName: 'Zumbini',
      images: [
        'photos/0922/thumb/IMG_2499.JPG',
        'photos/0922/thumb/IMG_2502.JPG',
        'photos/0922/thumb/IMG_2503.JPG',
        'photos/0922/thumb/IMG_2504.JPG',
        'photos/0922/thumb/IMG_2506.JPG',
        'photos/0922/thumb/IMG_2507.JPG',
        'photos/1022/thumb/IMG_0010.JPG',
        'photos/1022/thumb/IMG_0013.JPG',
        'photos/1022/thumb/IMG_0016.JPG',
        'photos/1022/thumb/IMG_0019.JPG',
        'photos/1022/thumb/IMG_0022.JPG',
        'photos/1022/thumb/IMG_0025.JPG',
        'photos/1022/thumb/IMG_0028.JPG',
        'photos/1022/thumb/IMG_0031.JPG',
        'photos/1022/thumb/IMG_0034.JPG',
        'photos/1022/thumb/IMG_0037.JPG',
        'photos/1022/thumb/IMG_0040.JPG',
        'photos/1022/thumb/IMG_0046.JPG',
        'photos/1022/thumb/IMG_0049.JPG',
      ],
      imagesLenght: 18
    }, {
      id: 2,
      folderName: 'Uczymy się',
      images: [
        'photos/0922/thumb/IMG_2546.JPG',
        'photos/0922/thumb/IMG_2547.JPG',
        'photos/0922/thumb/IMG_2548.JPG',
        'photos/1022/thumb/IMG_2616.JPG',
        'photos/1022/thumb/IMG_2619.JPG',
        'photos/1022/thumb/IMG_2621.JPG',
        'photos/1022/thumb/IMG_2622.JPG',
        'photos/1022/thumb/IMG_2623.JPG',
        'photos/1022/thumb/IMG_2624.JPG',
        'photos/1022/thumb/IMG_2625.JPG',
        'photos/1022/thumb/IMG_2626.JPG',
        'photos/1022/thumb/IMG_2627.JPG',
        'photos/1022/thumb/IMG_2628.JPG',
        'photos/1022/thumb/IMG_2630.JPG',
        'photos/1022/thumb/IMG_2633.JPG',
        'photos/1022/thumb/IMG_2634.JPG',
        'photos/1022/thumb/IMG_2635.JPG',
        'photos/1022/thumb/IMG_2637.JPG',
        'photos/1022/thumb/IMG_2638.JPG',
        'photos/1022/thumb/IMG_2639.JPG',
        'photos/1022/thumb/IMG_2641.JPG',
        'photos/1022/thumb/IMG_2642.JPG'
      ],
      imagesLenght: 21
    }, {
      id: 3,
      folderName: 'Zajęcia przyrodnicze',
      images: [
        'photos/0922/thumb/IMG_2510.JPG',
        'photos/0922/thumb/IMG_2511.JPG',
        'photos/0922/thumb/IMG_2513.JPG',
        'photos/0922/thumb/IMG_2544.JPG',
        'photos/0922/thumb/IMG_2558.JPG'
      ],
      imagesLenght: 4
    }, {
      id: 4,
      folderName: 'Przyroda',
      images: [
        'photos/1022/thumb/IMG_2569.JPG',
        'photos/1022/thumb/IMG_2570.JPG',
        'photos/1022/thumb/IMG_2571.JPG',
        'photos/1022/thumb/IMG_2572.JPG',
        'photos/1022/thumb/IMG_2573.JPG',
        'photos/1022/thumb/IMG_2574.JPG',
        'photos/1022/thumb/IMG_2575.JPG',
        'photos/1022/thumb/IMG_2576.JPG',
        'photos/1022/thumb/IMG_2578.JPG'
      ],
      imagesLenght: 8
    }, {
      id: 5,
      folderName: 'Teatrzyk',
      images: [
        'photos/1022/thumb/IMG_2648.JPG',
        'photos/1022/thumb/IMG_2650.JPG',
        'photos/1022/thumb/IMG_2652.JPG',
        'photos/1022/thumb/IMG_2653.JPG',
        'photos/1022/thumb/IMG_2655.JPG',
        'photos/1022/thumb/IMG_2656.JPG',
        'photos/1022/thumb/IMG_2658.JPG',
        'photos/1022/thumb/IMG_2659.JPG'
      ],
      imagesLenght: 7
    }, {
      id: 6,
      folderName: 'Urodzinki Ali i Zuzi',
      images: [
        'photos/1022/thumb/IMG_2661.JPG',
        // 'photos/1022/thumb/IMG_2662.JPG',
        // 'photos/1022/thumb/IMG_2663.JPG',
        'photos/1022/thumb/IMG_2664.JPG',
        'photos/1022/thumb/IMG_2665.JPG',
        'photos/1022/thumb/IMG_2666.JPG'
      ],
      imagesLenght: 3
    }
  ]
  const images0922 = [
    {
      id: 0,
      folderName: 'Teatrzyk',
      images: [
        'photos/0922/thumb/IMG_2348.JPG',
        'photos/0922/thumb/IMG_2349.JPG',
        'photos/0922/thumb/IMG_2350.JPG',
        'photos/0922/thumb/IMG_2352.JPG',
        'photos/0922/thumb/IMG_2354.JPG'
      ],
      imagesLenght: 4
    }, {
      id: 1,
      folderName: 'Bawimy się',
      images: [
        'photos/0922/thumb/IMG_2363.JPG',
        'photos/0922/thumb/IMG_2364.JPG',
        'photos/0922/thumb/IMG_2365.JPG',
        'photos/0922/thumb/IMG_2366.JPG',
        'photos/0922/thumb/IMG_2472.JPG',
        'photos/0922/thumb/IMG_2473.JPG',
        'photos/0922/thumb/IMG_2474.JPG',
        'photos/0922/thumb/IMG_2476.JPG',
        'photos/0922/thumb/IMG_2478.JPG',
        'photos/0922/thumb/IMG_2480.JPG',
        'photos/0922/thumb/IMG_2481.JPG',
        'photos/0922/thumb/IMG_2482.JPG'
      ],
      imagesLenght: 11
    }, {
      id: 2,
      folderName: 'Przyroda',
      images: [
        'photos/0922/thumb/IMG_2367.JPG',
        'photos/0922/thumb/IMG_2368.JPG',
        'photos/0922/thumb/IMG_2369.JPG',
        'photos/0922/thumb/IMG_2372.JPG',
        'photos/0922/thumb/IMG_2373.JPG',
        'photos/0922/thumb/IMG_2375.JPG',
        'photos/0922/thumb/IMG_2376.JPG',
        'photos/0922/thumb/IMG_2380.JPG',
        'photos/0922/thumb/IMG_2387.JPG',
        'photos/0922/thumb/IMG_2398.JPG',
        'photos/0922/thumb/IMG_2402.JPG',
        'photos/0922/thumb/IMG_2404.JPG',
        'photos/0922/thumb/IMG_2435.JPG',
        'photos/0922/thumb/IMG_2448.JPG',
        'photos/0922/thumb/IMG_2450.JPG',
        'photos/0922/thumb/IMG_2456.JPG',
        'photos/0922/thumb/IMG_2468.JPG',
        'photos/0922/thumb/IMG_2470.JPG',
        'photos/0922/thumb/IMG_2488.JPG'
      ],
      imagesLenght: 18
    }
  ]

  return (
    <div className='folders-container'>
      <div className='folders-div'>
        <h1>Galeria przedszkolaka</h1>
        <hr className='parent-hr' />
        <FoldersItem title='SIERPIEŃ 2024' key='08/24' images={images0824} />
        <hr className='parent-hr' />
        <FoldersItem title='CZERWIEC 2024' key='06/24' images={images0624} />
        <hr className='parent-hr' />
        <FoldersItem title='MAJ 2024' key='05/24' images={images0524} />
        <hr className='parent-hr' />
        <FoldersItem title='KWIECIEŃ 2024' key='04/24' images={images0424} />
        <hr className='parent-hr' />
        <FoldersItem title='MARZEC 2024' key='03/24' images={images0324} />
        <hr className='parent-hr' />
        <FoldersItem title='LUTY 2024' key='02/24' images={images0224} />
        <hr className='parent-hr' />
        <FoldersItem title='STYCZEŃ 2024' key='01/24' images={images0124} />
        <hr className='parent-hr' />
        <FoldersItem title='GRUDZIEŃ 2023' key='12/23' images={images1223} />
        <hr className='parent-hr' />
        <FoldersItem title='LISTOPAD 2023' key='11/23' images={images1123} />
        <hr className='parent-hr' />
        <FoldersItem title='PAŹDZIERNIK 2023' key='10/23' images={images1023} />
        <hr className='parent-hr' />
        <FoldersItem title='WRZESIEŃ 2023' key='09/23' images={images0923} />
        <hr className='parent-hr' />
        <FoldersItem title='CZERWIEC 2023' key='06/23' images={images0623} />
        <hr className='parent-hr' />
        <FoldersItem title='MAJ 2023' key='05/23' images={images0523} />
        <hr className='parent-hr' />
        <FoldersItem title='KWIECIEŃ 2023' key='04/23' images={images0423} />
        <hr className='parent-hr' />
        <FoldersItem title='MARZEC 2023' key='03/23' images={images0323} />
        <hr className='parent-hr' />
        <FoldersItem title='LUTY 2023' key='02/23' images={images0223} />
        <hr className='parent-hr' />
        <FoldersItem title='STYCZEŃ 2023' key='01/23' images={images0123} />
        <hr className='parent-hr' />
        <FoldersItem title='GRUDZIEŃ 2022' key='12/22' images={images1222} />
        <hr className='parent-hr' />
        <FoldersItem title='LISTOPAD 2022' key='11/22' images={images1122} />
        <hr className='parent-hr' />
        <FoldersItem title='PAŹDZIERNIK 2022' key='10/22' images={images1022} />
        <hr className='parent-hr' />
        <FoldersItem title='WRZESIEŃ 2022' key='09/22' images={images0922} />
      </div>
    </div>
  )
}

export default Folders