import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
  return (
    <>
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to="/" className="navbar-logo big">
                    <img id='navbar-logo' src={'svg/logov2_5.svg'} alt='logo' />
                </Link>
                <Link to="/" className="navbar-logo small">
                    <img id='navbar-logo' src={'svg/logovS_2.svg'} alt='logo2' />
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Strona główna
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/onas' className='nav-links' onClick={closeMobileMenu}>
                            O nas
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/aktualnosci' className='nav-links' onClick={closeMobileMenu}>
                            Aktualności
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/strefa' className='nav-links' onClick={closeMobileMenu}>
                            Strefa rodzica
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/galeria' className='nav-links' onClick={closeMobileMenu}>
                            Galeria
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    </>
  )
}

export default Navbar