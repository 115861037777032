import React, { useState } from 'react'
import ImagesViewer from './ImagesViewer';
import './FoldersItem.css'
import VideosViewer from './VideosViewer';

const FoldersItem = (props) => {
    const [model, setModel] = useState(false);
    const [tempImgSrc, setTempImgSrc] = useState([]);
    const getImg = (item) => {
        setTempImgSrc(item);
        setModel(true);
    }
    const clearModel = () => {
        setTempImgSrc([]);
        setModel(false);
    }

  return (
    <div className='folders-item'>
        <h1>{props.title}</h1>

        <div className={model ? "model open" : "model"}>
            {tempImgSrc.videos ? <VideosViewer videos={tempImgSrc} /> : <ImagesViewer images={tempImgSrc} />}
            <i className='fas fa-times' onClick={() => clearModel()} />
        </div>

        <div className='parent-gallery-container'>
            {props.images.toReversed().map((item, index) => {
                return (
                    <div className='images-parent' key={index} onClick={() => getImg(item)}>
                        <img src={'images/folders.png'} alt="alt" />
                        <p>{item.folderName}</p>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default FoldersItem