import React from 'react'
import './ParentSection.css';
import { useState } from "react";
import Folders from './Folders'

function ParentSection() {
  const [pwd, setPwd] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [logged, setLogged] = useState(false);
  const [error, setError] = useState(false);

  function handleLogin() {
    if(pwd === process.env.REACT_APP_STREFA_PWD) {
      setLogged(true);
    } else {
      setError(true);
    }
  }

  let errorDiv;
  if(error) {
    errorDiv = 
    <div className='error-message'>
      <h2>Błędne hasło</h2> 
    </div>
  } else {
    errorDiv =
    <></>
  }
  
  // let bodyDiv = <Folders/>;
  let bodyDiv;
  if(!logged) {
    bodyDiv = <div className='strefa-container password-page'>
    <div className='strefa-rodzic'>
      
      <div className='top-text'>
        <div className='strefa-text'>
            <h1>Strefa Rodzica</h1>
            <h3>Wpisy zabezpieczone hasłem</h3>
        </div>
        <div className='strefa-logo'>
            <img className='navbar-logo' id='navbar-logo' src={'svg/logov2_5.svg'} alt='logo' />
        </div>
      </div>
      
      {errorDiv}
      <div className='password-input'>
        <div className='input-eye'>
          <i className={isRevealPwd ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"}
          onClick={() => setIsRevealPwd(prevState => !prevState)} />
          <input
          name="pwd"
          className="input-field"
          placeholder="Wpisz hasło"
          type={isRevealPwd ? "text" : "password"}
          value={pwd}
          onChange={e => setPwd(e.target.value)} />
        </div>
        <button className="button-strefa" onClick={handleLogin}>Wejdź</button>
      </div>
    </div>
  </div>;
  } else {
    bodyDiv = <Folders/>;
  }

  return (
    <>
      {bodyDiv} 
    </>
  )
}

export default ParentSection