import React from 'react';
import './HeroSection.css';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <div className='hero-container' id="overlay">
      <div className='text'>
        <h1>Przedszkole "Słoneczka"</h1>
        <p>Zapraszamy wszystkie dzieci</p>
        <p className='zapisy-text'>Zapisy cały rok!</p>
      </div>

        <div className='items-container'>
          
          <Fade left><div className='item news'>
            <img src="/aktualnosci.png" alt='news'/>  
            <h1>Aktualności</h1>
            <br></br>
            <Link to="/aktualnosci">
              <button><h3>Więcej</h3></button></Link>
          </div></Fade>
          <Fade top>
          <div className='item contact'>
          <img src="/kontakt.png" alt='contact'/>  
          
          <h1>Kontakt</h1>
          <br></br>
          <h4>7:00-16:30</h4>
          <h4>al. Jana Pawła II 59c</h4>
          <h4><a href="mailto: info@sloneczka.bialystok.pl">info@sloneczka.bialystok.pl</a></h4>
          <br></br>
          <h3>tel: <a href="tel:856670803">85 667 08 03</a></h3>
          <h3>kom: <a href="tel:602650828">881 240 181</a></h3>
          </div></Fade>
          <Fade right>
          <div className='item sun'>
          <img src="/sun.png" alt='sun'/>  
          <h1>Dyrektor</h1>
          <br></br>
          <h4>mgr Emilia Sadowska</h4>
          </div></Fade>
      </div>
        
    </div>
  )
}

export default HeroSection