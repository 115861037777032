import React, {useEffect, useState} from 'react'
import './TopBtn.css'

function TopBtn() {
    const [backToTopBtn, setBackToTopBtn] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if(window.scrollY > 100) {
                setBackToTopBtn(true);
            } else {
                setBackToTopBtn(false);
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

  return (
    <div className='topbtn'>
        {backToTopBtn && (
            <button onClick={scrollUp}>
                <i className="fa-solid fa-circle-up" />
                </button>
        )}
    </div>
  )
}

export default TopBtn