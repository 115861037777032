import React from 'react'
import './Downloads.css'

function Downloads() {
  return (
    <div className='downloads'>
        <h3>Pliki do pobrania</h3>
        <h4><a href={'files/KARTA ZGŁOSZENIA DZIECKA.pdf'} download='KARTA ZGŁOSZENIA DZIECKA.pdf'><i class="fa-solid fa-file-pdf" style={{marginRight:"2%", color:"red"}} />KARTA ZGŁOSZENIA DZIECKA.pdf</a></h4>
        {/* <h4><a href={karta_w} download='KARTA ZGŁOSZENIA DZIECKA.docx'><i class="fa-solid fa-file-word"style={{marginRight:"2%"}} />KARTA ZGŁOSZENIA DZIECKA.docx</a></h4> */}
        <h4><a href={'files/Informacja dotycząca opłat.pdf'} download='Informacja dotycząca opłat.pdf'><i class="fa-solid fa-file-pdf" style={{marginRight:"2%", color:"red"}} />Informacja dotycząca opłat.pdf</a></h4>
        {/* <h4><a href={oplaty_w} download='Informacja dotycząca opłat.docx'><i class="fa-solid fa-file-word" style={{marginRight:"2%"}} />Informacja dotycząca opłat.docx</a></h4> */}
    </div>
  )
}

export default Downloads