import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home'
import About from './components/pages/About'
import News from './components/pages/News'
import Gallery from './components/Gallery';
import TopBtn from './components/TopBtn';
import ParentSection from './components/ParentSection';

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/galeria' element={<Gallery />} />
          <Route path='/aktualnosci' element={<News />} />
          <Route path='/onas' element={<About />} />
          <Route path='/strefa' element={<ParentSection />} />
        </Routes>
        <Footer />
        <TopBtn />
      </BrowserRouter>
    </>
  );
}

export default App;
