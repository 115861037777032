import React, { useState } from 'react'
import FsLightbox from "fslightbox-react";
import './Gallery.css'

const Gallery = () => {
    let data = [
        'photos/gallery/thumb/IMG_1341.JPG',
        'photos/gallery/thumb/IMG_1342.JPG',
        'photos/gallery/thumb/IMG_1343.JPG',
        'photos/gallery/thumb/IMG_1344.JPG',
        'photos/gallery/thumb/IMG_1345.JPG',
        'photos/gallery/thumb/IMG_1346.JPG',
        'photos/gallery/thumb/IMG_1347.JPG',
        'photos/gallery/thumb/IMG_1348.JPG',
        'photos/gallery/thumb/IMG_1349.JPG',
        'photos/gallery/thumb/IMG_1350.JPG',
        'photos/gallery/thumb/IMG_1351.JPG',
        'photos/gallery/thumb/IMG_1352.JPG',
        'photos/gallery/thumb/IMG_1353.JPG',
        'photos/gallery/thumb/IMG_1354.JPG',
        'photos/gallery/thumb/IMG_1355.JPG',
        'photos/gallery/thumb/IMG_1356.JPG',
        'photos/gallery/thumb/IMG_1358.JPG',
        'photos/gallery/thumb/IMG_1362.JPG',
        'photos/gallery/thumb/IMG_1364.JPG',
        'photos/gallery/thumb/IMG_1365.JPG',
        'photos/gallery/thumb/IMG_1369.JPG',
        'photos/gallery/thumb/IMG_1371.JPG',
        'photos/gallery/thumb/IMG_1372.JPG',
        'photos/gallery/thumb/IMG_1373.JPG',
        'photos/gallery/thumb/IMG_1374.JPG',
        'photos/gallery/thumb/IMG_1375.JPG',
        'photos/gallery/thumb/IMG_1376.JPG',
        'photos/gallery/thumb/IMG_1377.JPG',
        'photos/gallery/thumb/IMG_1379.JPG',
        'photos/gallery/thumb/IMG_1380.JPG',
        'photos/gallery/thumb/IMG_1385.JPG',
        'photos/gallery/thumb/IMG_1386.JPG',
        'photos/gallery/thumb/IMG_1390.JPG',
        'photos/gallery/thumb/IMG_1391.JPG',
        'photos/gallery/thumb/IMG_1396.JPG',
        'photos/gallery/thumb/IMG_1399.JPG',
        'photos/gallery/thumb/IMG_1400.JPG',
        'photos/gallery/thumb/IMG_1404.JPG',
        'photos/gallery/thumb/IMG_1405.JPG',
        'photos/gallery/thumb/IMG_1407.JPG',
        'photos/gallery/thumb/IMG_1408.JPG',
        'photos/gallery/thumb/IMG_1409.JPG',
        'photos/gallery/thumb/IMG_1411.JPG',
        'photos/gallery/thumb/IMG_1415.JPG',
        'photos/gallery/thumb/IMG_1420.JPG',
    ].reverse();

    const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	});

    const openLightboxOnSlide = (number) => {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number
		});
	}

    return (
        <>
            <div className='gallery-container'>
                  {data.map(pic => 
                        <div className='images'>
                              <img src={pic} onClick={() => openLightboxOnSlide(data.indexOf(pic) + 1)} alt={pic} />
                        </div>
                  )}
            </div>
            
			<FsLightbox
				toggler={lightboxController.toggler}
				slide={lightboxController.slide}
				sources={data}
                        type="image"
			/>
        </>
    )
}

export default Gallery